import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

export const styles = () =>
  StyleSheet.create({
    sectionTitle: {
      ...setFontWeight('600'),
    },

    otherServiceLink: {
      width: '100%',
    },
    otherServiceButton: {
      ...setFontWeight('400'),
    },
  });

export const StyledBrowseWrapper = tw.div`
  max-md:mx-4 max-md:mt-4
`;

export const StyledBrowseContainer = tw.div`
  mt-4
  md:flex md:flex-row md:flex-wrap justify-between
  md:gap-[2%]
  lg:gap-6
`;

export const StyledOfferTileContainer = tw.div`
  browse-item
  md:w-[48%]
  lg:w-[31%] lg:[&>div]:!mb-0
`;

export const StyledSectionWrapper = tw.div`
  mb-6
  [&:last-child]:!mb-0
  md:mb-10
`;

export const StyledNoServicesWrapper = tw.div`
max-md:mt-64
`;
