import { t } from '@lawnstarter/customer-modules/services';
import { TitleHighlighted } from '@lawnstarter/ls-react-common/molecules';
import { Confirmation, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import imageURL from '@src/assets/crew-cleaning-yard.svg';

import type { Props } from './types';

export function CancellationDeflectionSupport(props: Props) {
  const buttons = [
    {
      label: t('contactSupportButton'),
      onPress: props.onPrimaryPress,
    },
    {
      label: t('cancelMyService'),
      mode: 'text' as const,
      onPress: props.onSecondaryPress,
    },
  ];

  const image = (
    <img alt="" data-testid="image" height={156} loading="lazy" src={imageURL} width={163} />
  );

  const title = (
    <TitleHighlighted
      style={{ justifyContent: 'center' }}
      contents={[
        {
          text: t('cancelService.deflection.support.title.letOurTeam'),
        },
        {
          text: t('cancelService.deflection.support.title.makeItRight'),
          highlight: true,
        },
      ]}
    />
  );

  return (
    <ModalTemplate>
      <Confirmation
        buttons={buttons}
        description={t('cancelService.deflection.support.description')}
        descriptionStyle={{ textAlign: 'center' }}
        icon={image}
        origin="cancellation-deflection-support"
        stacked="always"
        title={title}
      />
    </ModalTemplate>
  );
}
