import { APP_NAME, APP_VERSION } from '@env';
import { useEffect } from 'react';

import { trackingService } from '@src/services';

interface useDocumentTitleArgs {
  anonymousId?: string;
  traits?: Record<string, unknown>;
  userId?: number;
}

export function useTrackIdentify(args?: useDocumentTitleArgs) {
  useEffect(() => {
    const payload = {
      appName: APP_NAME,
      appVersion: APP_VERSION,
      ...(!!args && args),
    };
    trackingService.analytics.identify(payload);
  }, [args]);
}
