import { Pressable } from 'react-native';
import { Text } from 'react-native-paper';
import { ScheduleSourceTypes, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon } from '@lawnstarter/ls-react-common/atoms';

import { useRouteNavigation } from '@src/hooks';

import type { ServiceLinkEntry } from '@lawnstarter/customer-modules/types';

const supportedRoutes = {
  [ScheduleSourceTypes.ManualQuote]: WebRoutes.manualQuoteDetails,
  [ScheduleSourceTypes.InstantQuote]: WebRoutes.instantQuoteDetails,
  [ScheduleSourceTypes.RecommendedQuote]: WebRoutes.serviceQuoteDetails,
  [ScheduleSourceTypes.RequiredQuote]: WebRoutes.serviceQuoteDetails,
} as const;

export function ServiceDetailsLink({ params, ...data }: ServiceLinkEntry) {
  const theme = useAppTheme();
  const { navigate } = useRouteNavigation();

  const isTypedLink = 'type' in data;

  return (
    <Pressable
      style={{ flexDirection: 'row', alignItems: 'center' }}
      onPress={() => {
        const routeLink = isTypedLink
          ? supportedRoutes[data.type as ScheduleSourceTypes]
          : data.href;

        navigate((routeLink ?? '') as WebRoutes, { params });
      }}
    >
      <Text
        style={{
          color: theme.colors.blue.primary,
          lineHeight: theme.fonts.bodySmall.lineHeight,
        }}
      >
        {isTypedLink ? t('serviceDetails') : data.text}
      </Text>

      <Icon name="chevron-right" size={theme.sizing.s6} color={theme.colors.blue.primary} />
    </Pressable>
  );
}
