import { View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { currency } from '@lawnstarter/ls-react-common/filters';

import { useStyles } from './styles';
import { HeaderProps } from './types';

export function Header({ selected, servicePrice = 0, tipMatching = false }: HeaderProps) {
  const { styles, ids } = useStyles(tipMatching);

  const amount = currency({
    amount: selected?.amount || (servicePrice * (selected?.percentage ?? 20)) / 100 || servicePrice,
  });

  return (
    <View dataSet={{ media: ids.container }} style={styles.container}>
      <Text dataSet={{ media: ids.title }} style={styles.title}>
        {t(`review.tipOptions.${tipMatching ? 'matchTip' : 'noMatchTip'}.title`)}
      </Text>
      <Text dataSet={{ media: ids.description }} style={styles.description}>
        {t(
          `review.tipOptions.${selected?.key === 'none' || !tipMatching ? 'noMatchTip' : 'matchTip'}.description`,
          { amount },
        )}
      </Text>
    </View>
  );
}
