import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common/helpers';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const style = (theme: AppTheme) =>
  StyleSheet.create({
    title: {
      marginBottom: theme.spacing.s4,
      ...setFontWeight('600'),
    },
    availability: {
      ...setFontWeight('400'),
    },
    modalHeader: {
      ...theme.fonts.headlineSmall,
      ...setFontWeight('400'),
      marginTop: theme.spacing.s3,
    },
    modalPhoneStyle: {
      ...theme.fonts.headlineMedium,
      ...setFontWeight('700'),
      color: theme.colors.blue.primary,
    },
    modalContainerStyle: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

export const StyledContainer = tw.div`
  p-2 flex flex-col gap-4
  md:p-6 md:pt-2 md:h-auto
`;
