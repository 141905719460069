import StyleSheet from 'react-native-media-query';
import { setFontWeight } from '@lawnstarter/ls-react-common';
import { MediaQuery } from '@lawnstarter/ls-react-common/enums';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export function useStyles(theme: AppTheme) {
  return StyleSheet.create({
    wrapperContainer: {
      height: '100%',
      [MediaQuery.Tablet]: {
        height: 500,
        paddingTop: theme.spacing.s6,
      },
    },
    contentContainer: {
      flex: 1,
      justifyContent: 'space-between',
      marginTop: theme.spacing.s10,
    },
    textContainer: {
      alignItems: 'center',
    },
    buttonsContainer: {
      flexDirection: 'column',
      [MediaQuery.Tablet]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: 0,
      },
    },
    title: {
      ...setFontWeight('600'),
      marginBottom: theme.spacing.s6,
      marginTop: theme.spacing.s6,
      textAlign: 'center',
    },
    subtitle: {
      textAlign: 'center',
    },
    buttonPrimary: {
      flex: 1,
      marginTop: theme.spacing.s4,
      [MediaQuery.Tablet]: {
        marginTop: 0,
        marginLeft: theme.spacing.s4,
      },
    },
    buttonSecondary: {
      flex: 1,
    },
  });
}
