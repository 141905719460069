import { Outlet, useLocation } from 'react-router-dom';
import { useConversationScreenController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';

import { ResponsiveContentWrapper } from '@src/components/organisms';
import { HeaderTabs } from '@src/enums';
import { AuthorizedTemplate } from '@src/pages';

import { InboxScreen } from './InboxScreen';

import type { User } from '@lawnstarter/ls-react-common/types';

export function AuthorizedInboxTemplate({ user }: { user?: User }) {
  const { pathname } = useLocation();
  const { isConversationUninitialized, isLoadingConversation, conversationList } =
    useConversationScreenController();

  return (
    <AuthorizedTemplate currentTab={HeaderTabs.inbox} user={user}>
      <ResponsiveContentWrapper
        isLoading={isLoadingConversation || isConversationUninitialized}
        hasRightContent={conversationList.length !== 0}
        hideLeftContentWhen={pathname !== WebRoutes.inbox}
        leftChildren={<InboxScreen />}
        rightChildren={<Outlet />}
      />
    </AuthorizedTemplate>
  );
}
