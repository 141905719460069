import { useMemo } from 'react';
import { t } from '@lawnstarter/customer-modules/services';
import { InstantQuoteConstants, QuoteFlowerBedWeedingOptions } from '@lawnstarter/ls-constants';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { ImageUploadInput, TextArea } from '@lawnstarter/ls-react-common/atoms';
import { RadioOptionVariant } from '@lawnstarter/ls-react-common/enums';
import { RadioOptionsForm } from '@lawnstarter/ls-react-common/molecules';
import { DynamicBedsForm } from '@lawnstarter/ls-react-common/organisms';

import { styles } from './styles';

import type { FlowerBedWeedingFormProps } from './types';

export function FlowerBedWeedingForm({ control, ...props }: FlowerBedWeedingFormProps) {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  return (
    <div className="flex flex-col gap-y-4 mb-6">
      <DynamicBedsForm control={control} {...props} />
      <RadioOptionsForm
        name="desired_handling"
        rules={{ required: true }}
        control={control}
        label={t('forms.flowerBedWeeding.howToHandle')}
        options={QuoteFlowerBedWeedingOptions.desiredHandling}
        variant={'flat' as RadioOptionVariant}
        noPadding
      />
      <RadioOptionsForm
        name="desired_haulaway"
        control={control}
        label={t('forms.flowerBedWeeding.howToHaulAndDispose')}
        options={QuoteFlowerBedWeedingOptions.desiredHaulawayIq}
        variant={'flat' as RadioOptionVariant}
        noPadding
        rules={{ validate: (value) => value !== undefined && value !== null }}
      />
      <TextArea
        label={t('forms.flowerBedWeeding.anyOtherDetails')}
        name="details"
        control={control}
        style={style.textarea}
      />
      <RadioOptionsForm
        name="cycle"
        control={control}
        label={t('forms.flowerBedWeeding.howFrequently')}
        options={InstantQuoteConstants.cycleOptions}
        variant={'flat' as RadioOptionVariant}
        noPadding
      />
      <ImageUploadInput
        name="images"
        control={control}
        isUploading={false}
        label={t('forms.uploadPhotos')}
        uploadText={t('forms.upload.uploading')}
        tooLargeFilesText={t('forms.upload.fileTooLarge')}
        addButton={{ text: t('forms.upload.placeholder') }}
      />
    </div>
  );
}
