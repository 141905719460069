import { useMatch } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';

export const useMainRouteCheck = () => {
  const services = useMatch(WebRoutes.services);
  const inbox = useMatch(WebRoutes.inbox);
  const browse = useMatch(WebRoutes.browse);
  const profile = useMatch(WebRoutes.profile);

  return {
    isMainRoute: [services, inbox, browse, profile].some((match) => !!match),
  };
};
