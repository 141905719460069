import { useEffect } from 'react';

import type { FieldErrors } from 'react-hook-form';

export function useAutoScrollOnFormErrors({
  errors,
  submitCount,
}: {
  errors: FieldErrors;
  submitCount: number;
}) {
  useEffect(() => {
    const fieldsWithError = Object.keys(errors);

    if (fieldsWithError.length > 0) {
      document
        ?.querySelector(`*[id="error_${fieldsWithError[0]}"]`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [errors, submitCount]);
}
