const safeDocument: Document = document;

/**
 * Usage:
 * const [blockScroll, allowScroll] = useScrollBlock();
 */
export const useScrollBlock = () => {
  if (!document?.documentElement) {
    return [];
  }

  const html = safeDocument.documentElement;

  const blockScroll = () => {
    if (!html.style) return 'nothing-happened';

    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    html.style.position = 'relative'; /* [1] */
    html.style.overflow = 'hidden'; /* [2] */
  };

  const allowScroll = () => {
    if (!html.style) return 'nothing-happened';

    html.style.position = '';
    html.style.overflow = '';
  };

  return [blockScroll, allowScroll];
};
