import { zodResolver } from '@hookform/resolvers/zod';
import { FocusEvent, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { z } from 'zod';
import { t } from '@lawnstarter/customer-modules/services';
import { TextInput } from '@lawnstarter/ls-react-common/atoms';
import { SelectOption } from '@lawnstarter/ls-react-common/molecules';

import { Header } from './Header';
import { OptionLabel } from './OptionLabel';
import { useStyles } from './styles';

import type { SelectOptionItemKey } from '@lawnstarter/ls-react-common/molecules';
import type { TipAmountOption, TipAmountOptionsProps } from './types';

const option10: TipAmountOption = { key: '10%', percentage: 10 };
const option20: TipAmountOption = { key: '20%', percentage: 20 };
const option30: TipAmountOption = { key: '30%', percentage: 30 };
const optionOther: TipAmountOption = { key: 'other', amount: 0 };
const optionNone: TipAmountOption = { key: 'none' };

TipAmountOptions.OptionPercent10 = option10;
TipAmountOptions.OptionPercent20 = option20;
TipAmountOptions.OptionPercent30 = option30;
TipAmountOptions.OptionOther = optionOther;
TipAmountOptions.OptionNone = optionNone;

export function TipAmountOptions({
  allowNoTip = false,
  disabled = false,
  onSelect,
  selected,
  servicePrice,
  tipMatching = false,
}: TipAmountOptionsProps) {
  const { styles, ids } = useStyles();

  const { control, setFocus, setValue } = useForm({
    defaultValues: {
      amount: selected?.amount ? selected.amount.toFixed(2) : '',
    },
    resolver: zodResolver(
      z.object({
        amount: z.string(),
      }),
    ),
  });

  const rawOptions = useMemo(
    () =>
      [option10, option20, option30, optionOther]
        .concat(allowNoTip ? [optionNone] : [])
        .map((option) => ({
          key: option.key,
          entity:
            option.key === TipAmountOptions.OptionOther.key && option.key === selected?.key
              ? {
                  ...option,
                  amount: selected.amount,
                }
              : option,
        })),
    [allowNoTip, selected],
  );

  const onAmountBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const formatted = parseFloat(event.target?.value || '0').toFixed(2);
      if (formatted === '0.00') {
        onSelect?.(allowNoTip ? TipAmountOptions.OptionNone : TipAmountOptions.OptionPercent20);
      } else {
        setValue('amount', formatted);
        onSelect?.({
          key: TipAmountOptions.OptionOther.key,
          amount: parseFloat(formatted),
        });
      }
    },
    [allowNoTip, onSelect, setValue],
  );

  const options = useMemo(
    () =>
      rawOptions.map(({ key, entity }) => ({
        accessibilityLabel: t(`review.tipOptions.labels.${key}`),
        containerStyle: styles.optionContainer,
        key,
        label: (
          <>
            <OptionLabel
              disabled={disabled}
              option={entity}
              selected={selected}
              servicePrice={servicePrice}
              visible={
                disabled ||
                key !== TipAmountOptions.OptionOther.key ||
                selected?.key !== TipAmountOptions.OptionOther.key
              }
            />
            {!disabled && key === TipAmountOptions.OptionOther.key && (
              <TextInput.Currency
                containerStyle={[
                  styles.currencyInputContainer,
                  { display: selected?.key === TipAmountOptions.OptionOther.key ? 'flex' : 'none' },
                ]}
                control={control}
                name="amount"
                style={styles.currencyInput}
                onBlur={onAmountBlur}
              />
            )}
          </>
        ),
      })),
    [control, disabled, onAmountBlur, rawOptions, selected, servicePrice, styles],
  );

  function onOptionSelect(key: SelectOptionItemKey) {
    const option = rawOptions.find((item) => item.key === key);

    if (!option?.entity) {
      return;
    }

    onSelect?.({
      key: option.key,
      amount: option.entity.amount,
      percentage: option.entity.percentage,
    } as TipAmountOption);

    if (option.key === TipAmountOptions.OptionOther.key) {
      setValue('amount', '');
      setTimeout(() => setFocus('amount'), 0);
    }
  }

  return (
    <View dataSet={{ media: ids.container }} style={styles.container}>
      {allowNoTip ? (
        <Header selected={selected} servicePrice={servicePrice} tipMatching={tipMatching} />
      ) : null}

      <View
        dataSet={allowNoTip ? { media: ids.optionsWithHeader } : {}}
        style={[styles.options, allowNoTip && styles.optionsWithHeader]}
      >
        <SelectOption
          key={JSON.stringify(selected)}
          options={options}
          selected={selected?.key}
          disabled={disabled}
          onSelect={onOptionSelect}
          textAlignment="left"
        />
      </View>
    </View>
  );
}
