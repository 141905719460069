import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { useDisputeController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { Button, ImageUploadInput, ScrollView, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader } from '@src/components';
import { useRouteNavigation } from '@src/hooks';

import { useStyles } from './styles';

import type { FormData } from './types';

export function DisputePhotosScreen() {
  const { styles } = useStyles();
  const { navigate } = useRouteNavigation();
  const { control, handleSubmit } = useForm<FormData>();

  const confirmationModal = useModal(ModalTemplate.CONFIRMATION);

  const { title, type, isLoading, isUpdating, updateCurrentDispute } = useDisputeController();

  useEffect(() => {
    if (!type) {
      navigate(WebRoutes.services, { options: { replace: true } });
    }
  }, [type, navigate]);

  const navigateToDisputeConfirm = () => {
    navigate(WebRoutes.disputeConfirm, { options: { replace: true } });
  };

  const onContinue = async (data: FormData) => {
    const response = await updateCurrentDispute({
      updatedValues: { images: data.images },
      submitCurrentDispute: true,
    });

    if (response?.success) {
      if (response?.isPartialCompletion) {
        confirmationModal.show({
          icon: 'block-helper',
          title: t('flows.reportIssue.proReportedIssueAccessing'),
          description: t('flows.reportIssue.makeSureYourProCanAccess'),
          buttons: [
            {
              label: t('ok'),
              onPress: navigateToDisputeConfirm,
            },
          ],
        });
      } else {
        navigateToDisputeConfirm();
      }
    }
  };

  return (
    <>
      <DetailsHeader showBackButton title={title} />
      <ScrollView>
        <View style={styles.container}>
          <Text style={styles.title} variant="titleMedium">
            {t('flows.reportIssue.providePhotos')}
          </Text>

          <Text style={styles.description} variant="bodySmall">
            {t('flows.reportIssue.photosOfUnsatisfactoryWork')}
          </Text>

          <ImageUploadInput
            name="images"
            control={control}
            isUploading={false}
            singleUpload={false}
            label={t('flows.reportIssue.optionalPhotoUpload')}
            uploadText={t('forms.upload.uploading')}
            addButton={{ text: t('forms.uploadPhotos') }}
            tooLargeFilesText={t('forms.upload.fileTooLarge')}
          />
        </View>

        <View>
          <Button
            disabled={isLoading || isUpdating}
            loading={isUpdating}
            mode="contained"
            onPress={handleSubmit(onContinue)}
            trackID="dispute_issue_location_screen-continue"
          >
            {t('continue')}
          </Button>
        </View>
      </ScrollView>
    </>
  );
}
