import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useOneTimeRescheduleController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { setFontWeight } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate, OneTimeRescheduleForm } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader, Loader } from '@src/components';
import { useAppContext } from '@src/contexts';
import { useRouteNavigation } from '@src/hooks';

import { StyledButtonContainer, StyledContainer, StyledLabel } from './styles';

import type { OneTimeRescheduleFormData, Schedule } from '@lawnstarter/ls-react-common/types';

export function OneTimeRescheduleScreen() {
  const { navigate } = useRouteNavigation();
  const { propertyId, scheduleId } = useParams();
  const { setSubTitle } = useAppContext();

  const confirmationModal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const optionalModal = useModal(ModalTemplate.OPTIONAL_ACTION);
  const summaryModal = useModal(ModalTemplate.OPERATION_SUMMARY);
  const binaryModal = useModal(ModalTemplate.BINARY_CHOICE);

  const onSuccess = () => {
    navigate(WebRoutes.serviceDetail, {
      params: {
        propertyId: propertyId as string,
        scheduleId: scheduleId as string,
      },
    });
  };

  const onMessagePro = ({ schedule }: { schedule: Schedule }) => {
    navigate(WebRoutes.conversation, {
      params: {
        conversationId: 0,
        contractorUserId: schedule.contractor?.user?.id,
      },
    });
  };

  const goToServiceDetails = () => {
    navigate(WebRoutes.serviceDetail, {
      params: {
        propertyId: propertyId as string,
        scheduleId: scheduleId as string,
      },
    });
  };

  const { watch, control, handleSubmit } = useForm<OneTimeRescheduleFormData>();

  const selectedDate = watch('date');
  const {
    submit,
    isUpdating,
    isOneTimeService,
    minDate,
    checkboxLabel,
    disabledDays,
    serviceName,
  } = useOneTimeRescheduleController({
    propertyId: Number(propertyId),
    scheduleId: Number(scheduleId),
    watch,
    summaryModal,
    onSuccess,
    confirmationModal,
    optionalModal,
    binaryModal,
    onMessagePro,
    goToServiceDetails,
  });

  useEffect(() => {
    serviceName && setSubTitle(serviceName);
  }, [serviceName, setSubTitle]);

  if (isUpdating) {
    return <Loader testID="one-time-reschedule-loader" />;
  }

  return (
    <div>
      <DetailsHeader showBackButton={true} />
      <StyledContainer>
        <StyledLabel>
          <Text variant="bodyMedium" style={{ ...setFontWeight('600') }}>
            {t('oneTimeReschedule.selectPreferredDate')}
          </Text>
          <Text variant="bodyMedium">{t('oneTimeReschedule.otherServicesMayReschedule')}</Text>
        </StyledLabel>
        <OneTimeRescheduleForm
          control={control}
          isUpdating={isUpdating}
          origin="one_time_reschedule_screen"
          minDate={minDate}
          isOneTimeService={isOneTimeService}
          checkboxLabel={checkboxLabel}
          disabledDays={disabledDays}
        />
        {selectedDate && (
          <StyledButtonContainer>
            <Button
              disabled={isUpdating}
              loading={isUpdating}
              mode="contained"
              onPress={handleSubmit(submit)}
              trackID={'change_frequency_screen-submit_request'}
            >
              {t('rescheduleService')}
            </Button>
          </StyledButtonContainer>
        )}
      </StyledContainer>
    </div>
  );
}
