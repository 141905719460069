import StyleSheet from 'react-native-media-query';
import { MediaQuery } from '@lawnstarter/ls-react-common/enums';

import { useWindowSize } from '@src/hooks';

export function useStyles() {
  const { height } = useWindowSize();
  return StyleSheet.create({
    container: {
      height: '100%',
      width: '100%',

      [MediaQuery.Small]: {
        height: height - 160,
        width: '100%',
      },
    },
  });
}
