import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { Notice as NoticeType } from '@lawnstarter/ls-react-common/enums';
import { Notice } from '@lawnstarter/ls-react-common/organisms';

import { StyledResponsiveNotice } from '@src/components/atoms';
import { useRouteNavigation, useSelector } from '@src/hooks';

export function MissingPasswordNotice() {
  const theme = useAppTheme();
  const { navigate } = useRouteNavigation();

  const { has_password } = useSelector(({ auth }) => auth.user);

  const onPress = () => navigate(WebRoutes.changePassword);

  return (
    !has_password && (
      <Notice type={NoticeType.Info}>
        <StyledResponsiveNotice>
          <Text>{t('notices.missingPassword')}</Text>
          <Button
            trackID="payment-notice_update-now"
            onPress={onPress}
            textColor={theme.colors.blue.primary}
            style={{ alignSelf: 'flex-end' }}
          >
            {t('setPassword')}
          </Button>
        </StyledResponsiveNotice>
      </Notice>
    )
  );
}
