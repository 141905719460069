import { StyleSheet } from 'react-native';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';

export function useStyles(disabled = false, visible = true) {
  const theme = useAppTheme();

  return StyleSheet.create({
    container: {
      display: visible ? 'flex' : 'none',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      paddingLeft: theme.spacing.s7,
    },
    labelBold: {
      ...setFontWeight('700'),
      color: disabled ? theme.colors.gray.primary : undefined,
    },
    labelRegular: {
      ...setFontWeight('400'),
      color: disabled ? theme.colors.gray.primary : undefined,
    },
  });
}
