import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';

import { HeaderTabs } from '@src/enums';

export const HEADER_ROUTE_OPTIONS = [
  {
    link: WebRoutes.browseRedirect,
    tab: HeaderTabs.browse,
    icon: 'store-plus-outline',
    label: t('tabs.browse'),
  },
  {
    link: WebRoutes.services,
    tab: HeaderTabs.services,
    icon: 'calendar-check-outline',
    label: t('tabs.service'),
  },
  {
    link: WebRoutes.inbox,
    tab: HeaderTabs.inbox,
    icon: 'inbox',
    label: t('tabs.inbox'),
  },
  {
    link: WebRoutes.profile,
    tab: HeaderTabs.profile,
    icon: 'account-outline',
    label: t('tabs.profile'),
  },
];
