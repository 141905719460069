import { Spinner } from '@lawnstarter/ls-react-common/atoms';

import { StyledSpinnerContainer } from './styles';

export const Loader = ({
  isRouterLoader,
  testID,
}: {
  isRouterLoader?: boolean;
  testID?: string;
}) => {
  return (
    <StyledSpinnerContainer data-testid="loader-wrapper" $routerSpinner={isRouterLoader ?? false}>
      <Spinner testID={testID} />
    </StyledSpinnerContainer>
  );
};
