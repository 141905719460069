import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { useDisputeController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { Button, ScrollView, Text, TextArea } from '@lawnstarter/ls-react-common/atoms';

import { DetailsHeader } from '@src/components';
import { useRouteNavigation } from '@src/hooks';
import { useStyles } from '@src/pages/Authorized/Services/DisputeNotesScreen/styles.ts';

export function DisputeNotesScreen() {
  const { title, type, isUpdating, isLoading, updateCurrentDispute } = useDisputeController();
  const { navigate } = useRouteNavigation();
  const { control, handleSubmit, watch } = useForm<{ notes: string }>();
  const { styles, ids } = useStyles();

  const hasText = !!watch('notes');

  useEffect(() => {
    if (!type && !isUpdating) {
      navigate(WebRoutes.services);
    }
  }, [type, isUpdating, navigate]);

  async function submit({ notes }: { notes: string }) {
    updateCurrentDispute({ updatedValues: { notes } });
    navigate(WebRoutes.disputePhotos, { options: { replace: true } });
  }

  return (
    <>
      <DetailsHeader showBackButton title={title} />
      <View dataSet={{ media: ids.contentContainer }} style={styles.contentContainer}>
        <ScrollView>
          <View dataSet={{ media: ids.textAreaContainer }} style={styles.textAreaContainer}>
            <Text style={styles.title} variant="titleMedium">
              {t('flows.reportIssue.whatWasTheIssue')}
            </Text>

            <TextArea
              name={'notes'}
              control={control}
              rules={{ required: true }}
              label={t('forms.provideMoreInformation')}
              placeholder={t('forms.enterDetailsHere')}
              contentStyle={styles.textArea}
            />
          </View>
          <Button
            disabled={isUpdating || isLoading || !hasText}
            loading={isUpdating}
            trackID={'dispute_reason_screen_notes-continue'}
            onPress={handleSubmit(submit)}
            mode={'contained'}
          >
            {t('continue')}
          </Button>
        </ScrollView>
      </View>
    </>
  );
}
