import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    addPropertyButton: {
      display: 'flex',
      paddingVertical: theme.spacing.s1,
      paddingRight: theme.spacing.s2,
      alignItems: 'center',
    },
    buttonText: {
      display: 'flex',
      alignItems: 'center',
    },
  });
export const StyledAddPropertyButtonInnerContainer = tw.div`flex w-full justify-start gap-2 ml-4 py-1`;
