import { StyleSheet } from 'react-native';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    closeButtonContainer: {
      alignItems: 'flex-end',
      position: 'absolute',
      width: '100%',
      zIndex: 1,
    },
    closeButtonLabel: {
      fontSize: theme.fonts.headlineSmall.fontSize,
      ...setFontWeight('300'),
    },
    label: {
      flex: 1,
      paddingVertical: theme.spacing.s5,
    },
  });
