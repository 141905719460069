import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { getServicesBySections } from '@lawnstarter/customer-modules/helpers';
import {
  auth_signedUserSelector,
  properties_currentPropertySelector,
  useGetAvailableServicesQuery,
} from '@lawnstarter/customer-modules/stores/modules';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';

import { Loader } from '@src/components';
import { useSelector } from '@src/hooks';

import type { ReactNode } from 'react';

export function OrderAccess({ component }: { component: ReactNode }) {
  const { pathname } = useLocation();

  const isOrderPath = pathname.includes('/order/');
  const [source, paramServiceId] = pathname
    .match(
      isOrderPath
        ? '/services/.*/(reactivate|request|order)/([^/]+)(?:/.*)?'
        : '/services/.*/(reactivate|request|order)/?(.*)/?',
    )
    ?.slice(1) ?? [undefined, undefined];

  // We handle reactivate differently; for order, our serviceIds are also pulled from whatever comes immediately after order/ in the URL.
  const serviceId = source === 'reactivate' ? ServiceType.LawnMowing : paramServiceId;

  const property = useSelector(properties_currentPropertySelector);
  const user = useSelector(auth_signedUserSelector);

  const { data: allServices } = useGetAvailableServicesQuery(
    {
      customerId: Number(user?.customer_id),
      propertyId: property?.id,
      marketId: property?.market_id,
    },
    { skip: !property },
  );

  const isServiceOrderable = useMemo(() => {
    const [availableServices] = getServicesBySections({ allServices });

    return availableServices?.data.find(
      ({ id, str_id }) => id?.toString() === serviceId || str_id === serviceId,
    );
  }, [serviceId, allServices]);

  const isLoading = useMemo(() => {
    return !property || !user || !allServices;
  }, [property, user, allServices]);

  if (!serviceId) {
    return <Navigate to={WebRoutes.browseRedirect} replace />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return isServiceOrderable ? component : <Navigate to={WebRoutes.browseRedirect} replace />;
}
