import { View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import { getScheduleServiceName } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { date } from '@lawnstarter/ls-react-common/filters';
import { ScheduleEvent } from '@lawnstarter/ls-react-common/types';

import { useStyles } from './styles';

export function ServiceReviewTitle({ scheduleEvent }: { scheduleEvent?: ScheduleEvent }) {
  const { styles, ids } = useStyles();

  const title = scheduleEvent?.schedule
    ? (getScheduleServiceName({ schedule: scheduleEvent.schedule }) as string)
    : '';

  const subtitle = scheduleEvent?.status_set_at
    ? date({ date: scheduleEvent.status_set_at, format: 'compactedTime' })
    : '';

  return (
    <View testID="review-service-title" dataSet={{ media: ids.container }} style={styles.container}>
      {title && (
        <Text dataSet={{ media: ids.title }} style={styles.title}>
          {t('review.title', { service: title.toLowerCase() })}
        </Text>
      )}

      {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
    </View>
  );
}
