import { t } from '@lawnstarter/customer-modules/services';

import { IssueOptionKey } from './enums';

export const issueOptions = [
  {
    key: IssueOptionKey.Quality,
    label: t('review.issues.quality'),
  },
  {
    key: IssueOptionKey.PartialComplete,
    label: t('review.issues.partialCompletion'),
  },
  {
    key: IssueOptionKey.NoWorkDone,
    label: t('review.issues.noWorkDone'),
  },
  {
    key: IssueOptionKey.PropertyDamage,
    label: t('review.issues.propertyDamage'),
  },
];
