import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { z } from 'zod';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import {
  auth_signedUserSelector,
  profile_updateBasicInfo,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { BasicInfoForm } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader } from '@src/components';
import { useDispatch, useSelector } from '@src/hooks';

import type { BasicInfoFormData } from '@lawnstarter/ls-react-common/organisms';
import type { User } from '@lawnstarter/ls-react-common/types';

export function ProfileDetailsScreen() {
  const user: User = useSelector(auth_signedUserSelector);
  const isUpdating = useSelector((state) => state.profile.loadingStatus.isUpdating);
  const theme = useAppTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const validationSchema = z.object({
    first_name: z.string().min(1, { message: 'First name is required' }),
    last_name: z.string().min(1, { message: 'Last name is required' }),
    email: z
      .string()
      .email({ message: 'Invalid email address' })
      .min(1, { message: 'Email is required' }),
    phone: z
      .string()
      .regex(/^\d{10}$/, { message: 'Phone number must be 10 digits' })
      .min(1, { message: 'Phone is required' }),
  });

  const { control, handleSubmit } = useForm<BasicInfoFormData>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      phone: user?.phone,
    },
  });

  const submit = async (data: BasicInfoFormData) => {
    const formattedData = {
      fname: data.first_name,
      lname: data.last_name,
      email: data.email,
      phone: data.phone,
    };

    const res = await dispatch(profile_updateBasicInfo(formattedData));

    if (res?.success && pathname !== WebRoutes.profile) {
      // due to the dual route navigation with Webroutes.profile does not re-render parent side-by-side comp
      return window.history.back();
    }
  };

  return (
    <div>
      <DetailsHeader title={t('profile.accountSettings.basicInformation')} />
      <div className="m-7">
        <BasicInfoForm
          isUpdating={isUpdating}
          submit={handleSubmit(submit)}
          control={control}
          origin="basic_info_screen"
        />
        <Button
          trackID="basic_info_screen-save_info"
          onPress={handleSubmit(submit)}
          style={{ marginTop: theme.spacing.s8 }}
          mode="contained"
          loading={isUpdating}
        >
          {t('save')}
        </Button>
      </div>
    </div>
  );
}
