import { useMatch } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';

export const useShowHelpIcon = () => {
  const serviceDetails = useMatch(WebRoutes.serviceDetail);
  const recommendedDetails = useMatch(WebRoutes.recommendedQuoteDetails);
  const requiredDetails = useMatch(WebRoutes.requiredQuoteDetails);
  const orderLawnTreatment = useMatch(WebRoutes.orderLawnTreatment);

  const matches = [serviceDetails, recommendedDetails, requiredDetails, orderLawnTreatment];

  return matches.some((match) => !!match);
};
