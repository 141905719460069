import { useState } from 'react';
import { View } from 'react-native';
import { useDisputeController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { Button, ScrollView, Text } from '@lawnstarter/ls-react-common/atoms';
import { SelectOption } from '@lawnstarter/ls-react-common/molecules';

import { DetailsHeader } from '@src/components';
import { useRouteNavigation } from '@src/hooks';

import { reasons } from './constants';
import { useStyles } from './styles';

import type { DisputeReason } from '@lawnstarter/ls-react-common/enums';

export function DisputeReasonScreen() {
  const { styles } = useStyles();
  const { navigate } = useRouteNavigation();
  const { isUpdating, title, updateCurrentDispute } = useDisputeController();
  const [selectedReason, setSelectedReason] = useState<DisputeReason | null>(null);

  function onContinue() {
    updateCurrentDispute({ updatedValues: { reason: selectedReason } });
    navigate(WebRoutes.disputeLocation, { options: { replace: true } });
  }

  return (
    <>
      <DetailsHeader showBackButton title={title} />

      <ScrollView>
        <View style={styles.container}>
          <Text style={styles.title} variant="titleMedium">
            {t('qualityIssue.whatWasTheQualityIssue')}
          </Text>

          <SelectOption
            disabled={isUpdating}
            onSelect={(key) => setSelectedReason(key as DisputeReason)}
            options={reasons}
            selected={selectedReason}
            trackID="dispute_reason_screen-reasons"
          />
        </View>

        <View>
          <Button
            disabled={!selectedReason || isUpdating}
            loading={isUpdating}
            mode="contained"
            onPress={onContinue}
            trackID="dispute_reason_screen-continue"
          >
            {t('continue')}
          </Button>
        </View>
      </ScrollView>
    </>
  );
}
