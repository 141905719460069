import { StyleSheet } from 'react-native';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const style = (theme: AppTheme) =>
  StyleSheet.create({
    contentContainer: {
      flexGrow: 1,
    },
    subtitleWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    subtitleNote: { ...setFontWeight('700') },
    subtitleTotal: {
      ...setFontWeight('700'),
      alignSelf: 'flex-end',
    },
    contentWrapper: {
      padding: theme.spacing.s3,
    },
    list: {
      backgroundColor: theme.colors.primaryLight,
      padding: theme.spacing.s5,
    },
    listTotal: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    accordionDivider: {
      backgroundColor: theme.colors.primaryLight,
    },
    itemWrapper: {
      marginBottom: theme.spacing.s4,
    },
    itemContent: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing.s4,
    },
    itemNote: {
      paddingRight: theme.spacing.s2,
    },
    footerContainer: {
      height: theme.sizing.s13,
      justifyContent: 'center',
    },
  });
