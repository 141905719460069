import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useChangeFrequencyController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ChangeFrequencyForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader, Loader } from '@src/components';
import { useRouteNavigation } from '@src/hooks';

import { StyledButtonContainer, StyledContainer } from './styles';

import type { ChangeFrequencyFormData, Schedule } from '@lawnstarter/ls-react-common/types';

export function ChangeFrequencyScreen() {
  const { propertyId, scheduleId } = useParams();
  const { navigate } = useRouteNavigation();
  const optionalModal = useModal(ModalTemplate.OPTIONAL_ACTION);
  const summaryModal = useModal(ModalTemplate.OPERATION_SUMMARY);
  const errorModal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangeFrequencyFormData>();

  const selectedCycle = watch('new_cycle');
  const {
    isLoading,
    isUpdating,
    cycleOptions,
    submit,
    getOptionalModalProps,
    getSuccessModalProps,
  } = useChangeFrequencyController({
    scheduleId,
    selectedCycle,
  });

  const onSuccess = ({ schedule }: { schedule?: Schedule }) => {
    setTimeout(() => {
      summaryModal.show({
        ...getSuccessModalProps(schedule),
        onDismiss: () =>
          navigate(WebRoutes.serviceDetail, {
            params: {
              propertyId: propertyId as string,
              scheduleId: scheduleId as string,
            },
          }),
      });
    }, 0);
  };

  const onFailure = ({ error }: { error?: any }) => {
    setTimeout(() => {
      return errorModal.show({
        icon: 'block-helper',
        action: t('unableProcessRequest'),
        messages: [error?.error?.message || t('apiGenericError')],
        buttonText: t('tryAgain'),
      });
    }, 0);
  };

  const renderOptionalModal = ({ new_cycle }: ChangeFrequencyFormData) => {
    optionalModal.show({
      ...getOptionalModalProps({ new_cycle }),
      onAction: async () => {
        await submit({ data: { new_cycle }, onSuccess, onFailure });
      },
    } as any);
  };

  if (isLoading) {
    return <Loader testID="change-frequency-loader" />;
  }

  if (!cycleOptions.length) {
    return (
      <StyledContainer>
        <Text variant="bodyMedium">{t('changeFrequency.cannotChangeFrequency')}</Text>
      </StyledContainer>
    );
  }

  return (
    <div>
      <DetailsHeader showBackButton={true} />

      <StyledContainer>
        <Text variant="bodyMedium">{t('changeFrequency.selectFrequency')}</Text>
        <ChangeFrequencyForm
          isUpdating={isLoading}
          control={control}
          origin={'change_frequency_screen'}
          cycleOptions={cycleOptions}
          errors={errors}
          label={t('changeFrequency.preference')}
        />
        <StyledButtonContainer>
          <Button
            trackID={'change_frequency_screen-submit_request'}
            onPress={handleSubmit(renderOptionalModal)}
            mode="contained"
            loading={isUpdating}
            disabled={isUpdating}
          >
            {t('changeFrequency.updateFrequency')}
          </Button>
        </StyledButtonContainer>
      </StyledContainer>
    </div>
  );
}
