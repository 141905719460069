export const DEVICE_WIDTH_BREAKPOINTS = {
  mobile: {
    min: 320,
    max: 480,
  },
  tablet: {
    min: 481,
    max: 768,
  },
  laptops: {
    min: 769,
    max: 1024,
  },
  desktop: {
    min: 1025,
    max: 1200,
  },
} as const;
