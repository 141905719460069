import StyleSheet from 'react-native-media-query';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { MediaQuery } from '@lawnstarter/ls-react-common/enums';

export function useStyles() {
  const theme = useAppTheme();

  return StyleSheet.create({
    container: {
      alignItems: 'center',
      gap: theme.spacing.s2,

      [MediaQuery.Small]: {
        alignItems: 'flex-start',
        gap: theme.spacing.s3,
      },
    },
    subtitle: {
      ...setFontWeight('700'),
      fontSize: theme.fonts.labelMedium.fontSize,
      lineHeight: theme.fonts.labelMedium.lineHeight,
    },
    title: {
      ...setFontWeight('600'),
      color: theme.colors.primary,
      fontSize: theme.fonts.titleMedium.fontSize,
      lineHeight: theme.fonts.titleMedium.lineHeight,
      textAlign: 'center',

      [MediaQuery.Small]: {
        fontSize: theme.fonts.headlineSmall.fontSize,
        lineHeight: theme.fonts.headlineSmall.lineHeight,
        textAlign: 'left',
      },
    },
  });
}
