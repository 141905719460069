import { useListItem } from '@floating-ui/react';
import { useContext } from 'react';
import { setCurrentProperty } from '@lawnstarter/customer-modules/helpers';
import { properties_updateCurrentProperty } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';

import { SelectContext } from '@src/contexts';
import { useDispatch } from '@src/hooks';

export function PropertyOptionButton({
  street,
  city,
  state,
  zip,
  label,
  style,
  property_id,
}: {
  street: string;
  city: string;
  state: string;
  zip: string;
  label: string;
  style?: object;
  property_id: string;
}) {
  const { activeIndex, getItemProps, handleSelect } = useContext(SelectContext);
  const theme = useAppTheme();
  const { ref, index } = useListItem({ label });
  const dispatch = useDispatch();
  const isActive = activeIndex === index;

  return (
    <button
      ref={ref}
      role="option"
      aria-selected={isActive}
      tabIndex={isActive ? 0 : -1}
      style={{
        ...style,
        background: isActive ? theme.colors.ripple : '',
        paddingInline: theme.spacing.s5,
      }}
      {...getItemProps({
        onClick: () => {
          handleSelect(index);
          dispatch(properties_updateCurrentProperty({ property_id }));
          setCurrentProperty({ propertyId: property_id });
        },
      })}
    >
      <Text>{street}</Text>
      <br />
      <Text>
        {city}, {state}, {zip}
      </Text>
    </button>
  );
}
