/*
The way we built DatePicker is not optimal:
- It forces us to use dayjs.
- We don't know the types.
- It requires another UI framework provider to be passed.
  - Customizations can only be done by doing this.
- We can't control the placement of the label.
- The library come with some standards we don't want to follow
- The component performs its own validations making it hard for us to integrate it with zod/react-hook-form

We have better and more integrated alternatives to use but we won't explore them now due to our reduced time to invest.
- https://github.com/web-ridge/react-native-paper-dates
- https://github.com/farhoudshapouran/react-native-ui-datepicker

PS: Before any improvement, this NEEDS to be moved to @lawnstarter/ls-react-common

This component was created to reduce the friction and make it integrate with react-hook-form seamlessly
*/

import { createTheme, ThemeProvider } from '@mui/material';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { useCallback } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { FormError, Text } from '@lawnstarter/ls-react-common/atoms';
import { setFontWeight } from '@lawnstarter/ls-react-common/helpers';
import { DatePicker } from '@lawnstarter/ls-react-web-common';

import type { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

dayjs.extend(isTomorrow);
dayjs.extend(isToday);

export interface DatePickerFormProps {
  name: string;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  control: Control<any>;
  rules?: RegisterOptions;
  disabled?: boolean;
}

export function DatePickerForm({
  name,
  label,
  control,
  minDate,
  maxDate,
  rules = {},
  disabled = false,
  ...props
}: DatePickerFormProps) {
  const theme = useAppTheme();
  const { colors } = useAppTheme();
  const componentTheme = createTheme({
    palette: {
      primary: {
        main: colors.primary,
      },
    },
  });

  const renderInput = useCallback(
    ({
      field,
      fieldState: { error },
    }: {
      field: ControllerRenderProps;
      fieldState: ControllerFieldState;
    }) => (
      <ThemeProvider theme={componentTheme}>
        <div {...props}>
          {label && (
            <Text style={{ marginBottom: theme.spacing.s1, ...setFontWeight('600') }}>{label}</Text>
          )}
          <DatePicker
            inputDisabled
            className="w-full"
            test-id="datepicker"
            value={dayjs(field.value)}
            shouldDisableDate={(date: Date) => {
              if (minDate && dayjs(date).isBefore(minDate)) {
                return true;
              }
              if (maxDate && dayjs(date).isAfter(maxDate)) {
                return true;
              }
              return false;
            }}
            disablePast={false}
            disabled={disabled}
            onChange={(date: { $d: Date }) => field.onChange(date.$d.toISOString().split('T')[0])}
          />
          <FormError
            error={error ?? null}
            style={{ alignSelf: 'flex-start', marginLeft: theme.spacing.s2 }}
          />
        </div>
      </ThemeProvider>
    ),
    [componentTheme, disabled, label, maxDate, minDate, props, theme],
  );

  return <Controller control={control} name={name} rules={rules} render={renderInput} {...props} />;
}
