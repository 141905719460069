import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { useEffect, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { FormError, Text } from '@lawnstarter/ls-react-common/atoms';

import { style, StyledFormRow, StyledStripeContainer, StyledStripeWrapper } from './styles';

import type { Control } from 'react-hook-form';

export function PaymentForm({
  name,
  control,
  disabled,
  isRequired,
  submitCount,
}: {
  name: string;
  control: Control<any>;
  disabled: boolean;
  isRequired: boolean;
  submitCount: number;
}) {
  const theme = useAppTheme();
  const { styles, stripeStyle } = useMemo(() => style(theme, disabled), [disabled, theme]);

  const [isCvcReady, setCvcReady] = useState(false);
  const [isExpiryReady, setExpiryReady] = useState(false);
  const [isCardNumberReady, setCardNumberReady] = useState(false);
  const shouldValidate = useMemo(() => isRequired && submitCount > 0, [isRequired, submitCount]);

  // Keep track of Stripe elements
  const stripeElements = useController({
    name,
    control,
    defaultValue: false,
    rules: { validate: (value) => (isRequired ? Boolean(value) : true) },
  });

  useEffect(() => {
    if (isCvcReady && isExpiryReady && isCardNumberReady) {
      stripeElements.field.onChange(true);
    }
  }, [isCvcReady, isExpiryReady, isCardNumberReady, stripeElements]);

  return (
    <>
      <StyledFormRow>
        <Text style={styles.inputLabel}>{t('payment.cardNumber')}</Text>
        <StyledStripeContainer>
          <CardNumberElement
            options={stripeStyle}
            onChange={(event: any) => setCardNumberReady(event.complete)}
          />
        </StyledStripeContainer>
        {!isCardNumberReady && shouldValidate && (
          <FormError
            id={`error_${name}`}
            error={{ type: 'required', message: t('forms.required') }}
          />
        )}
      </StyledFormRow>

      <StyledStripeWrapper>
        <div className="flex-1">
          <Text style={styles.inputLabel}>{t('payment.expirationMonthYear')}</Text>
          <StyledStripeContainer>
            <CardExpiryElement
              options={stripeStyle}
              onChange={(event) => setExpiryReady(event.complete)}
            />
          </StyledStripeContainer>
          {!isExpiryReady && shouldValidate && (
            <FormError
              id={`error_${name}`}
              error={{ type: 'required', message: t('forms.required') }}
            />
          )}
        </div>

        <div className="flex-1">
          <Text style={styles.inputLabel}>{t('payment.cvc')}</Text>
          <StyledStripeContainer>
            <CardCvcElement
              options={stripeStyle}
              onChange={(event) => setCvcReady(event.complete)}
            />
          </StyledStripeContainer>
          {!isCvcReady && shouldValidate && (
            <FormError
              id={`error_${name}`}
              error={{ type: 'required', message: t('forms.required') }}
            />
          )}
        </div>
      </StyledStripeWrapper>
    </>
  );
}
