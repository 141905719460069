import { STRIPE_KEY } from '@env';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import type { ReactNode } from 'react';

const stripePromise = loadStripe(STRIPE_KEY);

export function StripeProvider({ children }: { children: ReactNode }) {
  return <Elements stripe={stripePromise}>{children}</Elements>;
}
