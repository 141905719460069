import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { t } from '@lawnstarter/customer-modules/services';
import { profile_updatePayment } from '@lawnstarter/customer-modules/stores/modules';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { Notice as NoticeType } from '@lawnstarter/ls-react-common/enums';
import { currency, date } from '@lawnstarter/ls-react-common/filters';
import { Notice } from '@lawnstarter/ls-react-common/organisms';

import { AutoTipToggle } from '@src/components/organisms';
import { PaymentForm } from '@src/components/organisms/forms';
import { useDispatch, useSelector } from '@src/hooks';

import { DetailsContainer, PageContainer, styles, TitleContainer } from './styles';

export function UpdatePaymentScreen() {
  const theme = useAppTheme();
  const stripe = useStripe();
  const stripeElements = useElements();
  const dispatch = useDispatch();

  const cardLast4 = useSelector(({ auth }) => auth.user.customer?.last4);
  const cardExpirationDate = useSelector(({ auth }) => auth.user.customer?.card_expiration_date);
  const balance = useSelector(({ auth }) => auth.user.customer?.balance || 0);

  const cardExpirationFormatted = useMemo(
    () => (cardExpirationDate ? date({ date: cardExpirationDate, format: 'monthYear' }) : ''),
    [cardExpirationDate],
  );

  const style = useMemo(() => styles(theme), [theme]);

  const hasOutstandingBalance = balance < 0;

  const [isUpdating, setIsUpdating] = useState(!cardLast4);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { submitCount },
  } = useForm();

  const submit = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const cardNumberElement = stripeElements?.getElement('cardNumber');
    if (cardNumberElement) {
      await dispatch(
        profile_updatePayment({
          stripe,
          stripeElement: cardNumberElement,
        }),
      );
      setIsUpdating(false);
    }

    setIsLoading(false);
  };

  const renderTitle = useCallback(
    () => (
      <div className="flex flex-col justify-center h-[80px] bg-primary mb-6 pl-6">
        <Text variant="titleLarge" style={style.title}>
          {isUpdating ? t('updatePaymentMethod') : t('profile.accountSettings.paymentAndTips')}
        </Text>
      </div>
    ),
    [isUpdating, style],
  );

  const renderCard = useCallback(
    () => (
      <>
        <div className="flex flex-col grow md:grow-0">
          <Text variant="bodyMedium" style={style.cardData} testID="current-payment">
            {t('payment.paymentMethod.currentPayment')}
          </Text>
          <div className="flex flex-row items-center">
            <Icon
              name="credit-card-outline"
              color={theme.colors.primary}
              size={theme.sizing.s9}
              style={style.cardIcon}
            />

            <Text variant="bodyMedium">{t('payment.paymentMethod.cardEnding')} </Text>
            <Text variant="bodyMedium" style={style.cardData}>
              {cardLast4}
            </Text>
            <Text variant="bodyMedium">, {t('payment.paymentMethod.expirationShort')} </Text>
            <Text variant="bodyMedium" style={style.cardData}>
              {cardExpirationFormatted}
            </Text>
          </div>

          <div data-testid="auto-tip-toggle" className="mt-6">
            <AutoTipToggle />
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <div className="grow md:grow-0 md:w-button-md">
            <Button
              trackID="navigate_to_update_payment_method_screen"
              testID="update-payment-button"
              mode="contained"
              onPress={() => setIsUpdating(true)}
            >
              {t('updatePaymentMethod')}
            </Button>
          </div>
        </div>
      </>
    ),
    [theme, style, cardLast4, cardExpirationFormatted],
  );

  const renderUpdateCardForm = () => (
    <>
      <div className="flex flex-col grow md:grow-0" data-testid="update-payment-form">
        <PaymentForm
          isRequired
          control={control}
          disabled={isLoading}
          name="update_payment_form"
          submitCount={submitCount}
        />

        {hasOutstandingBalance && (
          <div className="mt-8" data-testid="outstanding-balance-notification">
            <Notice type={NoticeType.Hint}>
              <div className="pt-3 pb-5 flex flex-col gap-4">
                <div className="flex flex-row items-center gap-3">
                  <Icon
                    name={'information-outline'}
                    color={theme.colors.primary}
                    size={theme.sizing.s7}
                  />
                  <Text
                    variant={'labelLarge'}
                    style={{
                      ...setFontWeight('700'),
                      color: theme.colors.primary,
                    }}
                  >
                    {t('pendingPayment.balanceWillBeProcessed')}
                  </Text>
                </div>
                <Text>
                  {t('pendingPayment.outstandingBalance', { value: currency({ amount: balance }) })}
                </Text>
              </div>
            </Notice>
          </div>
        )}
      </div>
      <div className="flex justify-center mt-8">
        <div className="grow md:grow-0 md:w-button-md">
          <Button
            trackID="update_payment_method"
            testID="update-payment-button"
            mode="contained"
            loading={isLoading}
            onPress={handleSubmit(submit)}
          >
            {t('payment.paymentMethod.save')}
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <PageContainer>
      <TitleContainer>{renderTitle()}</TitleContainer>
      <DetailsContainer>{isUpdating ? renderUpdateCardForm() : renderCard()}</DetailsContainer>
    </PageContainer>
  );
}
