import { useEffect } from 'react';

export const useScript = (src: string, id: string) => {
  useEffect(() => {
    // Check if the script already exists
    if (document.getElementById(id)) {
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.getElementById(id)?.remove();
    };
  }, [src, id]);
};
