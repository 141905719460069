import StyleSheet from 'react-native-media-query';
import { setFontWeight } from '@lawnstarter/ls-react-common';
import { MediaQuery } from '@lawnstarter/ls-react-common/enums';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export function useStyles(theme: AppTheme) {
  return StyleSheet.create({
    container: {
      flex: 1,
      padding: theme.spacing.s5,

      [MediaQuery.Small]: {
        padding: theme.spacing.s8,
      },
    },
    content: {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      paddingVertical: theme.spacing.s9,
    },
    title: {
      ...setFontWeight('700'),
      marginBottom: theme.spacing.s3,
      marginTop: theme.spacing.s6,
      textAlign: 'center',
    },
    description: {
      marginBottom: theme.spacing.s2,
      textAlign: 'center',
    },
  });
}
