import { t } from '@lawnstarter/customer-modules/services';
import { TitleHighlighted } from '@lawnstarter/ls-react-common/molecules';
import { Confirmation, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import type { Props } from './types';

export function CancellationDeflectionServiceDisputable(props: Props) {
  const buttons = [
    {
      label: t('submitDispute'),
      onPress: props.onPrimaryPress,
    },
    {
      label: t('cancelMyService'),
      mode: 'text' as const,
      onPress: props.onSecondaryPress,
    },
  ];

  const icon = 'alert-circle-outline';

  const description = [
    t('cancelService.deflection.serviceDisputable.description.line1'),
    t('cancelService.deflection.serviceDisputable.description.line2'),
  ];

  const title = (
    <TitleHighlighted
      contents={[
        {
          text: t('cancelService.deflection.serviceDisputable.title.youCanSubmit'),
        },
        {
          text: t('cancelService.deflection.serviceDisputable.title.service'),
          highlight: true,
        },
        {
          text: t('cancelService.deflection.serviceDisputable.title.dispute'),
          highlight: true,
        },
      ]}
      style={{ justifyContent: 'center' }}
    />
  );

  return (
    <ModalTemplate>
      <Confirmation
        buttons={buttons}
        description={description}
        icon={icon}
        origin="cancellation-deflection-service-disputable"
        stacked="always"
        title={title}
      />
    </ModalTemplate>
  );
}
