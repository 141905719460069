export const ERROR_SERVICE_LOCAL_DOMAINS = ['localhost', '127.0.0.1', ''];
export const ERROR_SERVICE_IGNORED_ERRORS = [
  'Invalid or Missing X-Auth-Token',
  'Network error',
  'Password reset required',
  'The email and password did not match',
  'There was a network error',
  'This password recovery has already been used or has expired',
  'Unauthorized',
  'You are not authorized to perform this action',
  'unexpected error',
];
