import { View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, Text } from '@lawnstarter/ls-react-common/atoms';

import { ModalFooter } from '@src/components/molecules';

import { useStyles } from './styles';

import type { Props } from './types';

export function ActionConfirmation({
  action,
  cancel,
  description,
  icon,
  origin,
  stacked,
  title,
}: Props) {
  const theme = useAppTheme();
  const { styles, ids } = useStyles(theme);

  return (
    <View dataSet={{ media: ids.container }} style={styles.container}>
      <View style={styles.content}>
        {icon && <Icon name={icon} size={theme.sizing.s13} color={theme.colors.primary} />}

        {title && (
          <Text variant="bodyLarge" style={styles.title}>
            {title}
          </Text>
        )}

        {description &&
          (Array.isArray(description) ? description : [description]).map((line) => (
            <Text key={line.trim()} variant="bodyMedium" style={styles.description}>
              {line}
            </Text>
          ))}
      </View>

      <ModalFooter stacked={stacked}>
        {cancel && (
          <Button
            mode="contained-tonal"
            onPress={cancel.onPress}
            trackID={`${origin ? `${origin}_` : ''}action_confirmation_modal-${cancel.trackID ?? 'cancel'}`}
          >
            {cancel.label ?? t('cancel')}
          </Button>
        )}

        <Button
          mode="contained"
          onPress={action.onPress}
          trackID={`${origin ? `${origin}_` : ''}action_confirmation_modal-${action.trackID ?? 'ok'}`}
        >
          {action.label ?? t('ok')}
        </Button>
      </ModalFooter>
    </View>
  );
}
