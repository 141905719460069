import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';

import type { ExternalLinkProps } from './types';

export function ExternalLink({
  action,
  iconSize,
  text,
  textStyle,
  trackID,
  variant,
}: ExternalLinkProps) {
  const theme = useAppTheme();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      action();
    }
  };

  return (
    <div
      className="flex cursor-pointer"
      onClick={action}
      onKeyDown={handleKeyDown}
      role="link"
      tabIndex={0}
    >
      <Text
        variant={variant}
        style={[{ color: theme.colors.blue.primary, paddingLeft: theme.spacing.s1 }, textStyle]}
        trackID={trackID}
      >
        {text}
        <Icon
          style={{ marginLeft: theme.spacing.s1, top: 1 }}
          name="open-in-new"
          size={iconSize}
          color={theme.colors.blue.primary}
        />
      </Text>
    </div>
  );
}
