import { createAppStore } from '@lawnstarter/customer-modules/stores';

import {
  apiService,
  authService,
  engagementService,
  errorService,
  headersService,
  trackingService,
} from '@src/services';

import reducers from './reducers';

// Let our services be available to all actions
const services = {
  lsApiService: apiService,
  lsErrorService: errorService,
  lsAuthTokenService: authService,
  lsHeadersService: headersService,
  lsTrackingService: trackingService,
  lsEngagementService: engagementService,
};

export default createAppStore({
  reducers,
  middlewares: [],
  extraArguments: { ...services },
});
