import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    faqInfo: {
      marginTop: theme.sizing.s3,
      marginLeft: theme.sizing.s1,
      marginBottom: 75,
    },
    orderLawnTreatmentContainer: {
      backgroundColor: theme.colors.white.primary,
      padding: theme.sizing.s4,
      width: '100%',
    },
    orderLawnTreatmentSubtitle: {
      fontWeight: '700',
      marginTop: theme.sizing.s5,
    },
    orderLawnTreatmentDescription: {
      marginTop: theme.sizing.s3,
    },
    orderLawnTreatmentItems: {
      marginTop: theme.sizing.s3,
      flexDirection: 'row',
    },
    planDetailsTitle: {
      marginTop: theme.sizing.s5,
      marginBottom: theme.sizing.s2,
      fontWeight: '500',
    },
    imagesContainer: {
      marginTop: theme.sizing.s5,
      flexDirection: 'column',
    },
    smallImagesContainer: {
      flex: 1,
      alignItems: 'center',
      marginVertical: theme.sizing.s3,
    },
    smallImage: {
      height: 240,
      width: '100%',
    },
    smallImageSubtitle: {
      position: 'absolute',
      top: '110%',
      transform: [{ translateX: 0 }, { translateY: -50 }],
      color: 'white',
      textAlign: 'center',
    },
    footer: {
      marginTop: theme.sizing.s2,
      fontWeight: '500',
      color: theme.colors.gray.tertiary,
      textAlign: 'center',
      marginBottom: 75,
      padding: theme.sizing.s6,
    },
    sectionTitle: {
      fontFamily: theme.fontVariant.primary.w600,
      fontWeight: '600',
      marginBottom: theme.spacing.s3,
    },
    divider: {
      marginVertical: theme.spacing.s3,
    },
    contentContainer: {
      flex: 1,
    },
    screenContainer: {
      flex: 1,
      margin: theme.spacing.s3,
      backgroundColor: theme.colors.white.primary,
    },
    dueSection: {
      display: 'flex',
      flexDirection: 'column',
    },
    dueDay: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dueDayTitle: {
      fontFamily: theme.fontVariant.primary.w700,
      fontWeight: '700',
      marginBottom: theme.spacing.s3,
    },
    dueDetails: {
      marginBottom: theme.spacing.s3,
    },
    serviceSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    servicePrice: {
      marginBottom: theme.spacing.s3,
    },
  });
};

export const StyledOrderLawnTreatmentContainer = tw.div`
  flex flex-col items-center w-full max-w-screen-lg mx-auto
`;

export const StyledOrderLawnTreatmentInfoContainer = tw.section`
  flex flex-col items-center w-full max-w-screen-lg mx-auto
`;

export const StyledFaqContainer = tw.div`
  flex flex-col w-full max-w-screen-lg mx-auto
`;

export const MobileOnlyWrapper = tw.div`
  flex flex-col md:hidden
`;

export const DesktopOnlyWrapper = tw.div`
  hidden md:flex flex-col w-full
`;
