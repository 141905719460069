import { useParams } from 'react-router-dom';
import { useChangeProController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { Button, ScrollView } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ChangeProForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader } from '@src/components';
import { useAppDownloadModal, useRouteNavigation } from '@src/hooks';

import { StyledButtonWrapper, StyledWrapper } from './styles';

export const ChangeMyProviderScreen = () => {
  const { navigate } = useRouteNavigation();
  const { showAppDownloadModal } = useAppDownloadModal();

  const confirmationModal = useModal(ModalTemplate.CONFIRMATION);

  const replaceWithServiceRoute = () =>
    navigate(WebRoutes.services, { options: { replace: true } });

  const { scheduleId } = useParams();

  const {
    fields,
    control,
    isUpdating,
    handleSubmit,
    descriptionLabel,
    descriptionText,
    noticeTexts,
  } = useChangeProController({
    confirmationModal,
    onDismissIneligibleProsModal: replaceWithServiceRoute,
    onDismissSuccessModal: replaceWithServiceRoute,
    onSelectLetProFix: () => {
      // Give enough time for the previous modal to finish its animation
      setTimeout(showAppDownloadModal, 150);
    },
    scheduleId: Number(scheduleId),
  });

  return (
    <>
      <DetailsHeader showBackButton={true} />

      <StyledWrapper>
        <ScrollView>
          <ChangeProForm
            control={control}
            descriptionLabel={descriptionLabel}
            descriptionText={descriptionText}
            fields={fields}
            isUpdating={isUpdating}
            noticeTexts={noticeTexts}
            origin="change_my_provider_screen"
          />
        </ScrollView>

        <StyledButtonWrapper>
          <Button
            mode="contained"
            loading={isUpdating}
            disabled={isUpdating}
            style={{ flex: 1 }}
            trackID="change_my_provider_screen-confirm"
            onPress={handleSubmit}
          >
            {t('submitRequest')}
          </Button>
        </StyledButtonWrapper>
      </StyledWrapper>
    </>
  );
};
