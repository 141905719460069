import { SummaryData } from '@lawnstarter/ls-react-common/types';

export const splitSummaryByIconAvailability = (
  { withIcons, withoutIcons }: { withIcons: SummaryData[]; withoutIcons: SummaryData[] },
  item: SummaryData,
) => {
  const hasIcon = !!item?.icon;

  return {
    withIcons: hasIcon ? [...withIcons, item] : withIcons,
    withoutIcons: !hasIcon ? [...withoutIcons, item] : withoutIcons,
  };
};
