import { useEffect } from 'react';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { HeaderMode } from '@lawnstarter/ls-react-common/enums';

import { useAppContext } from '@src/contexts';

import type { Key, ReactElement } from 'react';

interface WrapperProps {
  title?: string;
  headerMode?: HeaderMode;
  children?: ReactElement;
}

interface RouteCreatorProps extends Omit<WrapperProps, 'children'> {
  key?: Key;
  index?: boolean;
  path?: WebRoutes;
  component: ReactElement;
}

export const useRouteCreator = () => {
  const ComponentWrapper = ({ title, headerMode, children }: WrapperProps) => {
    const { setTitle, setHeaderMode } = useAppContext();

    useEffect(() => {
      const isReady = title && setTitle;

      if (isReady) setTitle(title);
      return () => (isReady ? setTitle('') : undefined);
    }, [title, setTitle]);

    useEffect(() => {
      const isReady = headerMode && setHeaderMode;

      if (isReady) setHeaderMode(headerMode);
      return () => (isReady ? setHeaderMode(HeaderMode.PropertySwitch) : undefined);
    }, [headerMode, setHeaderMode]);

    return <>{children}</>;
  };

  const createRoute = ({ component, index, path, key, ...wrapperProps }: RouteCreatorProps) => {
    if (typeof index !== 'boolean' && !path) {
      throw new Error('Route must have either an index or a path');
    }

    // When key is not provided, sets it as "index" if it's an index or as the route's path
    if (!key) key = index ? 'index' : path;

    return {
      ...(path && { path }),
      ...(index && { index }),
      element: (
        <ComponentWrapper key={key} {...wrapperProps}>
          {component}
        </ComponentWrapper>
      ),
    };
  };

  return {
    createRoute,
  };
};
