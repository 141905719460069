import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import { auth_signOut } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';

import { DropdownMenuItem } from '@src/components';
import { SelectContext } from '@src/contexts';
import { useDispatch, useSelector } from '@src/hooks';

import { styles } from './styles';

export function DesktopUserDropdownMenu() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const user = useSelector(({ auth }) => auth.user);
  const fullName = `${user?.first_name} ${user?.last_name}`;
  const initials = useMemo(() => {
    return [user?.first_name, user?.last_name]
      .filter((name) => name)
      .map((name) => name.charAt(0))
      .join('');
  }, [user]);

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-end',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [flip()],
  });

  const elementsRef = useRef<Array<HTMLElement | null>>([]);
  const labelsRef = useRef<Array<string | null>>([]);

  const handleSelect = useCallback((index: number | null) => {
    setSelectedIndex(index);
    setIsOpen(false);
  }, []);

  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    listNav,
    click,
    dismiss,
    role,
  ]);

  const selectContext = useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      getItemProps,
      handleSelect,
    }),
    [activeIndex, selectedIndex, getItemProps, handleSelect],
  );

  const menuItems = [
    {
      label: t('profile.logOut.button').toUpperCase(),
      onPress: async () => {
        await dispatch(auth_signOut());
      },
    },
  ];

  const profileMenuItem = (
    <div style={style.dropdownItem}>
      <Text style={style.dropdownProfileName}>{fullName}</Text>
      <br />
      <Text style={style.dropdownProfileEmail}>{user?.email}</Text>
    </div>
  );

  return (
    <>
      <div className="select-none cursor-pointer" ref={refs.setReference} {...getReferenceProps()}>
        <div className="flex items-center justify-center" style={style.initialsContainer}>
          <Text variant="bodyLarge" style={style.initials} testID="initials">
            {initials}
          </Text>
        </div>
      </div>
      <SelectContext.Provider value={selectContext}>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={{ ...floatingStyles, ...style.listBox }}
              {...getFloatingProps()}
            >
              <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                {profileMenuItem}
                <View style={style.dropdownSeparator} />
                {menuItems.map((action) => {
                  return (
                    <DropdownMenuItem
                      key={action.label}
                      label={action.label}
                      onPress={action.onPress}
                      style={style.dropdownItem}
                    />
                  );
                })}
              </FloatingList>
            </div>
          </FloatingFocusManager>
        )}
      </SelectContext.Provider>
    </>
  );
}
