import StyleSheet from 'react-native-media-query';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { MediaQuery } from '@lawnstarter/ls-react-common/enums';

export function useStyles() {
  const theme = useAppTheme();

  return StyleSheet.create({
    container: {
      flex: 1,
      padding: theme.spacing.s5,

      [MediaQuery.Small]: {
        padding: theme.spacing.s8,
      },
    },
    content: {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      paddingVertical: theme.spacing.s9,
      gap: theme.spacing.s2,

      [MediaQuery.Small]: {
        marginBottom: theme.spacing.s3,
      },
    },
    description: {
      textAlign: 'center',
    },
    title: {
      ...setFontWeight('600'),
      color: theme.colors.primary,
      marginBottom: theme.spacing.s1,
      textAlign: 'center',

      [MediaQuery.Small]: {
        marginBottom: theme.spacing.s2,
      },
    },
  });
}
