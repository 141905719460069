import { useEffect } from 'react';

import { DetailsHeader } from '@src/components';
import { useAppDownloadModal } from '@src/hooks';

export function PastServicesScreen() {
  const { showAppDownloadModal } = useAppDownloadModal();

  useEffect(() => {
    showAppDownloadModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <DetailsHeader />
    </div>
  );
}
