import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    boldFont: {
      ...setFontWeight('600'),
    },
    submitBtn: {
      color: theme.colors.white.primary,
    },
  });
};

export const StyledLeafRemovalWrapper = tw.section`
  flex flex-col m-3 gap-y-4
`;
