import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    sectionTitle: {
      marginLeft: 2,
      marginBottom: 6,
      ...setFontWeight('600'),
    },
    question: {
      ...setFontWeight('500'),
      marginVertical: theme.spacing.s2,
    },
    questionAnswer: {
      marginVertical: theme.sizing.s2,
      marginLeft: theme.sizing.s1,
    },
    link: {
      color: theme.colors.blue.primary,
      marginLeft: theme.sizing.s1,
    },
  });

export const FaqContainer = tw.div`
p-6  flex flex-col 
md:pt-0
`;

export const FaqSectionContainer = tw.div`my-3`;
