import { View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { currency } from '@lawnstarter/ls-react-common/filters';
import { Tooltip } from '@lawnstarter/ls-react-web-common';

import { ConfirmationType } from './enums';
import { useStyles } from './styles';

import type { Props } from './types';

export function TipAmountConfirmation({
  amount,
  percentage,
  showInformation = false,
  variation = ConfirmationType.AutoTip,
}: Props) {
  const theme = useAppTheme();
  const { ids, styles } = useStyles(theme);

  const i18nKey = `review.tipConfirmation.${variation === ConfirmationType.AutoTip ? 'autoTip' : 'oneTime'}`;

  const value = amount ? currency({ amount }) : `${percentage}%`;

  return (
    <View dataSet={{ media: ids.container }} style={styles.container}>
      <View dataSet={{ media: ids.texts }} style={styles.texts}>
        <View dataSet={{ media: ids.sideBySide }} style={styles.sideBySide}>
          <Text style={styles.title} variant="titleMedium">
            {t(`${i18nKey}.title`)}
          </Text>

          {showInformation && (
            <Tooltip
              arrow
              enterTouchDelay={100}
              leaveTouchDelay={3000}
              title={
                <View style={styles.tooltip}>
                  <Text style={styles.tooltipTitle}>{t('autoTip.adjustment.title')}</Text>
                  <Text style={styles.tooltipDescription}>
                    {t('autoTip.adjustment.description')}
                  </Text>
                </View>
              }
            >
              <button
                aria-label={t('review.tipConfirmation.showInformation')}
                style={styles.information}
                type="button"
              >
                <Icon
                  name="information-outline"
                  size={theme.sizing.s3}
                  color={theme.colors.primary}
                />
              </button>
            </Tooltip>
          )}
        </View>

        <Text dataSet={{ media: ids.description }} style={styles.description}>
          {t(`${i18nKey}.description`)}
        </Text>
      </View>

      <View dataSet={{ media: ids.sideBySide }} style={styles.sideBySide}>
        <Icon
          style={styles.check}
          name="check"
          size={theme.sizing.s5}
          color={theme.colors.primary}
        />

        <Text style={styles.value} variant="titleMedium">
          {t('review.tipConfirmation.tipAdded', { value })}
        </Text>
      </View>
    </View>
  );
}
