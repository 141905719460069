import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const StyleDetailsWrapper = tw.div`
  px-4
  md:p-6 md:pt-0
`;

export const StyledHelpDeskContainer = tw.div`
  hidden
  md:flex md:flex-col
`;

export const style = ({ theme }: { theme: AppTheme }) =>
  StyleSheet.create({
    helpDeskTitle: {
      display: 'flex',
      marginBottom: theme.spacing.s4,
      ...setFontWeight('600'),
    },
  });
