import StyleSheet from 'react-native-media-query';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { MediaQuery } from '@lawnstarter/ls-react-common/enums';

export function useStyles() {
  const theme = useAppTheme();

  return StyleSheet.create({
    container: {
      flex: 1,
      padding: theme.spacing.s5,

      [MediaQuery.Small]: {
        padding: theme.spacing.s8,
      },
    },
    content: {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      paddingVertical: theme.spacing.s9,
    },
    description: {
      fontSize: theme.fonts.bodyMedium.fontSize,
      lineHeight: theme.fonts.bodyMedium.lineHeight,
      marginBottom: theme.spacing.s3,
      textAlign: 'left',

      [MediaQuery.Small]: {
        fontSize: theme.fonts.bodyLarge.fontSize,
        lineHeight: theme.fonts.bodyLarge.lineHeight,
        marginBottom: theme.spacing.s5,
        maxWidth: '90%',
        textAlign: 'center',
      },
    },
    input: {
      flex: 1,
      width: '100%',
    },
    title: {
      ...setFontWeight('600'),
      color: theme.colors.primary,
      fontSize: theme.fonts.titleMedium.fontSize,
      lineHeight: theme.fonts.titleMedium.lineHeight,
      marginBottom: theme.spacing.s3,
      textAlign: 'center',

      [MediaQuery.Small]: {
        marginBottom: theme.spacing.s5,
        maxWidth: '70%',
      },
    },
  });
}
