import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { auth_requestPassword } from '@lawnstarter/customer-modules/stores/modules';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, Text, TextInput } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { useDispatch, useRouteNavigation } from '@src/hooks';
import { ResetPasswordModal } from '@src/modals';

import { StyledGoBack } from './styles';

export function PasswordRecoveryScreen() {
  const dispatch = useDispatch();
  const theme = useAppTheme();
  const { navigate } = useRouteNavigation();

  const [params] = useSearchParams();
  const token = useMemo(() => params.get('token'), [params]);

  const modal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const [isRequesting, setIsRequesting] = useState(false);

  const passwordResetModal = useModal(ResetPasswordModal);

  const { control, handleSubmit } = useForm<{
    email: string;
  }>({
    defaultValues: { email: '' },
    resolver: zodResolver(
      z.object({
        email: TextInput.Email.validationRule({
          invalid: t('validations.invalidEmail'),
          required: t('validations.emailRequired'),
        }),
      }),
    ),
  });

  useEffect(() => {
    if (token) {
      passwordResetModal.show();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const submit = async ({ email }: { email: string }) => {
    setIsRequesting(true);

    const response = await dispatch(auth_requestPassword({ email })).finally(() =>
      setIsRequesting(false),
    );

    if (response?.success) {
      const navigationAction = () => navigate(WebRoutes.login);

      return modal.show({
        icon: 'check',
        onBackButtonPress: navigationAction,
        onDismiss: navigationAction,
        action: t('forms.passwordRecovery.modal.emailSent'),
        messages: [t('forms.passwordRecovery.modal.checkInbox')],
      });
    }
  };

  return (
    <form className="flex flex-col">
      <div className="mt-1 mb-3 max-md:text-center">
        <Text variant="titleLarge" style={{ color: theme.colors.primary }}>
          {t('forms.passwordRecovery.title')}
        </Text>
      </div>
      <div className="mb-6 max-md:text-center">
        <Text style={{ color: theme.colors.primary }}>
          {t('forms.passwordRecovery.enterEmail')}
        </Text>
      </div>

      <TextInput.Email
        name="email"
        disabled={isRequesting}
        control={control}
        testID="email-input"
        trackID="password_recovery_screen-email"
        returnKeyType="go"
        onSubmitEditing={handleSubmit(submit)}
        label={t('email')}
      />

      <StyledGoBack>
        <Link
          to={WebRoutes.login}
          className="flex items-center with-hover max-md:mb-5 max-md:self-center"
        >
          <Icon name="arrow-left" size={theme.sizing.s5} color={theme.colors.primary} />
          <Text
            style={{
              ...setFontWeight('700'),
              paddingLeft: theme.spacing.s1,
              color: theme.colors.primary,
            }}
          >
            {t('forms.passwordRecovery.backToSignIn')}
          </Text>
        </Link>

        <Button
          mode="contained"
          trackID="password_recovery_screen"
          testID="send_recover_email_button"
          loading={isRequesting}
          onPress={handleSubmit(submit)}
        >
          {t('forms.passwordRecovery.sendEmail')}
        </Button>
      </StyledGoBack>
    </form>
  );
}
