import AsyncStorage from '@react-native-async-storage/async-storage';
import { AsyncStorageKeys, WebRoutes } from '@lawnstarter/customer-modules/enums';

const excludedIntents = [
  WebRoutes.root,
  WebRoutes.login,
  WebRoutes.noNavigation,
  WebRoutes.passwordRecovery,
];

export const setLoginIntent = ({ pathname }: { pathname?: string }) => {
  if (!pathname || excludedIntents.includes(pathname as WebRoutes)) {
    return;
  }

  AsyncStorage.setItem(AsyncStorageKeys.loginIntent, pathname);
};

export const getAndClearLoginIntent = async () => {
  const intent = await AsyncStorage.getItem(AsyncStorageKeys.loginIntent);

  AsyncStorage.removeItem(AsyncStorageKeys.loginIntent);

  return intent;
};
