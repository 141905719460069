import { Widget } from '@typeform/embed-react';
import { useCallback, useContext, useMemo } from 'react';
import { View } from 'react-native';
import { useSubmitSurveyMutation } from '@lawnstarter/customer-modules/stores/modules';
import { ModalContext } from '@lawnstarter/ls-react-common/contexts';

import { useStyles } from './styles';

import type { Props } from './types';

export function SatisfactionSurveyModal({ survey }: Props) {
  const [surveyMutation] = useSubmitSurveyMutation();
  const modalContext = useContext(ModalContext);
  const { styles, ids } = useStyles();

  const hidden = useMemo(
    () =>
      survey.hidden_fields.reduce((acc: Record<string, string>, field) => {
        acc[field.name] = field.value;
        return acc;
      }, {}),
    [survey.hidden_fields],
  );

  const onReady = useCallback(() => {
    surveyMutation({ surveyId: survey.survey_id });
  }, [survey.survey_id, surveyMutation]);

  return (
    <View dataSet={{ media: ids.container }} style={styles.container}>
      <Widget
        id={survey.form_id}
        className="w-full h-full"
        hidden={hidden}
        onReady={onReady}
        onEndingButtonClick={modalContext.hide}
      />
    </View>
  );
}
