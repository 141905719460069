import tw from 'tailwind-styled-components';

export const StyledHeader = tw.header`
  flex w-full py-7 px-10 bg-whitePrimary
  max-md:flex max-md:items-center max-md:justify-center max-md:px-0
`;

export const StyledMainContent = tw.div`
 self-center shrink-0 p-8 w-[540px] bg-whitePrimary border border-primary
 max-md:pt-5 max-md:px-3 max-md:w-11/12
`;
