import tw from 'tailwind-styled-components';

export const StyledTextContainer = tw.div`
  pb-1 pt-6 px-6
  max-md:hidden
`;

export const StyledConversationItemWrapper = tw.div<{ $index: number; $selected: boolean }>`
  px-6 py-4 ${(params) => (params.$index === 0 ? 'mt-2' : '')}
  ${(params) => (params.$selected ? 'selectedInboxItem' : '')}
  ${(params) => (params.$selected ? 'md:active:[&_div[data-media]]:!bg-[var(--primary)]' : 'md:active:!bg-[var(--secondaryContainer)]')}
  max-md:px-2
`;

export const StyledNoMessageContainer = tw.div`
mt-8
`;
