import { getBrand } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';

import { DetailsHeader, ExternalLink } from '@src/components';

type TermsAndPoliciesOption = {
  label: string;
  trackID: string;
  action: () => void;
};

export function TermsAndPoliciesScreen() {
  const brand = getBrand();

  const handleClick = (route: string) => {
    window.open(route, '_blank');
  };

  const termsAndPoliciesOptions: TermsAndPoliciesOption[] = [
    {
      label: t('termsAndPolicies.longGrassPolicy'),
      trackID: 'profile-long_grass_policy',
      action: () => handleClick(brand.policies.longGrassFee),
    },
    {
      label: t('termsAndPolicies.minimumCut'),
      trackID: 'profile-minimum_cut',
      action: () => handleClick(brand.policies.threeCutMin),
    },
    {
      label: t('termsAndPolicies.trustFee'),
      trackID: 'profile-trust_fee',
      action: () => handleClick(brand.policies.trustFee),
    },
    {
      label: t('termsAndPolicies.privacyPolicy'),
      trackID: 'profile-privacy_policy',
      action: () => handleClick(brand.policies.privacyPolicy),
    },
    {
      label: t('termsAndPolicies.termsService'),
      trackID: 'profile-terms_of_service',
      action: () => handleClick(brand.policies.termsOfService),
    },
  ];

  return (
    <div>
      <DetailsHeader />

      <div className="p-6 md:pt-0">
        {termsAndPoliciesOptions.map((policy, index) => (
          <div key={index} className="my-4">
            <ExternalLink
              action={policy.action}
              text={policy.label}
              trackID={policy.trackID}
              iconSize={18}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
