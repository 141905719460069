import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { useSubmitNotesMutation } from '@lawnstarter/customer-modules/stores/modules';
import { Button, Text, TextArea } from '@lawnstarter/ls-react-common/atoms';

import { ModalFooter } from '@src/components/molecules/ModalFooter';
import { ActionConfirmation } from '@src/components/organisms/ActionConfirmation';
import { useSelector, useServiceReviewConfirmation } from '@src/hooks';

import { useStyles } from './styles';
import { ServiceReviewCommentsFormData } from './types';

import type { Props } from './types';

export function ServiceReviewCommentsForm({ contractorName, onClose, reviewId }: Props) {
  const { styles, ids } = useStyles();

  const navigate = useNavigate();
  const [submitNotes] = useSubmitNotesMutation();
  const { sharedOnNextDoor } = useServiceReviewConfirmation();

  const customerId = useSelector(({ auth }) => auth.user.customer_id as number);

  const { control, watch, handleSubmit } = useForm<ServiceReviewCommentsFormData>();
  const notes = watch('notes');

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  const disabled = useMemo(() => !notes, [notes]);
  const name = useMemo(() => contractorName || t('yourCrew'), [contractorName]);

  const onSkip = () => {
    setShowConfirmation(true);
  };

  const onSubmit = handleSubmit(async () => {
    setUpdating(true);
    await submitNotes({ customer_id: customerId, review_id: reviewId, notes });
    setShowConfirmation(true);
  });

  if (showConfirmation) {
    if (sharedOnNextDoor) {
      return (
        <ActionConfirmation
          action={{
            label: t('close'),
            onPress: () => {
              onClose?.();
            },
          }}
          description={t('review.confirmation.description')}
          icon="check"
          title={t('review.confirmation.title')}
        />
      );
    }

    return (
      <ActionConfirmation
        action={{
          label: t('review.shareNow'),
          onPress: () => {
            navigate(WebRoutes.share);
            onClose?.();
          },
        }}
        cancel={{
          label: t('maybeLater'),
          onPress: () => {
            onClose?.();
          },
        }}
        description={t('review.shareOnNextDoorAndEarn')}
        icon="wallet-giftcard"
        stacked={true}
        title={t('review.shareOnNextDoor')}
      />
    );
  }

  return (
    <View dataSet={{ media: ids.container }} style={styles.container}>
      <View style={styles.content}>
        <Text dataSet={{ media: ids.title }} style={styles.title}>
          {t('review.comments.title', { name })}
        </Text>

        <Text dataSet={{ media: ids.description }} style={styles.description}>
          {t('review.comments.review', { name })}
        </Text>

        <TextArea
          containerStyle={styles.input}
          control={control}
          disabled={isUpdating}
          maxLength={1024}
          name="notes"
          placeholder={t('review.comments.placeholder')}
          trackID="service_review_comments_form-notes_input"
        />
      </View>

      <ModalFooter>
        <Button
          disabled={isUpdating}
          mode="contained-tonal"
          onPress={onSkip}
          trackID="service_review_comments_form-skip"
        >
          {t('skip')}
        </Button>

        <Button
          disabled={disabled || isUpdating}
          loading={isUpdating}
          mode="contained"
          onPress={onSubmit}
          trackID="service_review_comments_form-submit_notes"
        >
          {t('submit')}
        </Button>
      </ModalFooter>
    </View>
  );
}
