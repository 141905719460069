import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { useDisputeController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { Button, ScrollView, Text } from '@lawnstarter/ls-react-common/atoms';
import { DisputeReason } from '@lawnstarter/ls-react-common/enums';
import { CheckboxOptionsForm } from '@lawnstarter/ls-react-common/molecules';

import { DetailsHeader } from '@src/components';
import { useRouteNavigation } from '@src/hooks';

import { options } from './constants';
import { useStyles } from './styles';

import type { FormData } from './types';

export function DisputeLocationScreen() {
  const { styles } = useStyles();
  const { navigate } = useRouteNavigation();
  const { control, watch, handleSubmit } = useForm<FormData>();
  const { currentDispute, isUpdating, title, parseLocations, updateCurrentDispute } =
    useDisputeController();

  const hasLocationSelected = Boolean(watch('locations')?.length);

  function onContinue(data: FormData) {
    const locations = parseLocations(data.locations);

    updateCurrentDispute({ updatedValues: { ...locations } });

    const isNotesRequired = currentDispute.reason === DisputeReason.Other || locations.other_yard;

    if (isNotesRequired) {
      navigate(WebRoutes.disputeNotes, { options: { replace: true } });
    } else {
      navigate(WebRoutes.disputePhotos, { options: { replace: true } });
    }
  }

  return (
    <>
      <DetailsHeader showBackButton title={title} />

      <ScrollView>
        <View style={styles.container}>
          <Text style={styles.title} variant="titleMedium">
            {t('flows.reportIssue.whereWasTheIssue')}
          </Text>

          <Text style={styles.description} variant="bodySmall">
            {t('flows.reportIssue.asViewedFromTheStreet')}
          </Text>

          <CheckboxOptionsForm
            trackID="dispute_issue_location_screen-locations"
            name="locations"
            control={control}
            options={options}
          />
        </View>

        <View>
          <Button
            disabled={!hasLocationSelected || isUpdating}
            loading={isUpdating}
            mode="contained"
            onPress={handleSubmit(onContinue)}
            trackID="dispute_issue_location_screen-continue"
          >
            {t('continue')}
          </Button>
        </View>
      </ScrollView>
    </>
  );
}
