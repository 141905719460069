import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = ({ theme, deviceHeight }: { theme: AppTheme; deviceHeight: number }) => {
  // For short desktop screens, use 3/4 of the screen as the max height
  const maxHeight = deviceHeight > 600 ? 600 : deviceHeight * 0.75;

  return StyleSheet.create({
    horizontalLine: {
      height: 1,
      width: 'full',
      backgroundColor: theme.colors.gray.secondary,
    },
    homeContainer: {
      backgroundColor: theme.colors.white.primary,
    },
    dropDownSpan: {
      marginHorizontal: theme.spacing.s2,
      textAlign: 'center',
    },
    propertyOption: {
      padding: '0.75rem',
      fontSize: 'inherit',
      border: 'none',
      textAlign: 'left',
      outlineStyle: 'none',
    },
    listBox: {
      border: `1px solid ${theme.colors.gray.secondary}`,
      marginTop: '1rem',
      borderRadius: theme.radius.s2,
      backgroundColor: theme.colors.white.primary,
      outlineStyle: 'none',
      maxHeight,
    },
  });
};

export const StyledHomeContainer = tw.div`select-none cursor-pointer flex items-center property-switcher`;

export const StyledPropertyTextWrapper = tw.div`max-lg:hidden`;

export const StyledFloatingList = tw.div`
  overflow-x-hidden overflow-y-scroll flex flex-col
`;
