import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common/helpers';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    amountDueSession: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.sizing.s2 + 2,
    },
    container: {
      marginTop: theme.sizing.s3,
      backgroundColor: theme.colors.white.primary,
      borderWidth: 1,
      borderRadius: theme.sizing.s2,
      borderColor: theme.colors.gray.secondary,
      padding: theme.sizing.s6,
    },
    fontBold: {
      ...setFontWeight('700'),
    },
    sessionMargin: {
      marginTop: theme.sizing.s2 + 2,
    },
    divisor: {
      borderWidth: 1,
      borderColor: theme.colors.grayLight,
      marginTop: theme.sizing.s2 + 2,
    },
    serviceAmountSession: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.sizing.s2 + 2,
    },
    servicePeriodicitySession: {
      alignSelf: 'flex-end',
      marginTop: theme.sizing.s2 + 2,
    },
    goBackIconContainer: {
      marginTop: -theme.sizing.s9,
      width: 'fit-content',
    },
    goBackIcon: {
      width: theme.sizing.s6,
      height: theme.sizing.s6,
      borderColor: '#E5E7EB',
      backgroundColor: '#E5E7EB',
      borderRadius: 9999,
    },
  });
};

export const StyledLawnTreatmentConfirmationContainer = tw.div`
w-full md:w-[454px] lg:w-[549px] xl:w-[613px] [@media(min-width:2000px)]:w-[900px]
`;
