import BottomSheet from '@devvie/bottom-sheet';
import { snakeCase } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';
import tw from 'tailwind-styled-components';
import { eventEmitter } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { ActionList } from '@lawnstarter/ls-react-common/organisms';

import { useScrollBlock } from '@src/hooks';
import { MobileHeaderEvents } from '@src/layout/Header/MobileHeader/MobileHeaderEvents';

import { styles } from './styles';

import type { BottomSheetMethods } from '@devvie/bottom-sheet';
import type { Props } from './types';

export function HelpDeskBottomSheet({ actions, onActionPress }: Props) {
  const theme = useAppTheme();
  const [blockScroll, allowScroll] = useScrollBlock();
  const [sheetVisibility, updateSheetVisibility] = useState(false);
  const sheetRef = useRef<BottomSheetMethods>(null);
  const style = useMemo(() => styles(theme), [theme]);

  // Listen to open requests
  useEffect(() => {
    const helpDeskSelectPress = () => {
      sheetRef.current?.open();
      blockScroll();
    };

    const source = eventEmitter.on(
      MobileHeaderEvents.onHelpDeskSelectPressEvent,
      helpDeskSelectPress,
    );

    return () => {
      source.removeListener(MobileHeaderEvents.onHelpDeskSelectPressEvent, helpDeskSelectPress);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeBottomSheet = useCallback(() => {
    sheetRef.current?.close();
    allowScroll();
  }, [allowScroll]);

  const handleIconNames = (name?: string) => {
    if (name === 'contact-support') {
      return 'phone-message';
    } else if (name === 'support-action') {
      return 'comment-question';
    } else {
      return 'help-box';
    }
  };

  return (
    <StyledHelpDeskBottomSheetWrapper $sheetVisibility={sheetVisibility}>
      <BottomSheet
        height={'60%'}
        ref={sheetRef}
        style={{ zIndex: 20, backgroundColor: theme.colors.white.primary }}
        onAnimate={(animated: number) => {
          // Avoid weird visual behaviors
          if (animated === 0 && sheetVisibility) {
            updateSheetVisibility(false);
          } else if (animated > 0 && !sheetVisibility) {
            updateSheetVisibility(true);
          }
        }}
        onClose={() => {
          allowScroll();
        }}
        android_closeOnBackPress={false}
      >
        <View style={style.closeButtonContainer}>
          <Button
            labelStyle={style.closeButtonLabel}
            textColor={theme.colors.gray.tertiary}
            trackID="close-help-desk"
            onPress={closeBottomSheet}
            useLowercase
          >
            <Icon name="close" />
          </Button>
        </View>

        <View style={{ maxHeight: '95%', padding: theme.spacing.s5 }}>
          <Text variant="titleLarge" style={style.headerTitle}>
            {t('helpDesk')}
          </Text>
          <ActionList
            items={actions.map((action) => {
              return {
                label: action.title,
                trackID: `${snakeCase(action.title)}-help_desk_bottom_sheet`,
                icon: <Icon size={theme.sizing.s7} name={handleIconNames(action.icon)} />,
                action: () => {
                  closeBottomSheet();
                  onActionPress(action);
                },
              };
            })}
          />
        </View>
      </BottomSheet>
    </StyledHelpDeskBottomSheetWrapper>
  );
}

const StyledHelpDeskBottomSheetWrapper = tw.div<{ $sheetVisibility: boolean }>`
  ${(params) => (params.$sheetVisibility ? 'z-10' : '-z-10')}
  fixed top-0 right-0 left-0 h-full
`;
