import { debounce } from 'lodash';
import { useLayoutEffect, useState } from 'react';

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useLayoutEffect(() => {
    const handleSize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 350);

    window.addEventListener('resize', handleSize);
    handleSize();

    return () => window.removeEventListener('resize', handleSize);
  }, []);

  return windowSize;
}
