import { createContext, useContext, useState } from 'react';
import { HeaderMode } from '@lawnstarter/ls-react-common/enums';

import type { Nullable } from '@lawnstarter/ls-react-common/types';
import type { AppContextValue, BackAction } from '../types';

export const AppContext = createContext<AppContextValue>({} as AppContextValue);

export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [title, setTitle] = useState<string>('');
  const [subTitle, setSubTitle] = useState<Nullable<string>>(null);
  const [headerMode, setHeaderMode] = useState<Nullable<HeaderMode>>(HeaderMode.PropertySwitch);
  const [backButtonAction, setBackButtonAction] = useState<Nullable<BackAction>>(null);

  return (
    <AppContext.Provider
      value={{
        ...{ title, setTitle },
        ...{ subTitle, setSubTitle },
        ...{ headerMode, setHeaderMode },
        ...{ backButtonAction, setBackButtonAction },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
