import { StyleSheet } from 'react-native';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    sheetContainer: {
      zIndex: 20,
      backgroundColor: theme.colors.white.primary,
      padding: theme.spacing.s5,
      paddingTop: 0,
      paddingBottom: 0,
      overflowY: 'scroll',
      marginBottom: -100,
    },
    header: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing.s4,
    },
    headerTitle: {
      fontFamily: theme.fontVariant.primary.w600,
    },
    closeButtonLabel: {
      left: theme.spacing.s3,
    },
  });
