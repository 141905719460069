import { StyleSheet } from 'react-native';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    textarea: {
      backgroundColor: theme.colors.white.primary,
    },
  });
