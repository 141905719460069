import { GOOGLE_API_KEY } from '@env';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { setCurrentProperty } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { properties_addProperty } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate, PropertyForm } from '@lawnstarter/ls-react-common/organisms';

import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';

import { style } from './styles';

import type { PropertyFormData } from '@lawnstarter/ls-react-common/organisms';

export function NewPropertyForm({
  origin = 'new-property-form',
  onGoBack = _.noop,
  onClose = _.noop,
  goBackText = t('goBack'),
}: {
  origin?: string;
  onGoBack: () => void;
  onClose?: () => void;
  goBackText?: string;
}) {
  const theme = useAppTheme();
  const styles = useMemo(() => style(theme), [theme]);
  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();

  const modal = useModal(ModalTemplate.OPTIONAL_ACTION);
  const actionModal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const isUpdating = useSelector((state) => state.properties.loadingStatus.isUpdating);

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
    trigger,
  } = useForm<PropertyFormData>();

  const formProps = useMemo(
    () => ({
      origin,
      errors,
      control,
      setValue,
      isUpdating: false,
      revalidate: trigger,
      googleApiKey: GOOGLE_API_KEY,
      fieldTexts: {
        address: {
          label: t('forms.propertyForm.address'),
          placeholder: t('forms.propertyForm.addressPlaceholder'),
        },
        complement: {
          placeholder: t('forms.propertyForm.complementPlaceholder'),
        },
        city: {
          label: t('forms.propertyForm.city'),
          placeholder: t('forms.propertyForm.cityPlaceholder'),
        },
        state: {
          label: t('forms.propertyForm.state'),
          placeholder: t('forms.propertyForm.statePlaceholder'),
        },
        zip: {
          label: t('forms.propertyForm.zip'),
          placeholder: t('forms.propertyForm.zipPlaceholder'),
        },
      },
    }),
    [control, setValue, errors, origin, trigger],
  );

  const submit = async ({ street, city, state, zip, complement }: PropertyFormData) => {
    const { success, error, propertyId }: { success?: boolean; error?: any; propertyId?: number } =
      await dispatch(properties_addProperty({ street, city, state, zip, complement }));

    setCurrentProperty({ propertyId });

    if (success) {
      modal.show({
        icon: 'checkbox-marked-circle-plus-outline',
        action: t('newProperty.addressSaved'),
        buttonText: t('newProperty.bookService'),
        messages: [t('newProperty.modalMessage')],
        onAction: () => navigate(WebRoutes.browseRedirect),
      });

      // Wait for modal to appear and then close the bottom sheet
      setTimeout(onClose, 250);
      return;
    }

    actionModal.show({
      icon: 'block-helper',
      action: t('newProperty.notSaved'),
      buttonText: t('goBack'),
      messages: [error?.error?.message ?? t('newProperty.tryAgain')],
    });
  };

  const goBackAction = useCallback(() => {
    reset();
    onGoBack();
  }, [onGoBack, reset]);

  return (
    <View style={styles.container}>
      <Text variant="headlineSmall" style={styles.title}>
        {t('newProperty.title')}
      </Text>

      <PropertyForm {...formProps} />

      <View style={{ flex: 1, marginBottom: theme.spacing.s8 }} />

      <View>
        <Button
          onPress={handleSubmit(submit)}
          buttonColor={theme.colors.primary}
          textColor={theme.colors.onPrimary}
          trackID={`${origin}property_form-save_button`}
          style={styles.saveButton}
          disabled={isUpdating}
          loading={isUpdating}
        >
          {t('save')}
        </Button>

        <Button
          onPress={goBackAction}
          buttonColor={theme.colors.primaryLight}
          textColor={theme.colors.primary}
          trackID={`${origin}property_form-save_button`}
          disabled={isUpdating}
        >
          {goBackText}
        </Button>
      </View>
    </View>
  );
}
