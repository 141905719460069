import { Navigate } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { properties_currentPropertySelector } from '@lawnstarter/customer-modules/stores/modules';

import { Loader } from '@src/components';
import { useRouteNavigation, useSelector } from '@src/hooks';

export function BrowseRedirectScreen() {
  const { parseRoute } = useRouteNavigation();
  const property = useSelector(properties_currentPropertySelector);

  if (!property) {
    return <Loader testID="browse-spinner" />;
  }

  const params = { propertyId: property.id };
  return <Navigate to={parseRoute(WebRoutes.browse, { params })} replace />;
}
