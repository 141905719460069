import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useConversationScreenController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { ConversationHelpers } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { useStartConversationMutation } from '@lawnstarter/customer-modules/stores/modules';
import { setFontWeight } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { ActionUnavailable } from '@lawnstarter/ls-react-common/molecules';
import { ConversationItem } from '@lawnstarter/ls-react-common/organisms';

import { useDispatch, useRouteNavigation } from '@src/hooks';

import {
  StyledConversationItemWrapper,
  StyledNoMessageContainer,
  StyledTextContainer,
} from './styles';

import type { EmptyMessageProps } from '@lawnstarter/customer-modules/controllers';
import type { Conversation } from '@lawnstarter/ls-react-common/types';

export function InboxScreen() {
  const { navigate } = useRouteNavigation();
  const params = useParams();

  const dispatch = useDispatch();
  const [startConversation] = useStartConversationMutation();

  const {
    setCount,
    unreadCount,
    isConversationUninitialized,
    refreshConversations,
    conversationList,
    keyExtractor,
    renderListEmptyComponent,
  } = useConversationScreenController();

  useEffect(() => {
    if (!isConversationUninitialized) {
      refreshConversations();
    }
  }, [isConversationUninitialized, refreshConversations]);

  const renderItem = (item: Partial<Conversation>, index: number) => {
    const { conversation_id, contractor, unread_messages } = item;
    const contractorUserId = contractor?.user?.id;

    const selected = params?.conversationId
      ? params.conversationId === conversation_id?.toString()
      : index === 0;

    return (
      <StyledConversationItemWrapper
        data-testid={`conversation-${index}`}
        key={keyExtractor(item)}
        $selected={selected}
        $index={index}
      >
        <ConversationItem
          {...ConversationHelpers.getConversationItemParams(item)}
          onPressed={async () => {
            const conversationUnread = unread_messages ?? 0;
            if (conversationUnread > 0) {
              dispatch(setCount(unreadCount - conversationUnread));
            }

            if (!conversation_id && contractorUserId) {
              const response = await startConversation({ contractorUserId }).unwrap();
              return navigate(WebRoutes.conversation, {
                params: {
                  conversationId: response.id,
                },
              });
            }

            return navigate(WebRoutes.conversation, {
              params: {
                conversationId: conversation_id!,
              },
            });
          }}
        />
      </StyledConversationItemWrapper>
    );
  };

  if (conversationList.length === 0) {
    return renderListEmptyComponent({
      render: (props: EmptyMessageProps) => (
        <StyledNoMessageContainer>
          <ActionUnavailable {...props} fallbackAction={() => navigate(WebRoutes.browseRedirect)} />
        </StyledNoMessageContainer>
      ),
    });
  }

  return (
    <div className="md:min-h-[60vh]">
      <StyledTextContainer>
        <Text variant="titleLarge" style={{ ...setFontWeight('700') }}>
          {t('recentMessages')}
        </Text>
      </StyledTextContainer>

      {conversationList.map(renderItem)}
    </div>
  );
}
