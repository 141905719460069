import StyleSheet from 'react-native-media-query';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { MediaQuery } from '@lawnstarter/ls-react-common/enums';

export function useStyles() {
  const theme = useAppTheme();

  return StyleSheet.create({
    container: {
      flexDirection: 'column',
      gap: theme.spacing.s4,

      [MediaQuery.Small]: {
        flexDirection: 'row',
        gap: theme.spacing.s7,
        justifyContent: 'space-between',
      },
    },
    currencyInput: {
      backgroundColor: theme.colors.white.primary,
    },
    currencyInputContainer: {
      flex: 1,
      marginVertical: -theme.spacing.s3,
      paddingRight: theme.spacing.s1,
    },
    optionContainer: {
      minHeight: 'auto',
      position: 'relative',
    },
    options: {
      flex: 1,
      flexShrink: 0,
      width: '100%',
    },
    optionsWithHeader: {
      [MediaQuery.Small]: {
        maxWidth: '50%',
      },
    },
  });
}
