import { useLayoutEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCustomerQuoteController } from '@lawnstarter/customer-modules/controllers';
import { ScheduleSourceTypes, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { getJobBadge } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { instantQuote_acceptPendingQuote } from '@lawnstarter/customer-modules/stores/modules';
import { currency, date } from '@lawnstarter/ls-react-common/filters';
import { ScheduleEventDetails } from '@lawnstarter/ls-react-common/molecules';
import { PendingInstantQuoteViewer } from '@lawnstarter/ls-react-common/templates';

import { DetailsHeader, Loader } from '@src/components';
import { useAppContext } from '@src/contexts';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';
import { errorService } from '@src/services';

import { ContentWrapper } from './style';

import type { InstantQuote } from '@lawnstarter/ls-react-common/types';

export function PendingInstantQuoteScreen() {
  const dispatch = useDispatch();
  const { propertyId, sourceId } = useParams();
  const { navigate } = useRouteNavigation();
  const { setTitle } = useAppContext();

  const property = useSelector((state) => state.properties.propertiesById[propertyId as string]);
  const propertyQuote = useMemo<InstantQuote | undefined>(
    () => property?.instantQuotes?.find(({ id }: InstantQuote) => id === Number(sourceId)),
    [property?.instantQuotes, sourceId],
  );

  const { quote, quoteDescription, isLoading } = useCustomerQuoteController({
    propertyId: propertyId as string,
    sourceId: sourceId as string,
    type: ScheduleSourceTypes.InstantQuote,
    errorService,
  });

  useLayoutEffect(() => {
    if (quote && quote.id === Number(sourceId)) {
      setTitle(quote.service.formattedName);
    }
  }, [quote, sourceId, setTitle]);

  if (isLoading && !quote) {
    return <Loader testID="quote-details-loader" />;
  }

  const approve = async () => {
    const result = await dispatch(
      instantQuote_acceptPendingQuote({
        property_id: propertyId,
        instantQuoteId: sourceId,
      }),
    );

    if (result?.success) {
      navigate(WebRoutes.services, { options: { replace: true } });
    }
  };

  const cancel = () => {
    navigate(WebRoutes.services);
  };

  return (
    <div>
      <DetailsHeader showBackButton />

      <ContentWrapper>
        <ScheduleEventDetails.Summary
          badge={getJobBadge({ status: quote?.status })}
          items={[
            {
              icon: 'calendar',
              title: t('quote.expiresAt', {
                date: date({ date: propertyQuote?.hide_at, format: 'compactMonth' }),
              }),
            },
            {
              icon: 'currency-usd',
              title: t('quote.yourQuote', {
                price: currency({ amount: propertyQuote?.price || 0 }),
              }),
            },
          ]}
        />

        <PendingInstantQuoteViewer
          isLoading={isLoading}
          copy={quoteDescription}
          images={quote?.images || []}
          removeButtons={false}
          buttons={{
            approve: {
              label: t('quote.approve'),
              action: approve,
            },
            cancel: {
              label: t('cancel'),
              action: cancel,
            },
          }}
        />
      </ContentWrapper>
    </div>
  );
}
