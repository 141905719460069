import tw from 'tailwind-styled-components';
import { t } from '@lawnstarter/customer-modules/services';
import {
  InstantQuoteBushTrimmingConstants,
  InstantQuoteConstants,
} from '@lawnstarter/ls-constants';
import { DropdownInput, ImageUploadInput, TextArea } from '@lawnstarter/ls-react-common/atoms';
import { RadioOptionVariant } from '@lawnstarter/ls-react-common/enums';
import { CheckboxOptionsForm, RadioOptionsForm } from '@lawnstarter/ls-react-common/molecules';

import type { BushTrimmingFormProps } from './types';

export function BushTrimmingForm({ control }: BushTrimmingFormProps) {
  return (
    <>
      <StyledDropdownSection>
        <DropdownInput
          control={control}
          name="shrubsLessThan5"
          label={t('bushTrimmingForm.howMany.lessThan5')}
          options={InstantQuoteBushTrimmingConstants.shrubTrimCounts}
        />

        <DropdownInput
          control={control}
          name="shrubs5To10"
          label={t('bushTrimmingForm.howMany.5to10')}
          options={InstantQuoteBushTrimmingConstants.shrubTrimCounts}
        />

        <DropdownInput
          control={control}
          name="shrubsMoreThan10"
          label={t('bushTrimmingForm.howMany.over10')}
          options={InstantQuoteBushTrimmingConstants.shrubTrimCounts}
        />
      </StyledDropdownSection>

      <StyledFormSection>
        <RadioOptionsForm
          name="haulOff"
          control={control}
          label={t('bushTrimmingForm.haulOff')}
          variant={RadioOptionVariant.Flat}
          noPadding
          options={[
            {
              value: 1,
              label: t('yes'),
            },
            {
              value: 0,
              label: t('no'),
            },
          ]}
        />
      </StyledFormSection>

      <StyledFormSection>
        <CheckboxOptionsForm
          control={control}
          name="location"
          options={InstantQuoteBushTrimmingConstants.locations}
          title={t('bushTrimmingForm.location')}
        />
      </StyledFormSection>

      <StyledFormSection>
        <RadioOptionsForm
          name="frequency"
          label={t('bushTrimmingForm.frequency')}
          control={control}
          variant={RadioOptionVariant.Flat}
          noPadding
          options={InstantQuoteConstants.cycleOptions}
        />
      </StyledFormSection>

      <StyledFormSection>
        <TextArea
          control={control}
          name="details"
          label={t('bushTrimmingForm.details.label')}
          placeholder={t('bushTrimmingForm.details.placeholder')}
        />
      </StyledFormSection>

      <StyledFormSection>
        <ImageUploadInput
          name="images"
          control={control}
          isUploading={false}
          label={t('forms.uploadPhotos')}
          uploadText={t('forms.upload.uploading')}
          tooLargeFilesText={t('forms.upload.fileTooLarge')}
          addButton={{ text: t('forms.upload.placeholder') }}
        />
      </StyledFormSection>
    </>
  );
}

const StyledFormSection = tw.div`mb-5`;

const StyledDropdownSection = tw.div`flex flex-col gap-6 mb-6`;
