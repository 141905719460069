import { useContext } from 'react';
import { ScrollView, View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { ModalContext } from '@lawnstarter/ls-react-common/contexts';

import { NewPropertyForm } from '@src/components';

export function NewPropertyFormModal() {
  const theme = useAppTheme();
  const modalContext = useContext(ModalContext);

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <View style={{ flex: 1, width: '100%', padding: theme.spacing.s5 }}>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: theme.spacing.s7,
          }}
        >
          <div style={{ maxWidth: '90vw' }}>
            <NewPropertyForm onGoBack={modalContext.hide} goBackText={t('cancel')} />
          </div>
        </View>
      </View>
    </ScrollView>
  );
}
