import { useFlags } from 'flagsmith/react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { SCHEDULE_CYCLE_ONE_TIME } from '@lawnstarter/customer-modules/constants';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  getTrackingEventSource,
  useOrderServiceViewedTracking,
} from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_orderLtrsService,
  properties_currentPropertySelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { LtrsServices } from '@src/components/layouts';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';
import { errorService } from '@src/services';

import type { AerationFormData } from '@src/components/organisms/forms';

export function OrderAerationAndOverseedingScreen() {
  const { navigate } = useRouteNavigation();
  const dispatch = useDispatch();
  const location = useLocation();
  const carouselAbTest = useFlags([FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST])[
    FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST
  ];
  const modal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const property = useSelector(properties_currentPropertySelector);

  const apiTracking = useApiTracking()!;
  const source = carouselAbTest.enabled
    ? getTrackingEventSource(location.state?.source, carouselAbTest.value)
    : undefined;

  useOrderServiceViewedTracking(apiTracking, ServiceType.AerationOverSeeding, errorService, source);

  const { price, service } = useMemo(() => {
    const { options, service } = property?.priced?.services?.aeration_overseeding_package ?? {
      options: [{}],
      service: {},
    };
    const [price] = options;

    return {
      price,
      service,
    };
  }, [property?.priced?.services?.aeration_overseeding_package]);

  const submit = async (data: AerationFormData) => {
    const dataToCallAction = {
      price: price?.price,
      cycle: SCHEDULE_CYCLE_ONE_TIME,
      property_id: property.id,
      service_id: service?.service_id,
      starts_at: data.starts_at,
      source,
    };

    const {
      success = false,
      error,
      scheduleId,
    } = await dispatch(orderServices_orderLtrsService(dataToCallAction));

    if (success) {
      navigate(WebRoutes.serviceDetail, {
        params: { propertyId: property.id, scheduleId },
      });
    } else {
      modal.show({
        icon: 'block-helper',
        action: t('somethingWentWrong'),
        messages: [error?.error?.message ?? t('apiGenericError')],
        buttonText: t('tryAgain'),
      });
    }
  };

  return (
    <LtrsServices
      service={{
        name: t('ltrs.services.aerationAndOverseeding.information.header'),
        description: t('ltrs.services.aerationAndOverseeding.information.content'),
        bulletPoints: [
          t('ltrs.services.aerationAndOverseeding.information.bulletPoints.breakUp'),
          t('ltrs.services.aerationAndOverseeding.information.bulletPoints.increase'),
          t('ltrs.services.aerationAndOverseeding.information.bulletPoints.fillIn'),
          t('ltrs.services.aerationAndOverseeding.information.bulletPoints.enjoy'),
        ],
        notice: {
          firstLine: t('ltrs.services.aerationAndOverseeding.notice.serviceWindow'),
          secondLine: t(
            'ltrs.services.aerationAndOverseeding.notice.chargedAfterServiceCompletion',
          ),
        },
        price: {
          title: t('ltrs.services.aerationAndOverseeding.title'),
          recurrencyMode: t('ltrs.services.aerationAndOverseeding.price.recurrency'),
          unitCharge: t('ltrs.services.aerationAndOverseeding.price.unit'),
        },
        type: ServiceType.AerationOverSeeding,
      }}
      submit={submit}
    />
  );
}
