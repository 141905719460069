import { useMemo } from 'react';
import { Pressable, View } from 'react-native';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { properties_currentPropertySelector } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Spinner, Text } from '@lawnstarter/ls-react-common/atoms';
import { currency } from '@lawnstarter/ls-react-common/filters';

import { useRouteNavigation, useSelector } from '@src/hooks';
import { OrderLawnTreatmentContainer } from '@src/pages/Authorized/Browse/OrderLawnTreatmentScreen/styles';

import { StyledLawnTreatmentConfirmationContainer, styles } from './styles';

export function LawnTreatmentConfirmation({ price }: { price: string | number }) {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);
  const property = useSelector(properties_currentPropertySelector);
  const { navigate } = useRouteNavigation();

  return (
    <StyledLawnTreatmentConfirmationContainer>
      <Pressable
        accessibilityLabel={t('goBack')}
        onPress={() => navigate(WebRoutes.back)}
        style={style.goBackIconContainer}
      >
        <Icon name="arrow-left" size={theme.sizing.s6} style={style.goBackIcon} />
      </Pressable>
      <View style={style.container}>
        <Text variant="bodyMedium" style={{ fontWeight: '500' }}>
          {t('ltrs.orderLawnTreatmentConfirmation.serviceAddress')}
        </Text>

        <Text variant="bodyMedium" style={style.sessionMargin}>
          {`${property.street}, ${property.city}`}
        </Text>

        <Text variant="bodyMedium">{`${property.state} ${property.zip}`}</Text>

        <View style={style.divisor}></View>

        <Text variant="bodyMedium" style={[style.sessionMargin, { fontWeight: '500' }]}>
          {t('ltrs.orderLawnTreatmentConfirmation.service')}
        </Text>

        <View style={style.serviceAmountSession}>
          <Text variant="bodyMedium">{t('ltrs.orderLawnTreatmentConfirmation.serviceName')}</Text>
          {price ? (
            <Text variant="bodyMedium">
              {t('ltrs.aboutLawnTreatment.priceContainer.amountPerApplication', {
                amount: currency({ amount: price }),
              })}
            </Text>
          ) : (
            <Spinner />
          )}
        </View>

        <View style={style.servicePeriodicitySession}>
          <Text variant="bodyMedium">
            {t('ltrs.aboutLawnTreatment.priceContainer.periodicity')}
          </Text>
        </View>

        <View style={style.divisor}></View>

        <OrderLawnTreatmentContainer style={{ width: '100%', overflow: 'hidden' }}>
          <View style={style.amountDueSession}>
            <Text variant="bodyMedium" style={style.fontBold}>
              {t('ltrs.orderLawnTreatmentConfirmation.dueToday')}
            </Text>
            <Text variant="bodyMedium" style={[style.fontBold]}>
              {t('ltrs.orderLawnTreatmentConfirmation.dueTodayAmount')}
            </Text>
          </View>
          <View style={{ flexDirection: 'column' }}>
            <Text variant="bodyMedium" style={style.sessionMargin}>
              {t('ltrs.orderLawnTreatmentConfirmation.paymentDue')}
            </Text>

            <Text variant="bodyMedium" style={style.sessionMargin}>
              {t('ltrs.orderLawnTreatmentConfirmation.serviceSchedule')}
            </Text>
          </View>
        </OrderLawnTreatmentContainer>
      </View>
    </StyledLawnTreatmentConfirmationContainer>
  );
}
