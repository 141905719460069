import { t } from '@lawnstarter/customer-modules/services';
import { CancellationReason } from '@lawnstarter/ls-react-common/enums';
import { TitleHighlighted } from '@lawnstarter/ls-react-common/molecules';
import { Confirmation, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import imageURL from '@src/assets/man-lawn-mowing.svg';

import type { Props } from './types';

export function CancellationDeflectionChangePro(props: Props) {
  const buttons = [
    {
      label: t('yesChangeMyPro'),
      onPress: props.onPrimaryPress,
    },
    {
      label: t('cancelMyService'),
      mode: 'text' as const,
      onPress: props.onSecondaryPress,
    },
  ];

  const description =
    props.reason === CancellationReason.Scheduling
      ? [t('cancelService.deflection.changePro.scheduling.description')]
      : [
          t('cancelService.deflection.changePro.description.line1'),
          t('cancelService.deflection.changePro.description.line2'),
        ];

  const image = (
    <img alt="" data-testid="image" height={176} loading="lazy" src={imageURL} width={161} />
  );

  const titleTexts =
    props.reason === CancellationReason.Scheduling
      ? [
          {
            text: t('cancelService.deflection.changePro.scheduling.title.getNewProWhoFits'),
          },
          {
            text: t('cancelService.deflection.changePro.scheduling.title.yourSchedule'),
            highlight: true,
          },
        ]
      : [
          {
            text: t('cancelService.deflection.changePro.title.switchTo'),
          },
          {
            text: t('cancelService.deflection.changePro.title.topPerforming'),
            highlight: true,
          },
          {
            text: t('cancelService.deflection.changePro.title.pro'),
          },
        ];

  const title = <TitleHighlighted style={{ justifyContent: 'center' }} contents={titleTexts} />;

  return (
    <ModalTemplate>
      <Confirmation
        buttons={buttons}
        description={description}
        descriptionStyle={{ textAlign: 'center', width: '100%' }}
        icon={image}
        origin="cancellation-deflection-change-pro"
        stacked="always"
        title={title}
      />
    </ModalTemplate>
  );
}
