import { HELP_CENTER_CUSTOMER_POLICY_FAQ_3_CUT_MIN } from '@env';
import { useEffect, useMemo } from 'react';
import { ActivityIndicator } from 'react-native-paper';
import { useParams } from 'react-router-dom';
import {
  useCancellationController,
  usePauseController,
} from '@lawnstarter/customer-modules/controllers';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { useDispatch, useFeatureFlag } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  disputes_gotoFirstDisputeStep,
  disputes_initCurrentDispute,
  properties_getPropertiesAndSchedules,
} from '@lawnstarter/customer-modules/stores/modules';
import { RichText, ScrollView } from '@lawnstarter/ls-react-common/atoms';
import {
  CancellationBlockReason,
  CancellationDeflection,
  CancellationReason,
  DisputeType,
} from '@lawnstarter/ls-react-common/enums';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { CancellationReasonForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import {
  CancellationDeflectionChangePro,
  CancellationDeflectionChangeSchedule,
  CancellationDeflectionFeeSameDay,
  CancellationDeflectionFeeWithin48h,
  CancellationDeflectionMoving,
  CancellationDeflectionPause,
  CancellationDeflectionServiceDisputable,
  CancellationDeflectionServiceFixScheduled,
  CancellationDeflectionServicePendingFix,
  CancellationDeflectionSkip,
  CancellationDeflectionSupport,
  DetailsHeader,
} from '@src/components';
import { useAppContext } from '@src/contexts';
import { useAppDownloadModal, useRouteNavigation } from '@src/hooks';
import { trackingService } from '@src/services';

const ThreeCutMinimumLinkDescription = () => {
  const onLinkPress = () => {
    window.open(HELP_CENTER_CUSTOMER_POLICY_FAQ_3_CUT_MIN, '_blank');
  };
  return (
    <RichText
      linkActions={{ onLinkPress }}
      textStyle={{ width: '100%', alignSelf: 'stretch', textAlign: 'center' }}
    >
      {t('cancelService.threeCutMinimumModal.description.line2')}
    </RichText>
  );
};

export function CancelServiceScreen() {
  const propertyId = Number(useParams().propertyId);
  const scheduleId = Number(useParams().scheduleId);
  const { setTitle, setSubTitle } = useAppContext();

  const { navigate } = useRouteNavigation();
  const { showAppDownloadModal } = useAppDownloadModal();
  const isDisputeFlowEnabled = useFeatureFlag(FeatureFlags.ALLOW_DISPUTE_FLOW_WEB);
  const dispatch = useDispatch();

  const pauseModalOptionalAction = useModal(ModalTemplate.OPTIONAL_ACTION);
  const pauseModalActionConfirmation = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const feeActionConfirmation = useModal(ModalTemplate.CONFIRMATION);

  function onConfirmCancellation() {
    navigate(WebRoutes.confirmCancel, {
      params: routeParams,
      options: { state: { cancellationDetails } },
    });
  }

  function onDeflectionChangePro() {
    navigate(WebRoutes.changeMyPro, { params: routeParams });
  }

  function onDeflectionChangeSchedule() {
    navigate(WebRoutes.changeSchedule, { params: routeParams });
  }

  function onDeflectionPause() {
    showPauseModal();
  }

  function onDeflectionSkip() {
    navigate(WebRoutes.skipService, { params: routeParams });
  }

  function onDeflectionSupport() {
    navigate(WebRoutes.supportFromCancel, { params: routeParams });
  }

  async function onDeflectionServiceDisputable() {
    if (isDisputeFlowEnabled) {
      if (!property || !lastCompletedEvent) {
        return;
      }
      await dispatch(
        disputes_initCurrentDispute({
          dispute_type: DisputeType.Quality,
          property_id: property.id,
          schedule_id: schedule.id,
          scheduleevent_id: lastCompletedEvent.id,
        }),
      );

      const route = await dispatch(disputes_gotoFirstDisputeStep());
      navigate(route.nextStep);
    } else {
      showAppDownloadModal();
    }
  }

  function onDeflectionServicePendingFix() {
    navigate(WebRoutes.services, { params: routeParams });
  }

  function onDeflectionServiceFixScheduled() {
    navigate(WebRoutes.serviceDetail, {
      params: { propertyId: routeParams.propertyId, scheduleId: `${scheduleServiceFix?.id}` },
    });
  }

  function onDeflectionFeeSameDay() {
    navigate(WebRoutes.back);
  }

  function onDeflectionFeeWithin48h() {
    navigate(WebRoutes.back);
  }

  const {
    cancellationDetails,
    contractorName,
    deflection,
    isCustomQualityDeflectionFlow,
    isCustomSchedulingDeflectionFlow,
    isFlagImproveCancellationFeeDeflection,
    isLoading,
    onNextStep,
    onReasonChange,
    onReasonExplanationChange,
    reason,
    reasonExplanation,
    scheduleServiceFix,
    serviceName,
    schedule,
    property,
    lastCompletedEvent,
  } = useCancellationController({
    onConfirmCancellation,
    propertyId,
    scheduleId,
    trackingService,
  });

  const blockReason = cancellationDetails?.blockReason;
  const isThreeCutMinimum = blockReason === CancellationBlockReason.ThreeCutMinimum;

  const { showPauseModal } = usePauseController({
    schedule,
    modals: {
      optionalAction: pauseModalOptionalAction,
      actionConfirmation: pauseModalActionConfirmation,
    },
    onSuccess: () => {
      dispatch(properties_getPropertiesAndSchedules({ forceRefresh: true }));
      navigate(WebRoutes.services, { options: { replace: true } });
    },
  });

  const routeParams = useMemo(
    () => ({
      propertyId,
      scheduleId,
      cancellationReason: `${reason}`,
    }),
    [propertyId, scheduleId, reason],
  );

  function renderThreeCutMinimumModal() {
    const onDismiss = () => {
      navigate(WebRoutes.back);
    };

    const description = [
      t('cancelService.threeCutMinimumModal.description.line1'),
      ThreeCutMinimumLinkDescription,
    ];

    feeActionConfirmation.show({
      description,
      title: t('cancelService.threeCutMinimumModal.title'),
      buttons: [
        {
          label: t('close'),
          onPress: onDismiss,
        },
      ],
      icon: 'block-helper',
    });
  }

  setTitle(t('cancelService.title'));
  serviceName && setSubTitle(serviceName);

  useEffect(() => {
    if (isFlagImproveCancellationFeeDeflection && isThreeCutMinimum) {
      renderThreeCutMinimumModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isThreeCutMinimum, isFlagImproveCancellationFeeDeflection]);

  if (isLoading) {
    return (
      <>
        <DetailsHeader showBackButton />
        <ScrollView contentContainerStyleOverride={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator />
        </ScrollView>
      </>
    );
  }

  if (deflection === CancellationDeflection.ChangePro) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionChangePro
          onPrimaryPress={onDeflectionChangePro}
          onSecondaryPress={onNextStep}
          reason={isCustomSchedulingDeflectionFlow ? reason : null}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.ChangeSchedule) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionChangeSchedule
          onPrimaryPress={onDeflectionChangeSchedule}
          onSecondaryPress={onNextStep}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.ContactSupport) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionSupport
          onPrimaryPress={onDeflectionSupport}
          onSecondaryPress={onNextStep}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.Moving) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionMoving onPrimaryPress={onNextStep} />
      </>
    );
  }

  if (deflection === CancellationDeflection.Pause) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionPause
          contractorName={contractorName}
          onPrimaryPress={onDeflectionPause}
          onSecondaryPress={onNextStep}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.Skip) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionSkip
          onPrimaryPress={onDeflectionSkip}
          onSecondaryPress={onNextStep}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.ServiceDisputable) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionServiceDisputable
          onPrimaryPress={onDeflectionServiceDisputable}
          onSecondaryPress={onNextStep}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.ServicePendingFix) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionServicePendingFix
          onPrimaryPress={onDeflectionServicePendingFix}
          onSecondaryPress={onNextStep}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.ServiceFixScheduled) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionServiceFixScheduled
          onPrimaryPress={onDeflectionServiceFixScheduled}
          onSecondaryPress={onNextStep}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.FeeWithin48h) {
    return (
      <CancellationDeflectionFeeWithin48h
        onPrimaryPress={onDeflectionFeeWithin48h}
        onSecondaryPress={onNextStep}
        feeAmount={cancellationDetails?.feeAmount as number}
        serviceDate={schedule.next_starts_at as Date}
      />
    );
  }

  if (deflection === CancellationDeflection.FeeSameDay) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionFeeSameDay
          onPrimaryPress={onDeflectionFeeSameDay}
          onSecondaryPress={onNextStep}
          feeAmount={cancellationDetails?.feeAmount as number}
        />
      </>
    );
  }

  type CancellationReasonFormProps = Parameters<typeof CancellationReasonForm>[0];

  const props: CancellationReasonFormProps = isCustomSchedulingDeflectionFlow
    ? {
        onChange: onReasonExplanationChange,
        options: [
          CancellationReason.SchedulingNoProEver,
          CancellationReason.SchedulingDoesntWork,
          CancellationReason.SchedulingNoProLastTime,
        ].map((value) => ({
          label: t(`cancelService.reason.${value}`),
          value,
        })),
        title: t('cancelService.whatIssuesHaveYouHadWithYourSchedule'),
        selected: reasonExplanation ?? undefined,
      }
    : isCustomQualityDeflectionFlow
      ? {
          onChange: onReasonExplanationChange,
          options: [
            CancellationReason.QualityNotGoodEnough,
            CancellationReason.QualityServiceNotAsExpected,
            CancellationReason.QualityProIsPoaching,
            CancellationReason.QualityAnotherIssue,
          ].map((value) => ({
            label: t(`cancelService.reason.${value}`),
            value,
          })),
          title: t('cancelService.whatIssuesHaveYouHadWithYourServiceOrPro'),
          selected: reasonExplanation ?? undefined,
        }
      : {
          onChange: onReasonChange,
          options: [
            CancellationReason.Moving,
            CancellationReason.Temporary,
            CancellationReason.Scheduling,
            CancellationReason.Quality,
            CancellationReason.Charges,
            CancellationReason.Pricing,
            CancellationReason.Support,
          ].map((value) => ({
            label: t(`cancelService.reason.${value}`),
            value,
          })),
          title: t('cancelService.whyAreYouCancelling'),
          selected: reason ?? undefined,
        };

  return (
    <>
      <DetailsHeader showBackButton />
      {
        <ScrollView>
          <CancellationReasonForm {...props} />
        </ScrollView>
      }
    </>
  );
}
