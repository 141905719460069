import { useEffect, useMemo } from 'react';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';

import { StyledContainedSection } from '@src/components';
import { useRouteNavigation } from '@src/hooks';
import { trackingService } from '@src/services';

import { StyledCenteredContent, styles } from './styles';

export function NoMatch() {
  const { navigate } = useRouteNavigation();

  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  useEffect(() => {
    trackingService.trackScreen({ screen: 'NoMatch' });
  }, []);

  return (
    <StyledContainedSection>
      <StyledCenteredContent>
        <Text variant="headlineMedium" style={style.heading}>
          404 - PAGE NOT FOUND
        </Text>

        <Button
          trackID="no-match"
          mode="contained"
          onPress={() => navigate(WebRoutes.browseRedirect)}
        >
          GO TO HOME PAGE
        </Button>
      </StyledCenteredContent>
    </StyledContainedSection>
  );
}
