import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    title: {
      color: theme.colors.primary,
      ...setFontWeight('700'),
    },
  });
};

export const StyledServiceWrapper = tw.div`
  flex flex-col p-4 gap-2
  md:flex-row md:gap-12
`;
