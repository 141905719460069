import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const PageContainer = tw.div`
  flex flex-col grow
`;

export const TitleContainer = tw.div`
  hidden md:block
`;

export const DetailsContainer = tw.div`
  flex flex-col grow p-6 md:pt-0
`;

export const InputContainer = tw.div`
  flex pl-6 pr-[12px] pb-8
`;

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    title: {
      color: theme.colors.white.primary,
      ...setFontWeight('700'),
    },
  });
};
