import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCustomerQuoteController } from '@lawnstarter/customer-modules/controllers';
import { ScheduleSourceTypes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { QuoteDetailsViewer } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader, Loader } from '@src/components';
import { useSelector } from '@src/hooks';
import { errorService } from '@src/services';

import { StyledQuoteDetails } from './styles';

export function QuoteDetailsScreen() {
  const { propertyId, sourceId } = useParams();

  const schedule = useSelector((state) => state.schedules.schedulesById[sourceId as string]);

  const type = useMemo(
    () =>
      location.pathname.includes('instant-quote')
        ? ScheduleSourceTypes.InstantQuote
        : ScheduleSourceTypes.ManualQuote,
    [],
  );

  const quoteSourceId = type === ScheduleSourceTypes.InstantQuote ? schedule?.source_id : sourceId;
  const { quote, quoteDescription, isLoading } = useCustomerQuoteController({
    propertyId: propertyId as string,
    sourceId: quoteSourceId as string,
    type,
    errorService,
  });

  if (isLoading) {
    return <Loader testID="quote-details-loader" />;
  }

  return (
    <div>
      <DetailsHeader showBackButton />

      {quote && (
        <StyledQuoteDetails>
          <QuoteDetailsViewer
            description={quoteDescription}
            images={quote?.images || []}
            imagesLabel={t('servicePhotos')}
          />
        </StyledQuoteDetails>
      )}
    </div>
  );
}
