import { StyleSheet } from 'react-native';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';

export function useStyles() {
  const theme = useAppTheme();

  return {
    styles: StyleSheet.create({
      container: {
        flex: 1,
        marginBottom: theme.spacing.s4,
      },
      title: {
        ...setFontWeight('600'),
        marginBottom: theme.spacing.s6,
      },
    }),
    theme,
  };
}
