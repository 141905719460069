import { useEffect } from 'react';
import { t } from '@lawnstarter/customer-modules/services';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { errorService } from '@src/services';

export function GlobalErrorRenderer() {
  const errorModal = useModal(ModalTemplate.MESSAGES);

  useEffect(() => {
    errorService.setNotifyError(({ title = 'Error', message }) => {
      errorModal.show({ messages: [title, message], buttonText: t('close') });
    });
  }, [errorModal]);

  return <></>;
}
