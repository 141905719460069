import { useMemo } from 'react';
import { Divider } from 'react-native-paper';
import { useParams } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { reactivation_disagreeWithPolicies } from '@lawnstarter/customer-modules/stores/modules';
import { getLongGrassPolicy } from '@lawnstarter/ls-constants';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { useDispatch, useRouteNavigation } from '@src/hooks';

import { StyledButtonsWrapper, StyledPoliciesWrapper, styles } from './styles';

import type { MaterialIcon } from '@lawnstarter/ls-react-common/types';

export function ReactivationPoliciesScreen() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();
  const { propertyId = 0 } = useParams();

  const modal = useModal(ModalTemplate.OPTIONAL_ACTION);

  const handlePoliciesAgreement = () => {
    navigate(WebRoutes.propertyDetails, { params: { propertyId } });
  };

  const handlePoliciesDisagreement = async () => {
    modal.show({
      icon: '' as MaterialIcon,
      action: t('policies.disagree.modal.title'),
      messages: [t('policies.disagree.modal.content')],
      buttonText: t('policies.disagree.modal.cancel'),
      onAction: async () => {
        const { success = false } = await dispatch(
          reactivation_disagreeWithPolicies({ property_id: propertyId }),
        );

        if (success) {
          navigate(WebRoutes.browseRedirect);
        }
      },
    });
  };

  const policySections = [
    {
      title: t('long_grass.policy.label'),
      content: getLongGrassPolicy(),
    },
    {
      title: t('policies.threeCut.title'),
      content: [
        t('policies.threeCut.paragraphs.onlyCommitTo3Cuts'),
        t('policies.threeCut.paragraphs.ifNotHappyContactUs'),
      ],
    },
    {
      title: t('policies.rescheduleNotice.title'),
      content: [
        t('policies.rescheduleNotice.paragraphs.needToSkip'),
        t('policies.rescheduleNotice.paragraphs.goAheadSkip'),
      ],
    },
  ];

  if (!propertyId || propertyId === '0') {
    navigate(WebRoutes.browse);
  }

  return (
    <StyledPoliciesWrapper>
      <Text variant="headlineLarge" style={style.header}>
        {t('policies.title')}
      </Text>

      {policySections.map(({ title, content }) => (
        <section key={title} className="flex flex-col gap-6">
          <Text variant="titleLarge" style={style.title}>
            {title}
          </Text>

          {content.map((paragraph) => (
            <Text key={paragraph}>{paragraph}</Text>
          ))}
        </section>
      ))}

      <Divider />

      <StyledButtonsWrapper>
        <Button
          mode="contained"
          trackID="reactivation_policies_screen-agree"
          style={{ width: '100%' }}
          onPress={handlePoliciesAgreement}
        >
          {t('policies.agreeContinue')}
        </Button>

        <Button
          mode="contained-tonal"
          trackID="reactivation_policies_screen-disagree"
          style={{ width: '100%' }}
          onPress={handlePoliciesDisagreement}
        >
          {t('policies.disagree.button')}
        </Button>
      </StyledButtonsWrapper>
    </StyledPoliciesWrapper>
  );
}
