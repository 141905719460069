import tw from 'tailwind-styled-components';

export const StyledContainer = tw.div`
  p-2 flex flex-col gap-4 h-full
  md:p-6 md:pt-0 md:h-[545px]
`;

export const StyledFooter = tw.div`
  self-end w-full flex-1 flex flex-col justify-end
`;
