import { useParams } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { properties_currentPropertySelector } from '@lawnstarter/customer-modules/stores/modules';
import { date as dateFilter, frequency } from '@lawnstarter/ls-react-common/filters';
import { ActionList } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader } from '@src/components';
import { useRouteNavigation, useSelector } from '@src/hooks';

export function ChangeScheduleScreen() {
  const { scheduleId } = useParams();
  const { navigate } = useRouteNavigation();

  const schedule = useSelector((state) => state.schedules.schedulesById[scheduleId as string]);
  const selectedProperty = useSelector(properties_currentPropertySelector);

  const actions = [
    {
      trackID: `change_schedule_screen-reschedule`,
      label: t('changeSchedule.reschedule'),
      subtitle: dateFilter({ date: schedule?.next_starts_at }),
      action: () =>
        navigate(WebRoutes.oneTimeReschedule, {
          params: { propertyId: selectedProperty.id, scheduleId: schedule.id },
        }),
    },
    {
      trackID: `change_schedule_screen-frequency`,
      label: t('changeSchedule.frequency'),
      subtitle: frequency({ cycle: schedule?.cycle }),
      action: () =>
        navigate(WebRoutes.changeFrequency, {
          params: { propertyId: selectedProperty.id, scheduleId: schedule.id },
        }),
    },
  ];

  return (
    <div>
      <DetailsHeader showBackButton={true} />

      <div className="px-6">
        <ActionList items={actions} />
      </div>
    </div>
  );
}
