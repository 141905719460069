import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { profile_confirmEmail } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { Notice as NoticeType } from '@lawnstarter/ls-react-common/enums';
import { Notice } from '@lawnstarter/ls-react-common/organisms';

import { StyledResponsiveNotice } from '@src/components';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';

export function EmailsNotGoingThroughNotice() {
  const theme = useAppTheme();
  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();

  const { email, email_bounced } = useSelector(({ auth }) => auth.user);

  const navigateToSettings = () => navigate(WebRoutes.settings);
  const confirmEmail = () => dispatch(profile_confirmEmail());

  return (
    email_bounced && (
      <Notice type={NoticeType.Warn}>
        <StyledResponsiveNotice>
          <Text>{t('emailsNotGettingThrough', { email })}</Text>

          <div className="flex justify-end">
            <Button
              trackID="emails-not-going-through_confirm"
              textColor={theme.colors.blue.primary}
              onPress={confirmEmail}
            >
              {t('confirmEmail')}
            </Button>

            <Button
              trackID="emails-not-going-through_update"
              textColor={theme.colors.blue.primary}
              onPress={navigateToSettings}
            >
              {t('updateEmail')}
            </Button>
          </div>
        </StyledResponsiveNotice>
      </Notice>
    )
  );
}
