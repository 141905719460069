import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    heading: {
      ...setFontWeight('700'),
      textAlign: 'center',
      marginBottom: theme.spacing.s8,
    },
    errorBtn: {
      marginTop: theme.spacing.s5,
      backgroundColor: theme.colors.primaryLight,
    },
  });

export const StyledCenteredContent = tw.section`
  h-screen flex flex-col justify-center
`;
