import { StyleSheet } from 'react-native';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';

export function useStyles() {
  const theme = useAppTheme();

  return StyleSheet.create({
    container: {
      flex: 1,
    },
    description: {
      ...setFontWeight('400'),
      marginBottom: theme.spacing.s4,
    },
    header: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    title: {
      ...setFontWeight('600'),
      color: theme.colors.gray.tertiary,
      marginBottom: theme.spacing.s1,
    },
  });
}
