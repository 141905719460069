import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const PageContainer = tw.div`
  flex flex-col grow md:grow-0
`;

export const TitleContainer = tw.div`
  hidden md:block
`;

export const DetailsContainer = tw.div`
  flex flex-col grow md:grow-0 p-6 md:pt-0
`;

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    title: {
      color: theme.colors.white.primary,
      ...setFontWeight('700'),
    },
  });
};
