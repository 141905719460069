import StyleSheet from 'react-native-media-query';
import { setFontWeight } from '@lawnstarter/ls-react-common';
import { MediaQuery } from '@lawnstarter/ls-react-common/enums';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export function useStyles(theme: AppTheme) {
  return StyleSheet.create({
    container: {
      flex: 1,
      padding: theme.spacing.s5,
      width: '100%',

      [MediaQuery.Small]: {
        padding: theme.spacing.s8,
      },
    },
    contentSection: {
      flex: 1,
      paddingVertical: theme.spacing.s8,

      [MediaQuery.Small]: {
        paddingVertical: theme.spacing.s9,
      },
    },
    divider: {
      backgroundColor: theme.colors.primaryLight,
      marginBottom: theme.spacing.s4,

      [MediaQuery.Small]: {
        marginBottom: theme.spacing.s6,
      },
    },
    issueLabel: {
      ...setFontWeight('600'),
      color: theme.colors.primary,
      marginBottom: theme.spacing.s4,
      textAlign: 'center',

      [MediaQuery.Small]: {
        flex: 1,
        marginBottom: 0,
        marginRight: theme.spacing.s7,
        textAlign: 'left',
      },
    },
    issueOptions: {
      flex: 1,
      flexShrink: 0,
      width: '100%',

      [MediaQuery.Small]: {
        maxWidth: '50%',
      },
    },
    issueSection: {
      alignItems: 'center',
      justifyContent: 'center',

      [MediaQuery.Small]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },
    },
    loadingSection: {
      flex: 1,
      justifyContent: 'center',
    },
    ratingInput: {
      paddingHorizontal: theme.spacing.s1,
    },
    ratingLabel: {
      ...setFontWeight('600'),
      color: theme.colors.primary,
      fontSize: theme.fonts.titleMedium.fontSize,
      lineHeight: theme.fonts.titleMedium.lineHeight,
      marginBottom: theme.spacing.s2,
      textAlign: 'center',

      [MediaQuery.Small]: {
        marginBottom: 0,
        marginRight: theme.spacing.s7,
        textAlign: 'left',
      },
    },
    ratingSection: {
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing.s4,

      [MediaQuery.Small]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing.s5,
      },
    },
    ratingStar: {
      marginHorizontal: 1,
    },
    titleSection: {
      marginBottom: theme.spacing.s6,
    },
  });
}
