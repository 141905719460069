import { HELP_CENTER_CUSTOMER_POLICY_FAQ_WITHIN_48H } from '@env';
import { View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { RichText } from '@lawnstarter/ls-react-common/atoms';
import { currency, date } from '@lawnstarter/ls-react-common/filters';
import { TitleHighlighted } from '@lawnstarter/ls-react-common/molecules';
import { Confirmation, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import imageURL from '@src/assets/man-lawn-mowing.svg';

import { style } from './style';

import type { Props } from './types';

export function CancellationDeflectionFeeWithin48h(props: Props) {
  const theme = useAppTheme();
  const styles = style(theme);

  const serviceDate = date({ date: props.serviceDate });
  const feeAmount = currency({ amount: props.feeAmount * 0.01, trimTrailingZeros: true });
  const onLinkPress = () => {
    window.open(HELP_CENTER_CUSTOMER_POLICY_FAQ_WITHIN_48H, '_blank');
  };

  const buttons = [
    {
      label: t('nevermindDontCancel'),
      onPress: props.onPrimaryPress,
    },
    {
      label: t('payAndCancel', { feeAmount }),
      mode: 'text' as const,
      onPress: props.onSecondaryPress,
    },
  ];

  const image = (
    <img alt="" data-testid="image" height={176} loading="lazy" src={imageURL} width={161} />
  );

  const title = (
    <TitleHighlighted
      contents={[
        {
          text: t('cancelService.deflection.feeWithin48h.title.wait'),
        },
        {
          text: t('cancelService.deflection.feeWithin48h.title.yourServiceIs'),
        },
        {
          text: t('cancelService.deflection.feeWithin48h.title.scheduledFor'),
        },
        {
          text: serviceDate,
          highlight: true,
        },
      ]}
      style={{ justifyContent: 'center' }}
    />
  );

  const description = (
    <View>
      <RichText textStyle={styles.description}>
        {t('cancelService.deflection.feeWithin48h.description.line1', { serviceDate })}
      </RichText>
      <RichText textStyle={styles.description}>
        {t('cancelService.deflection.feeWithin48h.description.line2', { feeAmount })}
      </RichText>
      <RichText
        linkActions={{ onLinkPress }}
        textStyle={styles.link}
        containerStyle={styles.linkContainer}
      >
        {t('cancelService.deflection.feeWithin48h.description.line3')}
      </RichText>
    </View>
  );

  return (
    <ModalTemplate>
      <Confirmation
        buttons={buttons}
        description={description}
        icon={image}
        origin="cancellation-deflection-support"
        stacked="always"
        title={title}
      />
    </ModalTemplate>
  );
}
