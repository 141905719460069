// @ts-ignore
import iconFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';

import { useCSSBindTheme } from './controller';

export function CSSBindTheme() {
  const { cssVars, cssClasses } = useCSSBindTheme();

  return (
    <>
      <style type="text/css">
        {`
          :root {
            ${cssVars}
          }
          
          ${cssClasses}
          
          @font-face {
            src: url(${iconFont});
            font-family: MaterialCommunityIcons;
          };    
        `}
      </style>
    </>
  );
}
