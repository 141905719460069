import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSkipServiceController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { DayChange } from '@lawnstarter/ls-react-common/molecules';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader } from '@src/components';
import { useAppContext } from '@src/contexts';
import { useRouteNavigation } from '@src/hooks';

import { style, StyledButtonContainer } from './styles';

export const SkipServiceScreen = () => {
  const { setSubTitle } = useAppContext();

  const { navigate } = useRouteNavigation();
  const { scheduleId } = useParams();

  const theme = useAppTheme();
  const styles = useMemo(() => style(theme), [theme]);

  const confirmationModal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const binaryModal = useModal(ModalTemplate.BINARY_CHOICE);

  const {
    currentPropertyId,
    displayUnableToSkip,
    schedule,
    serviceName,
    dayChangeProps,
    isUpdating,
    getUnableToSkipMessageProps,
    onSkipService,
  } = useSkipServiceController({ scheduleId: scheduleId || 0 });

  const submit = async () => {
    const modalProps = await onSkipService({
      onSuccessModalClose: () => navigate(WebRoutes.services, { options: { replace: true } }),
    });

    confirmationModal.show(modalProps as any);
  };

  useEffect(() => {
    setSubTitle(serviceName);

    return () => setSubTitle(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceName]);

  useEffect(() => {
    if (!schedule) {
      navigate(WebRoutes.services, { options: { replace: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule]);

  useEffect(() => {
    if (displayUnableToSkip && schedule) {
      const modalProps = getUnableToSkipMessageProps({
        onBackPress: () => navigate(WebRoutes.back),
        onMessageProPress: () =>
          navigate(WebRoutes.conversation, {
            params: {
              conversationId: 0,
              contractorUserId: schedule.contractor?.user?.id,
            },
          }),
        onReturnToServicePress: () => {
          navigate(WebRoutes.serviceDetail, {
            options: { replace: true },
            params: {
              propertyId: currentPropertyId,
              scheduleId: schedule.id,
            },
          });
        },
      });

      binaryModal.show(modalProps as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayUnableToSkip]);

  return (
    <div>
      <DetailsHeader showBackButton={true} />

      <div className="flex flex-col px-6">
        <Text variant="bodyLarge" style={styles.title}>
          {t('skipMowScreen.noNeedService')}
        </Text>

        <Text style={styles.subTitle}>{t('skipMowScreen.feeAlert')}</Text>

        <DayChange {...dayChangeProps} />
      </div>

      <StyledButtonContainer>
        <Button
          testID="submit_request"
          trackID="skip_mow_screen-submit_request"
          mode="contained"
          onPress={submit}
          loading={isUpdating}
          disabled={isUpdating}
        >
          {t('skipMowScreen.skipService')}
        </Button>
      </StyledButtonContainer>
    </div>
  );
};
