import { useFlags } from 'flagsmith/react';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  formatLocationsPressureWash,
  getMQDefaultValues,
  getMQFieldData,
  getMQServiceInfo,
  MQHowQuotingWorksModal,
  MQSubmitSuccessModal,
} from '@lawnstarter/customer-modules/helpers';
import {
  getTrackingEventSource,
  useOrderServiceViewedTracking,
} from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_submitQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { ExtendedYardLocations } from '@lawnstarter/ls-constants';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { GenericQuoteForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { OrderServiceHeader } from '@src/components';
import { checkboxParser } from '@src/helpers';
import {
  useAutoScrollOnFormErrors,
  useDispatch,
  useRouteNavigation,
  useSelector,
} from '@src/hooks';

import { StyledFormContainer } from './styles';

import type {
  GenericFormFieldData,
  GenericQuoteFormData,
} from '@lawnstarter/ls-react-common/organisms';

export function GenericQuoteServiceScreen({ serviceType }: { serviceType: ServiceType }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const flags = useFlags([FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST]);
  const carouselAbTest = flags[FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST];
  const { navigate } = useRouteNavigation();
  const MQStepsModal = useModal(ModalTemplate.STEPS);
  const QuoteSubmittedModal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);

  const apiTracking = useApiTracking()!;
  const source = carouselAbTest.enabled
    ? getTrackingEventSource(location.state?.source, carouselAbTest.value)
    : undefined;

  useOrderServiceViewedTracking(apiTracking, serviceType, undefined, source);

  const propertyId = useSelector(properties_currentPropertyIdSelector);

  const {
    control,
    handleSubmit,
    formState: { errors, submitCount },
  } = useForm<GenericQuoteFormData>({ defaultValues: getMQDefaultValues(serviceType) });

  const serviceInfo = useMemo(() => {
    return getMQServiceInfo(serviceType);
  }, [serviceType]);

  useAutoScrollOnFormErrors({ errors, submitCount });

  const submit = async ({
    budget,
    locations,
    pendingImages,
    desired_work,
    cycle,
  }: GenericQuoteFormData) => {
    const handleFieldData = () => {
      if (serviceType === ServiceType.PressureWashing) {
        return {
          updatedFields: {
            property_id: propertyId,
            service_id: serviceInfo.id,
            service_str_id: serviceType,
            budget,
            pendingImages,
            desiredWorkDetails: formatLocationsPressureWash(locations, desired_work),
            cycle,
          },
        };
      } else {
        return {
          updatedFields: {
            property_id: propertyId,
            service_id: serviceInfo.id,
            service_str_id: serviceType,
            budget,
            pendingImages,
            desiredWorkDetails: desired_work,
            locations: checkboxParser(locations, ExtendedYardLocations),
            cycle,
          },
        };
      }
    };

    const result = await dispatch(orderServices_submitQuote({ ...handleFieldData(), source }));

    if (result?.success) {
      QuoteSubmittedModal.show(MQSubmitSuccessModal(navigate, WebRoutes.services));
      return;
    }
  };

  return (
    <StyledFormContainer>
      <OrderServiceHeader section={t('forms.quote.request')} serviceName={serviceInfo.name} />
      <div role="form" aria-labelledby="formTitle" className="flex flex-col">
        <GenericQuoteForm
          control={control}
          isUpdating={isUpdating}
          fieldData={getMQFieldData(serviceType) as GenericFormFieldData}
          infoModal={() => MQStepsModal.show(MQHowQuotingWorksModal())}
        />

        <Button
          trackID="submit"
          testID="submit"
          mode="contained"
          disabled={isUpdating}
          loading={isUpdating}
          onPress={handleSubmit(submit)}
        >
          {t('recommendations.getQuote')}
        </Button>
      </div>
    </StyledFormContainer>
  );
}
