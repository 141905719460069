import tw from 'tailwind-styled-components';

export const StyledContainer = tw.div`
  px-6 pb-6 flex flex-col
  max-md:pb-20
`;

export const StyledLabel = tw.div`
  flex flex-col gap-y-2 mb-6
`;

export const StyledButtonContainer = tw.div`
  absolute w-full p-6 bottom-[var(--footer-size)] left-0
  md:relative md:bottom-0 md:p-0 md:mt-4
`;
