import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    header: {
      color: theme.colors.primary,
      ...setFontWeight('700'),
    },
    title: {
      color: theme.colors.primary,
    },
  });
};

export const StyledPoliciesWrapper = tw.div`
  flex flex-col gap-6 my-6 mx-auto
  max-md:mx-4
  md:w-11/12
  lg:w-9/12"
`;

export const StyledButtonsWrapper = tw.div`
  flex
  max-md:flex-col max-md:gap-4
  md:w-4/12 md:gap-6
  lg:w-3/12 
`;
