import StyleSheet from 'react-native-media-query';
import { setFontWeight } from '@lawnstarter/ls-react-common';
import { MediaQuery } from '@lawnstarter/ls-react-common/enums';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export function useStyles(theme: AppTheme) {
  return StyleSheet.create({
    check: {
      marginRight: theme.spacing.s2,
    },
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',

      [MediaQuery.Small]: {
        alignItems: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    description: {
      marginVertical: theme.spacing.s3,
      textAlign: 'center',

      [MediaQuery.Small]: {
        textAlign: 'left',
      },
    },
    information: {
      alignItems: 'center',
      backgroundColor: theme.colors.transparent,
      borderColor: theme.colors.transparent,
      borderRadius: theme.sizing.s5,
      borderWidth: 0,
      cursor: 'help',
      display: 'flex',
      height: theme.sizing.s5,
      justifyContent: 'center',
      marginLeft: theme.spacing.s1 / 2,
      width: theme.sizing.s5,
    },
    sideBySide: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',

      [MediaQuery.Small]: {
        justifyContent: 'flex-start',
      },
    },
    texts: {
      flex: 1,
      textAlign: 'center',

      [MediaQuery.Small]: {
        maxWidth: '55%',
        textAlign: 'left',
      },
    },
    title: {
      ...setFontWeight('700'),
      color: theme.colors.primary,
      textAlign: 'center',
    },
    tooltip: {
      paddingHorizontal: theme.spacing.s3,
      paddingVertical: theme.spacing.s2,
    },
    tooltipDescription: {
      color: theme.colors.white.primary,
      fontSize: theme.fonts.bodySmall.fontSize,
      lineHeight: theme.fonts.bodySmall.lineHeight,
    },
    tooltipTitle: {
      ...setFontWeight('700'),
      color: theme.colors.white.primary,
      fontSize: theme.fonts.bodySmall.fontSize,
      lineHeight: theme.fonts.bodySmall.lineHeight,
      paddingBottom: theme.spacing.s2,
    },
    value: {
      ...setFontWeight('700'),
      textAlign: 'center',
    },
  });
}
