import { StyleSheet } from 'react-native';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const style = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      height: '100%',
    },
    title: {
      marginBottom: theme.spacing.s6,
      ...setFontWeight('600'),
    },
    saveButton: {
      marginBottom: theme.spacing.s3,
    },
  });
