import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { theme as defaultTheme, useAppTheme } from '@lawnstarter/ls-react-common';

export function useCSSBindTheme() {
  const appTheme = useAppTheme();

  // Resolve nested colors in flat colors
  const colors = useMemo(() => {
    const mergedColors = { ...defaultTheme.colors, ...appTheme.colors };
    const flatColors: [string, string][] = [];

    Object.entries(mergedColors).forEach((entry) => {
      typeof entry[1] === 'string'
        ? flatColors.push(entry as [string, string]) // ['primary', '#color']
        : flatColors.push(
            ...Object.entries(entry[1]).map(
              ([key, value]) => [`${entry[0]}${capitalize(key)}`, value] as [string, string], // ['grayPrimary', '#color']
            ),
          );
    });

    return flatColors;
  }, [appTheme.colors]);

  // Creates a branded Theme object
  const fullTheme = useMemo(() => {
    return {
      ...defaultTheme,
      ...appTheme,
      colors,
    };
  }, [appTheme, colors]);

  const colorsCssVars = useMemo(
    () => fullTheme.colors.map((color) => `--${color[0]}: ${color[1]};`),
    [fullTheme.colors],
  );

  type SpacingKeys = keyof typeof fullTheme.spacing;
  const spacingCssVars = useMemo(
    () =>
      Object.keys(fullTheme.spacing).map(
        (key) => `--${key}: ${fullTheme.spacing[key as SpacingKeys]}px;`,
      ),
    [fullTheme.spacing],
  );

  // Overriding default tailwind colors for background, text and border
  const colorsCssClasses = useMemo(
    () =>
      fullTheme.colors.map(
        ([color, _]) =>
          `.text-${color} { color: var(--${color}); } ` +
          `.border-${color} { border-color: var(--${color}); } ` +
          `.bg-${color} { background-color: var(--${color}); }`,
      ),
    [fullTheme.colors],
  );

  const cssVars = [...spacingCssVars, ...colorsCssVars].reduce((acc, value) => acc + value, '');
  const cssClasses = [...colorsCssClasses].reduce((acc, value) => acc + value, '');

  return { cssVars, cssClasses };
}
