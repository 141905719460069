import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const StyleLongGrassWrapper = tw.div`
  p-2
  md:p-6 md:pt-0
`;

export const style = ({ theme }: { theme: AppTheme }) =>
  StyleSheet.create({
    trustFee: {
      textAlign: 'right',
      marginRight: theme.spacing.s1,
      ...setFontWeight('600'),
    },
  });
