import { StyleSheet } from 'react-native';
import { type AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    label: {
      flex: 1,
      paddingVertical: theme.spacing.s5,
    },
  });
