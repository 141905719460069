import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const style = (theme: AppTheme) =>
  StyleSheet.create({
    title: {
      ...setFontWeight('600'),
      marginBottom: theme.spacing.s2,
    },
    subTitle: { marginBottom: theme.spacing.s3 },
  });

export const StyledButtonContainer = tw.div`
  absolute w-full p-6 bottom-[var(--footer-size)]
  md:relative md:bottom-0
`;
