import { t } from '@lawnstarter/customer-modules/services';
import { Table } from '@lawnstarter/ls-react-common/molecules';

import type { ComponentProps } from 'react';

type TableProps = ComponentProps<typeof Table>;

export function PlanDetailsTable() {
  const columns: TableProps['columns'] = [
    { identifier: 'problem', header: t('lawnProblem') },
    { identifier: 'treatment', header: t('treatmentAndTimeline') },
  ];

  const rows: TableProps['rows'] = [
    {
      problem: t('problems.weedRemoval'),
      treatment: `${t('treatments.preEmergent')};${t('treatments.lateSpring')};${t('treatments.postEmergent')};${t('treatments.springSummerFall')};${t('treatments.broadleafWeedControl')};${t('treatments.yearRound')}`,
    },
    {
      problem: t('problems.lawnHealth'),
      treatment: `${t('treatments.fertilization')};${t('treatments.yearRound')}`,
    },
  ];

  return (
    <Table
      columns={columns}
      rows={rows}
      rowKeyExtractor={(row: TableProps['rows'][0]) => row.problem.toString()}
    />
  );
}
