import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { auth_resetPassword } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { ModalContext } from '@lawnstarter/ls-react-common/contexts';

import { ChangePasswordForm } from '@src/components';
import { useDispatch } from '@src/hooks';

import type { ChangePasswordFormData } from '@src/components';

export function ResetPasswordModal() {
  const theme = useAppTheme();
  const modalContext = useContext(ModalContext);
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const token = params.get('token');

  const [isUpdating, setIsUpdating] = useState(false);

  const { control, setFocus, handleSubmit } = useForm<ChangePasswordFormData>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: zodResolver(
      z
        .object({
          password: z
            .string({ required_error: t('validations.passwordRequired') })
            .min(6, t('validations.passwordMinLength', { n: 6 })),
          confirmPassword: z
            .string({ required_error: t('validations.password') })
            .min(6, t('validations.passwordMinLength', { n: 6 })),
        })
        .refine((data) => data.password === data.confirmPassword, {
          path: ['confirmPassword'],
          message: t('validations.passwordsDontMatch'),
        }),
    ),
  });

  const resetPassword = useCallback(
    async ({ password, confirmPassword }: ChangePasswordFormData) => {
      setIsUpdating(true);

      const { success } = await dispatch(
        auth_resetPassword({ password, password_repeat: confirmPassword, token }),
      );

      if (success) {
        modalContext.hide();
        return (window.location.href = WebRoutes.login);
      }
      setIsUpdating(false);
    },
    [setIsUpdating, dispatch, token, modalContext],
  );

  return (
    <View style={{ padding: theme.spacing.s5 }}>
      <ChangePasswordForm
        control={control}
        isUpdating={isUpdating}
        origin="recover-password-screen"
        submit={() => handleSubmit(resetPassword)}
        setFocus={setFocus}
        fieldTexts={{
          password: { label: t('password') },
          confirmPassword: { label: t('passwordConfirmation') },
        }}
      />

      <Button
        mode="contained"
        trackID="password_modal-on_submit"
        onPress={handleSubmit(resetPassword)}
        style={{ marginTop: theme.spacing.s5 }}
        disabled={isUpdating}
        loading={isUpdating}
      >
        {t('settings.changePassword')}
      </Button>
    </View>
  );
}
