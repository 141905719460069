import { StyleSheet } from 'react-native';
import { useAppTheme } from '@lawnstarter/ls-react-common';

export function useStyles() {
  const theme = useAppTheme();

  return {
    styles: StyleSheet.create({
      container: {
        flex: 1,
        marginBottom: theme.spacing.s8,
        gap: theme.spacing.s8,
      },
    }),
    theme,
  };
}
