import { useMemo } from 'react';
import { generateInstantQuoteDisplayValues } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { currency } from '@lawnstarter/ls-react-common/filters';

import {
  ButtonContainer,
  InfoContainer,
  PriceAndIconContainer,
  PriceHeaderMainContainer,
  PriceHeaderSubContainer,
  styles,
} from './styles';

import type { InstantQuoteType } from '@lawnstarter/ls-react-common/enums';
import type { MaterialIcon } from '@lawnstarter/ls-react-common/types';
import type { GeneratedInstantQuoteViewerProps } from './types';

export function GeneratedInstantQuoteViewer({
  property_id,
  currentQuote,
  isUpdating,
  date,
  approveBtnText = t('instantQuote.viewer.approveButton'),
  rejectBtnText = t('instantQuote.viewer.rejectButton'),
  onApprove,
  onReject,
}: GeneratedInstantQuoteViewerProps) {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const { icon, statusText, dateDisplay } = useMemo(() => {
    return generateInstantQuoteDisplayValues({
      currentQuote: currentQuote,
      theme: theme,
      date: date,
    });
  }, [currentQuote, theme, date]);

  const actionText =
    currentQuote[currentQuote.type as Exclude<InstantQuoteType, InstantQuoteType.LongGrass>]?.copy
      ?.action;

  return (
    <section>
      <PriceHeaderMainContainer>
        <Text variant="titleMedium" style={{ ...style.bold, ...style.colorPrimary }}>
          {currentQuote.customer_accepted_at
            ? t('instantQuote.viewer.approvedQuote')
            : t('instantQuote.label')}
        </Text>
        <PriceHeaderSubContainer>
          <PriceAndIconContainer>
            <Icon name="note-edit-outline" color={theme.colors.green.primary} size={26} />
            <Text
              variant="titleLarge"
              style={{ ...style.price, ...style.colorPrimary, ...style.bold }}
            >
              {currency({
                amount: currentQuote.price,
              })}
            </Text>
          </PriceAndIconContainer>
          <Text variant="bodyMedium" style={{ ...style.priceContext, ...style.colorPrimary }}>
            {actionText}
          </Text>
        </PriceHeaderSubContainer>
      </PriceHeaderMainContainer>
      <div className="my-4" style={style.infoContainer}>
        <InfoContainer>
          <Icon name={icon.name as MaterialIcon} color={icon.color} size={20} />
          <Text>
            {statusText}
            <Text style={style.bold}>{dateDisplay}</Text>
          </Text>
        </InfoContainer>
      </div>
      <ButtonContainer>
        <Button
          mode="contained"
          testID="accept"
          trackID="instant_quote_form_generated_quote-accept"
          loading={isUpdating}
          disabled={isUpdating}
          style={style.button}
          onPress={() => onApprove({ property_id })}
        >
          <Text variant="bodySmall" style={{ ...style.accept, ...style.bold }}>
            {approveBtnText}
          </Text>
        </Button>
        <Button
          mode="contained-tonal"
          testID="turn_down"
          trackID="instant_quote_form_generated_quote-turn_down"
          disabled={isUpdating}
          style={style.button}
          onPress={() => onReject({ property_id })}
        >
          <Text variant="bodySmall" style={{ ...style.bold, ...style.colorPrimary }}>
            {rejectBtnText}
          </Text>
        </Button>
      </ButtonContainer>
    </section>
  );
}
