import { useState } from 'react';
import { View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import {
  review_autoTipSettingsSelector as autoTipSettingsSelector,
  auth_updateCustomer as updateCustomer,
  useSetAutoTipMutation,
} from '@lawnstarter/customer-modules/stores/modules';
import { Switch, Text } from '@lawnstarter/ls-react-common/atoms';

import { useDispatch, useSelector } from '@src/hooks';

import { TipAmountOptions } from '../TipAmountOptions';
import { useStyles } from './styles';

import type { Customer } from '@lawnstarter/ls-react-common/types';
import type { TipAmountOption } from '../TipAmountOptions';

export function AutoTipToggle() {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [setAutoTip, { isLoading: isUpdating }] = useSetAutoTipMutation();

  const { amount, customer_id, enabled, percentage } = useSelector(autoTipSettingsSelector);

  const defaultTip = TipAmountOptions.OptionPercent20;

  const tipOptionsMap: Record<number, TipAmountOption> = {
    0.1: TipAmountOptions.OptionPercent10,
    0.2: TipAmountOptions.OptionPercent20,
    0.3: TipAmountOptions.OptionPercent30,
    [amount ?? 0]: {
      key: TipAmountOptions.OptionOther.key,
      amount: (amount ?? 0) / 100,
    },
  };

  const [selectedTip, setSelectedTip] = useState<TipAmountOption | undefined>(
    enabled ? tipOptionsMap[percentage ?? amount ?? defaultTip.percentage] : undefined,
  );

  if (!customer_id) {
    return null;
  }

  const description = t(`autoTip.toggle.${enabled ? 'enabled' : 'disabled'}`);

  const onSwitchToggle = async (value: boolean) => {
    if (isUpdating) {
      return;
    }

    const customer = {
      autotip_amount: undefined,
      autotip_percentage: undefined,
      is_autotip_enabled: value,
    } as Customer;

    if (value) {
      setSelectedTip(defaultTip);
      customer.autotip_percentage = defaultTip.percentage / 100;
    }

    dispatch(updateCustomer(customer));

    await setAutoTip({
      amount: customer.autotip_amount,
      customer_id,
      enabled: customer.is_autotip_enabled as boolean,
      percentage: customer.autotip_percentage,
    });
  };

  const onTipChange = async (option: TipAmountOption) => {
    if (isUpdating) {
      return;
    }

    setSelectedTip(option);

    if (option.key === TipAmountOptions.OptionOther.key && !option.amount) {
      return;
    }

    const customer = {
      autotip_amount: undefined,
      autotip_percentage: undefined,
      is_autotip_enabled: true,
    } as Customer;

    if (option.amount) {
      customer.autotip_amount = Math.round(option.amount * 100);
    } else if (option.percentage) {
      customer.autotip_percentage = option.percentage / 100;
    }

    dispatch(updateCustomer(customer));

    await setAutoTip({
      amount: customer.autotip_amount,
      customer_id,
      enabled: customer.is_autotip_enabled as boolean,
      percentage: customer.autotip_percentage,
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View>
          <Text variant="titleMedium" style={styles.title}>
            {t('autoTip.toggle.title')}
          </Text>

          <Text variant="bodySmall" style={styles.description}>
            {description}
          </Text>
        </View>

        <Switch value={enabled} onValueChange={onSwitchToggle} />
      </View>

      <View style={{ display: 'flex', width: '100%' }}>
        <TipAmountOptions
          allowNoTip={false}
          disabled={!enabled}
          onSelect={onTipChange}
          selected={enabled ? selectedTip : undefined}
        />
      </View>
    </View>
  );
}
