import { useListItem } from '@floating-ui/react';
import { useContext } from 'react';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';

import { SelectContext } from '@src/contexts';

export function DropdownMenuItem({
  label,
  onPress,
  style,
}: {
  label: string;
  onPress: () => void;
  style?: object;
}) {
  const { activeIndex, selectedIndex, getItemProps, handleSelect } = useContext(SelectContext);
  const theme = useAppTheme();
  const { ref, index } = useListItem({ label });

  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;

  return (
    <button
      ref={ref}
      role="option"
      aria-selected={isActive && isSelected}
      tabIndex={isActive ? 0 : -1}
      {...getItemProps({
        onClick: () => {
          handleSelect(index);
          onPress();
        },
      })}
      style={{
        ...style,
        background: isActive ? theme.colors.ripple : '',
        paddingInline: theme.spacing.s5,
      }}
    >
      <Text style={{ ...setFontWeight('500') }}>{label}</Text>
    </button>
  );
}
