import { useAppTheme } from '@lawnstarter/ls-react-common';
import { TextInput } from '@lawnstarter/ls-react-common/atoms';

import type { ChangePasswordFormType } from './types';

export function ChangePasswordForm({
  control,
  isUpdating,
  origin,
  submit,
  setFocus,
  fieldTexts,
}: ChangePasswordFormType) {
  const theme = useAppTheme();

  return (
    <div>
      <TextInput.Password
        name="password"
        control={control}
        label={fieldTexts.password.label}
        placeholder={fieldTexts.password.placeholder}
        containerStyle={{ marginBottom: theme.spacing.s2 }}
        trackID={`${origin}-password`}
        testID="password-input"
        returnKeyType="next"
        enablesReturnKeyAutomatically
        onSubmitEditing={() => {
          setFocus('password');
        }}
        blurOnSubmit={false}
        disabled={isUpdating}
      />

      <TextInput.Password
        name="confirmPassword"
        control={control}
        label={fieldTexts.confirmPassword.label}
        placeholder={fieldTexts.confirmPassword.placeholder}
        trackID={`${origin}-confirm-password`}
        testID="confirm-password-input"
        returnKeyType="go"
        onSubmitEditing={submit()}
        blurOnSubmit={false}
        disabled={isUpdating}
      />
    </div>
  );
}
