import tw from 'tailwind-styled-components';

export const StyledCardContainer = tw.div`h-44 mt-1`;

export const StyledInnerCardContainer = tw.div`h-40 pl-5 pb-1 mx-3 rounded flex items-center`;

export const StyledCarouselContainer = tw.div`
w-full h-46
max-sm:pb-4
max-md:right-0
`;
