import StyleSheet from 'react-native-media-query';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { MediaQuery } from '@lawnstarter/ls-react-common/enums';

export function useStyles() {
  const theme = useAppTheme();

  return StyleSheet.create({
    textAreaContainer: {
      flex: 1,
      marginBottom: theme.spacing.s4,
      [MediaQuery.Tablet]: {
        marginBottom: 226,
      },
    },
    contentContainer: {
      height: '100%',
      [MediaQuery.Tablet]: {
        height: 600,
      },
    },
    textArea: {
      minHeight: 132,
    },
    title: {
      ...setFontWeight('600'),
      marginBottom: theme.spacing.s6,
    },
  });
}
