import { t } from '@lawnstarter/customer-modules/services';
import { TitleHighlighted } from '@lawnstarter/ls-react-common/molecules';
import { Confirmation, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import imageURL from '@src/assets/yard-services-in-progress.svg';

import type { Props } from './types';

export function CancellationDeflectionMoving(props: Props) {
  const buttons = [
    {
      label: t('continue'),
      onPress: props.onPrimaryPress,
    },
  ];

  const image = (
    <img alt="" data-testid="image" height={193} loading="lazy" src={imageURL} width={251} />
  );

  const title = (
    <TitleHighlighted
      contents={t('cancelService.deflection.moving.title')}
      punctuation="!"
      sentenceStyle={{ marginLeft: 0 }}
    />
  );

  return (
    <ModalTemplate>
      <Confirmation
        buttons={buttons}
        description={t('cancelService.deflection.moving.description')}
        descriptionStyle={{ textAlign: 'center' }}
        icon={image}
        origin="cancellation-deflection-moving"
        stacked="always"
        title={title}
      />
    </ModalTemplate>
  );
}
