import { AUTH__SIGN_OUT } from '@lawnstarter/customer-modules/stores/actions';
import { createAppReducers } from '@lawnstarter/customer-modules/stores/reducers';

import type { Reducer } from 'redux';

const appReducer = createAppReducers({
  reducers: {},
});

export type RootState = ReturnType<typeof appReducer>;

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === AUTH__SIGN_OUT) {
    // don't reset featureFlags on sign out
    state = {
      featureFlags: state?.featureFlags,
    } as RootState;
  }
  return appReducer(state, action);
};

export default rootReducer;
