import { useContext, useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { ModalContext } from '@lawnstarter/ls-react-common/contexts';
import { useModal } from '@lawnstarter/ls-react-common/hooks';

import { setServiceReviewShown, wasServiceReviewShown } from '@src/helpers';
import { ServiceReviewModal } from '@src/modals';

import { useSelector } from './useSelector';

import type { SkippableHookProps } from '@src/types';

export function useAskLastCompletedServiceReview({ skip = false }: SkippableHookProps = {}) {
  const modalContext = useContext(ModalContext);
  const reviewModal = useModal(ServiceReviewModal);
  const isBrowseRoute = useMatch(WebRoutes.browse);
  const [hasAskedReview, setAskedReview] = useState(false);
  const [hasFinished, setFinished] = useState(false);

  const isFetching = useSelector(({ schedules, properties }) => {
    return schedules.loadingStatus.isLoading || properties.loadingStatus.isLoading;
  });

  const completedEvent = useSelector(({ auth }) => {
    return auth.user.customer?.last_completed_scheduleevent;
  });

  useEffect(() => {
    const shouldSkip =
      skip || // Explicitly received to skip the hook.
      isFetching || // We are waiting Redux data to load.
      hasFinished; // This hook finished processing.

    if (shouldSkip) {
      return;
    }

    const cantReview =
      modalContext.visible || // Modal is already visible.
      !isBrowseRoute || // Shouldn't run outside the main screen.
      !completedEvent || // No last completed event to ask for review.
      !completedEvent.is_reviewable || // Service is not reviewable.
      Boolean(completedEvent.reviews?.length) || // Service already has reviews.
      wasServiceReviewShown(completedEvent?.id ?? 0); // User has already been asked for review.

    if (cantReview) {
      setFinished(true);
      return;
    }

    setServiceReviewShown(completedEvent.id);
    setAskedReview(true);
    setFinished(true);

    const { schedule } = completedEvent;

    reviewModal.show({
      animationType: 'fade',
      backdropCloseable: false,
      closeable: true,
      scheduleEventId: completedEvent.id.toString(),
      propertyId: schedule?.property_id?.toString(),
      scheduleId: schedule?.schedule_id?.toString(),
    });
  }, [
    completedEvent,
    hasFinished,
    isBrowseRoute,
    isFetching,
    modalContext.visible,
    reviewModal,
    skip,
  ]);

  return {
    hasAskedReview,
    isLoading: !hasFinished,
  };
}
