import StyleSheet from 'react-native-media-query';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { MediaQuery } from '@lawnstarter/ls-react-common/enums';

export function useStyles(tipMatching = false) {
  const theme = useAppTheme();

  return StyleSheet.create({
    container: {
      alignItems: 'center',

      [MediaQuery.Small]: {
        alignItems: 'flex-start',
        flex: 1,
      },
    },
    description: {
      ...setFontWeight('400'),
      fontSize: theme.fonts.bodyMedium.fontSize,
      lineHeight: theme.fonts.bodyMedium.lineHeight,
      textAlign: 'center',

      [MediaQuery.Small]: {
        fontSize: theme.fonts.bodyMedium.fontSize,
        lineHeight: theme.fonts.bodyMedium.lineHeight,
        textAlign: 'left',
      },
    },
    title: {
      ...setFontWeight('600'),
      color: theme.colors.primary,
      fontSize: (tipMatching ? theme.fonts.titleSmall : theme.fonts.titleMedium).fontSize,
      lineHeight: (tipMatching ? theme.fonts.titleSmall : theme.fonts.titleMedium).lineHeight,
      marginBottom: theme.spacing.s1,
      textAlign: 'center',

      [MediaQuery.Small]: {
        fontSize: theme.fonts.titleMedium.fontSize,
        lineHeight: theme.fonts.titleMedium.lineHeight,
        textAlign: 'left',
      },
    },
  });
}
