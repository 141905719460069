import { SEGMENT_KEY } from '@env';
import { useEffect } from 'react';

declare global {
  interface Window {
    analytics: any;
  }
}

export function useSegment(): void {
  useEffect(() => {
    if (SEGMENT_KEY) {
      (function () {
        const analytics = (window.analytics = window.analytics || []);
        if (!analytics.initialize) {
          if (analytics.invoked) {
            // eslint-disable-next-line no-console
            console.error('Segment snippet included twice.');
          } else {
            analytics.invoked = true;
            analytics.methods = [
              'trackSubmit',
              'trackClick',
              'trackLink',
              'trackForm',
              'pageview',
              'identify',
              'reset',
              'group',
              'track',
              'ready',
              'alias',
              'debug',
              'page',
              'once',
              'off',
              'on',
            ];
            analytics.factory = function (method: string) {
              return function () {
                const args = Array.prototype.slice.call(arguments);
                args.unshift(method);
                analytics.push(args);
                return analytics;
              };
            };
            for (let i = 0; i < analytics.methods.length; i++) {
              const method = analytics.methods[i];
              analytics[method] = analytics.factory(method);
            }
            analytics.load = function (key: string, options: any) {
              const script = document.createElement('script');
              script.type = 'text/javascript';
              script.async = true;
              script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
              const firstScript = document.getElementsByTagName('script')[0];
              firstScript?.parentNode?.insertBefore(script, firstScript);
              analytics._loadOptions = options;
            };
            analytics.SNIPPET_VERSION = '4.1.0';
            analytics.load(SEGMENT_KEY);
            analytics.page();
          }
        }
      })();
    }
  }, []);
}
