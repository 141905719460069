import { WebRoutes } from '@lawnstarter/customer-modules/enums';

import { useRouteCreator } from '@src/hooks';
import { AuthorizedInboxTemplate, ConversationScreen } from '@src/pages/Authorized/Inbox';

import type { User } from '@lawnstarter/ls-react-common/types';
import type { RouteObject } from 'react-router-dom';

export function InboxRouter({ user }: { user: User }): RouteObject[] {
  const { createRoute } = useRouteCreator();

  return [
    {
      path: WebRoutes.inbox,
      element: <AuthorizedInboxTemplate user={user} />,
      children: [
        createRoute({
          index: true,
          component: <ConversationScreen />,
        }),
        createRoute({
          key: 'conversation',
          path: WebRoutes.conversation,
          component: <ConversationScreen />,
        }),
      ],
    },
  ];
}
