import tw from 'tailwind-styled-components';

export const StyledContainer = tw.div`
px-6 pb-6 flex flex-col gap-y-5
`;

export const StyledButtonContainer = tw.div`
  absolute w-full p-6 bottom-[var(--footer-size)] left-0
  md:relative md:bottom-0 md:p-0 md:mt-4
`;
