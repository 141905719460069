import tw from 'tailwind-styled-components';

export const StyledWelcome = tw.div`
  mt-1 mb-3
  max-md:text-center
`;

export const StyledDetails = tw.div`
  mb-6
  max-md:text-center
`;

export const StyledRecovery = tw.div`
  flex items-center justify-between pt-6
  max-md:flex-col max-md:items-stretch max-md:justify-center
`;
