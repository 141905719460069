import { useMemo } from 'react';

import { useAskLastCompletedServiceReview } from '@src/hooks/useAskLastCompletedServiceReview';
import { useAskSatisfactionSurvey } from '@src/hooks/useAskSatisfactionSurvey';
import { useAskServiceReviewByRoute } from '@src/hooks/useAskServiceReviewByRoute';

export function useAskInteractionOnStartup() {
  const {
    hasAskedReview: hasAskedLastCompletedServiceReview,
    isLoading: isLoadingAskLastCompletedServiceReview,
  } = useAskLastCompletedServiceReview();

  const skipReviewByRoute = useMemo(() => {
    return hasAskedLastCompletedServiceReview || isLoadingAskLastCompletedServiceReview;
  }, [hasAskedLastCompletedServiceReview, isLoadingAskLastCompletedServiceReview]);

  const {
    hasAskedReview: hasAskedServiceReviewByRoute,
    isLoading: isLoadingAskServiceReviewByRoute,
  } = useAskServiceReviewByRoute({
    skip: skipReviewByRoute,
  });

  const skipSurvey = useMemo(() => {
    return skipReviewByRoute || hasAskedServiceReviewByRoute || isLoadingAskServiceReviewByRoute;
  }, [skipReviewByRoute, hasAskedServiceReviewByRoute, isLoadingAskServiceReviewByRoute]);

  const { hasAskedSurvey: hasAskedSatisfactionSurvey } = useAskSatisfactionSurvey({
    skip: skipSurvey,
  });

  const hasAskedInteraction = useMemo(() => {
    return (
      hasAskedLastCompletedServiceReview ||
      hasAskedServiceReviewByRoute ||
      hasAskedSatisfactionSurvey
    );
  }, [
    hasAskedLastCompletedServiceReview,
    hasAskedServiceReviewByRoute,
    hasAskedSatisfactionSurvey,
  ]);

  return { hasAskedInteraction };
}
