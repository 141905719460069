import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import {
  properties_currentPropertySelector,
  properties_updatePropertyInfo,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { PropertyDetailsForm } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader, Loader } from '@src/components';
import {
  useAutoScrollOnFormErrors,
  useDispatch,
  useRouteNavigation,
  useSelector,
} from '@src/hooks';

import { mapDataToForm } from './mapDataToForm';
import { mapFormDataToAction } from './mapFormDataToApi';
import { formLabels } from './propertyFormLabels';
import { PropertyDetailsContentWrapper } from './styles';

import type { PropertyDetailsFormData } from '@lawnstarter/ls-react-common/organisms';

export function PropertyDetailsScreen() {
  const theme = useAppTheme();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { propertyId = '0' } = useParams();

  const { navigate, parseRoute } = useRouteNavigation();

  const property = useSelector(properties_currentPropertySelector);

  const shouldHidePageTitle = () =>
    pathname === parseRoute(WebRoutes.propertyDetails, { params: { propertyId } });

  const {
    control,
    handleSubmit,
    formState: { errors, submitCount },
    watch,
    setValue,
    trigger,
    reset,
  } = useForm<PropertyDetailsFormData>();

  useEffect(() => {
    reset(mapDataToForm(property?.info));
  }, [reset, property?.info]);

  useAutoScrollOnFormErrors({ errors, submitCount });

  const submit = async (formData: PropertyDetailsFormData) => {
    const formattedData = {
      property_id: property.id,
      info: mapFormDataToAction(property?.info, formData),
    };

    const { success = false } = await dispatch(properties_updatePropertyInfo(formattedData));

    if (success) {
      navigate(WebRoutes.services);
    }
  };

  const renderTitle = () => {
    if (shouldHidePageTitle()) {
      return;
    }

    return <DetailsHeader />;
  };

  if (!property) {
    return <Loader />;
  }

  return (
    <div>
      {renderTitle()}

      <PropertyDetailsContentWrapper>
        <form>
          <PropertyDetailsForm
            origin="property-details-screen"
            isUpdating={false}
            control={control}
            fieldTexts={formLabels}
            watch={watch}
            setValue={setValue}
            revalidate={trigger}
            errors={errors}
          />
          <Button
            mode="contained"
            loading={false}
            onPress={handleSubmit(submit)}
            style={{ marginTop: theme.spacing.s5 }}
            trackID="property-details-screen_submit-btn"
            testID="property-details-screen_submit-btn"
          >
            {t('saveAndContinue')}
          </Button>
        </form>
      </PropertyDetailsContentWrapper>
    </div>
  );
}
