import StyleSheet from 'react-native-media-query';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { MediaQuery } from '@lawnstarter/ls-react-common/enums';

export function useStyles(stacked = false) {
  const theme = useAppTheme();

  return StyleSheet.create({
    container: {
      alignItems: 'center',
      columnGap: theme.spacing.s4,
      flexDirection: stacked ? 'column-reverse' : 'row',
      justifyContent: 'center',
      marginHorizontal: 'auto',
      maxWidth: 350,
      rowGap: theme.spacing.s3,
      width: '100%',

      [MediaQuery.Small]: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        maxWidth: 'none',
      },
    },
    item: {
      flex: 1,
      width: '100%',

      [MediaQuery.Small]: {
        flex: 'none',
        width: 'auto',
      },
    },
  });
}
