import { useMemo } from 'react';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text, TouchableRipple } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';

import { NewPropertyFormModal } from '@src/modals';

import { StyledAddPropertyButtonInnerContainer, styles } from './styles';

export function AddPropertyButton() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const modal = useModal(NewPropertyFormModal);

  return (
    <TouchableRipple
      style={{ ...style.addPropertyButton }}
      onPress={() => {
        modal.show();
      }}
    >
      <StyledAddPropertyButtonInnerContainer>
        <Icon name="plus-thick" size={theme.sizing.s5} color={theme.colors.gray.tertiary} />
        <Text style={style.buttonText}>{t('addPropertyAddress')}</Text>
      </StyledAddPropertyButtonInnerContainer>
    </TouchableRipple>
  );
}
