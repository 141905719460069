import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMessageUsScreenController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { Button, Text, Toast } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { DescribeIssueForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader } from '@src/components';
import { useRouteNavigation } from '@src/hooks';

import { StyledContainer, StyledFooter } from './styles';

export function ContactSupportScreen() {
  const location = useLocation();
  const { navigate } = useRouteNavigation();
  const modalInstance = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const preSelectedReason = useMemo(
    () => new URLSearchParams(location?.search).get('reason'),
    [location?.search],
  );

  const {
    theme,
    issue,
    issues,
    control,
    isUpdating,
    callSupport,
    handleSubmit,
    brandSupportNumber,
    isFindMyProSelected,
    currentSecondaryIssue,
  } = useMessageUsScreenController({
    modalInstance,
    preSelectedReason,
    onSuccess: () => navigate(WebRoutes.services),
  });

  return (
    <>
      <DetailsHeader showBackButton />

      <StyledContainer>
        <Toast msToHide={0} description={t('forms.describeIssue.header')} />

        <div>
          <DescribeIssueForm
            issues={issues}
            control={control}
            isUpdating={isUpdating}
            origin="message_us_screen"
            fieldTexts={{
              reason: {
                label: t('forms.whatIsTheExperiencedIssue'),
                placeholder: t('forms.select'),
              },
              secondaryReason: {
                label: t('forms.whatIsTheIssue', { issue }),
                placeholder: t('forms.select'),
              },
              describeIssue: {
                label: t('forms.provideMoreInformation'),
                placeholder: t('forms.enterDetailsHere'),
              },
            }}
          />
        </div>

        {isFindMyProSelected && (
          <div className="flex flex-col gap-1">
            <Text style={{ marginBottom: theme.spacing.s2 }}>
              <Link to={WebRoutes.inbox} className="text-bluePrimary">
                {t('contactPro')}
              </Link>
              {` ${t('forms.describeIssue.forPreciseInformation')}`}
            </Text>

            <Text>
              {`${t('forms.describeIssue.forUrgentSchedulingCallUsAt')} `}
              <Text style={{ color: theme.colors.blue.primary }} onPress={callSupport}>
                {brandSupportNumber}
              </Text>
              {` ${t('forms.describeIssue.afterMessagingYourProWeAreHereToHelp')}`}
            </Text>
          </div>
        )}

        <StyledFooter>
          {currentSecondaryIssue && (
            <Button
              mode="contained"
              loading={isUpdating}
              disabled={isUpdating}
              onPress={handleSubmit}
              trackID="message_us_screen-submit"
            >
              {isFindMyProSelected ? t('forms.describeIssue.callSupport') : t('submitMessage')}
            </Button>
          )}
        </StyledFooter>
      </StyledContainer>
    </>
  );
}
