import { Divider } from '@mui/material';
import { useMemo } from 'react';
import { getFaq } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Accordion, Button, Text } from '@lawnstarter/ls-react-common/atoms';

import { DetailsHeader, ExternalLink } from '@src/components';
import { useAppDownloadModal } from '@src/hooks';

import { FaqContainer, FaqSectionContainer, styles } from './styles';

export function HelpScreen() {
  const faqs = getFaq();
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);
  const { showAppDownloadModal } = useAppDownloadModal();

  const handleClick = (route: string) => {
    window.open(route, '_blank');
  };

  return (
    <div>
      <div>
        <DetailsHeader />
        <FaqContainer>
          {faqs.map((section, index) => {
            return (
              <FaqSectionContainer key={`main-${index}`}>
                <Text style={style.sectionTitle} variant="titleLarge">
                  {section?.title}
                </Text>
                {section?.questions?.map((question, index) => {
                  return (
                    <FaqSectionContainer key={`sub-${index}`}>
                      <Accordion
                        style={{
                          title: { ...style.question },
                        }}
                        title={question?.question}
                        trackID=""
                      >
                        <>
                          <Text style={style.questionAnswer}>{question?.answer}</Text>
                          {question?.links && (
                            <ExternalLink
                              text={question?.links[0]?.text}
                              iconSize={18}
                              action={() =>
                                question?.links && handleClick(question.links[0]?.target)
                              }
                            />
                          )}
                        </>
                      </Accordion>
                      <Divider />
                    </FaqSectionContainer>
                  );
                })}
              </FaqSectionContainer>
            );
          })}
          {/* TODO: replace with app modal once complete */}
          <Button
            mode="contained"
            buttonColor={theme.colors.primary}
            trackID="faq_screen-still_need_help"
            onPress={showAppDownloadModal}
          >
            {t('customerFaq.stillNeedHelp')}
          </Button>
        </FaqContainer>
      </div>
    </div>
  );
}
