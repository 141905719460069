import { t } from '@lawnstarter/customer-modules/services';
import { TitleHighlighted } from '@lawnstarter/ls-react-common/molecules';
import { Confirmation, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import imageURL from '@src/assets/schedule-calendar.svg';

import type { Props } from './types';

export function CancellationDeflectionChangeSchedule(props: Props) {
  const buttons = [
    {
      label: t('changeMySchedule'),
      onPress: props.onPrimaryPress,
    },
    {
      label: t('cancelMyService'),
      mode: 'text' as const,
      onPress: props.onSecondaryPress,
    },
  ];

  const image = (
    <img alt="" data-testid="image" height={149} loading="lazy" src={imageURL} width={162} />
  );

  const title = (
    <TitleHighlighted
      contents={[
        {
          text: t('cancelService.deflection.changeSchedule.title.takeCharge'),
        },
        {
          text: t('cancelService.deflection.changeSchedule.title.yourSchedule'),
          highlight: true,
        },
      ]}
      style={{ justifyContent: 'center' }}
    />
  );

  return (
    <ModalTemplate>
      <Confirmation
        buttons={buttons}
        description={t('cancelService.deflection.changeSchedule.description')}
        descriptionStyle={{ textAlign: 'center', width: '100%' }}
        icon={image}
        origin="cancellation-deflection-change-schedule"
        stacked="always"
        title={title}
      />
    </ModalTemplate>
  );
}
