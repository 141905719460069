import { View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import {
  auth_updateCustomer as updateCustomer,
  useSetAutoTipMutation,
} from '@lawnstarter/customer-modules/stores/modules';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';

import { ModalFooter } from '@src/components/molecules';
import { useDispatch, useSelector } from '@src/hooks';

import { useStyles } from './styles';

import type { Props } from './types';

export function AutoTipEnableForm({ amount, onDismiss, percentage }: Props) {
  const { styles, ids } = useStyles();
  const dispatch = useDispatch();

  const [setAutoTip, { isLoading }] = useSetAutoTipMutation();
  const customer_id = useSelector(({ auth }) => auth.user?.customer_id);

  if (!customer_id) {
    return null;
  }

  const onCancel = () => {
    onDismiss?.(false);
  };

  const onConfirm = async () => {
    if ((!amount && !percentage) || (amount && percentage)) {
      onDismiss?.(false);
    }

    try {
      await setAutoTip({
        amount,
        customer_id,
        enabled: true,
        percentage,
      });

      dispatch(
        updateCustomer({
          autotip_amount: amount,
          autotip_percentage: percentage,
          is_autotip_enabled: true,
        }),
      );

      onDismiss?.(true);
    } catch {
      onDismiss?.(false);
    }
  };

  return (
    <View dataSet={{ media: ids.container }} style={styles.container}>
      <View dataSet={{ media: ids.content }} style={styles.content}>
        <Text dataSet={{ media: ids.title }} style={styles.title} variant="bodyLarge">
          {t('autoTip.enable.title')}
        </Text>

        <Text style={styles.description}>{t('autoTip.enable.paragraphs.1')}</Text>

        <Text style={styles.description}>{t('autoTip.enable.paragraphs.2')}</Text>
      </View>

      <ModalFooter>
        <Button
          disabled={isLoading}
          mode="contained-tonal"
          onPress={onCancel}
          trackID={'auto-tip-enable-form--cancel-button'}
        >
          {t('notNow')}
        </Button>

        <Button
          disabled={isLoading}
          loading={isLoading}
          mode="contained"
          onPress={onConfirm}
          trackID={'auto-tip-enable-form--confirm-button'}
        >
          {t('yes')}
        </Button>
      </ModalFooter>
    </View>
  );
}
