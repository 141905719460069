import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text, TouchableRipple } from '@lawnstarter/ls-react-common/atoms';

import { useAppContext } from '@src/contexts';

import { StyledDetailsHeader, styles } from './styles';

export function DetailsHeader({
  title,
  showBackButton = false,
}: {
  title?: string;
  showBackButton?: boolean;
}) {
  const theme = useAppTheme();
  const navigate = useNavigate();
  const style = useMemo(() => styles(theme), [theme]);
  const { title: appTitle, subTitle } = useAppContext();

  const renderBackButton = () => {
    if (window.history.length <= 1) {
      return;
    }

    return (
      <div className="cursor-pointer mr-4">
        <TouchableRipple style={style.backButton} onPress={() => navigate(-1)} testID="back-button">
          <Icon size={theme.sizing.s7} name="chevron-left" color={theme.colors.primary} />
        </TouchableRipple>
      </div>
    );
  };

  return (
    <StyledDetailsHeader $withSubtitle={!!subTitle}>
      {showBackButton && renderBackButton()}
      <div className="flex flex-col">
        <Text variant="titleLarge" style={style.title}>
          {title || appTitle}
        </Text>

        {subTitle && (
          <Text variant="bodyLarge" style={style.subTitle}>
            {subTitle}
          </Text>
        )}
      </div>
    </StyledDetailsHeader>
  );
}
