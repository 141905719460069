import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const StyledMobileHeader = tw.div`
  flex items-center justify-between pt-6 sticky top-0 w-full z-10
  md:hidden
`;

export const StyledHelpDeskContainer = tw.div`
  max-md:block
  md:hidden
`;

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    wrapper: {
      height: 112,
      backgroundColor: theme.colors.primary,
    },
    headerTitle: {
      ...setFontWeight('600'),
      color: theme.colors.onPrimary,
      lineHeight: theme.sizing.s7,
    },
    headerSubTitle: {
      color: theme.colors.onPrimary,
      lineHeight: theme.sizing.s6,
      marginTop: theme.spacing.s1,
    },
    headerButton: {
      backgroundColor: theme.colors.white.primary,
      borderRadius: 100,
      marginRight: theme.spacing.s4,
      padding: theme.spacing.s3,
      height: 'fit-content',
      cursor: 'pointer',
    },
  });
