import { PropertyGateLock } from '@lawnstarter/ls-react-common/enums';
import { PropertyDetailsFormData } from '@lawnstarter/ls-react-common/organisms';

import type { GateWidth, GrassLength, MowingScopeOption } from '@lawnstarter/ls-react-common/enums';
import type { PropertyDetails } from './types';

export const mapDataToForm = (propertyInformation: PropertyDetails): PropertyDetailsFormData => ({
  property: {
    gate: {
      exists: propertyInformation?.gate?.has_gate,
      lock: propertyInformation?.gate?.locked
        ? propertyInformation?.gate?.code
          ? PropertyGateLock.Code
          : PropertyGateLock.Key
        : PropertyGateLock.No,
      code: propertyInformation?.gate?.code,
      width: propertyInformation?.gate?.width as GateWidth,
    },
    hasPets: propertyInformation?.pets,
    instructions: {
      exists: !!propertyInformation?.access_instructions,
      content: propertyInformation?.access_instructions,
    },
    isCornerlot: propertyInformation?.cornerlot,
    multitenant: {
      isMultitenant: propertyInformation?.multitenant.is_multitenant,
      unit: (propertyInformation?.multitenant.units ?? []).join(','),
    },
  },
  mowing: {
    grassHeight: propertyInformation?.grasslength as GrassLength,
    instructions: {
      exists: !!propertyInformation?.mowing_preferences?.details,
      content: propertyInformation?.mowing_preferences?.details,
    },
    scope: propertyInformation?.mowing_preferences?.scope as MowingScopeOption,
  },
});
