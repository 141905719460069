import { useMemo } from 'react';
import { Pressable } from 'react-native';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { getBrand } from '@lawnstarter/customer-modules/helpers';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';

import { DesktopPropertySwitcher, DesktopUserDropdownMenu } from '@src/components/molecules';
import { HEADER_ROUTE_OPTIONS } from '@src/constants';
import { useRouteNavigation } from '@src/hooks';

import { StyledCenteredFlexDiv, StyledPropertySwitcherDiv, styles } from './styles';

import type { CurrentTabProps } from '@src/types';

export function DesktopHeader({ currentTab }: CurrentTabProps) {
  const brand = getBrand();
  const { navigate } = useRouteNavigation();

  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const navItems = useMemo(() => {
    return HEADER_ROUTE_OPTIONS.map(({ link, label, tab }) => {
      const itemStyle = currentTab === tab ? style.selectedNavItem : style.navItem;

      return (
        <Pressable key={label} onPress={() => navigate(link)}>
          <Text variant="titleLarge" style={itemStyle}>
            {label}
          </Text>
        </Pressable>
      );
    });
  }, [currentTab, navigate, style.navItem, style.selectedNavItem]);

  return (
    <header
      data-testid="desktop-header"
      className="border-b px-4 sticky top-0 w-full z-20 border-graySecondary bg-whitePrimary max-md:hidden"
    >
      <div className="flex w-full items-center justify-between">
        <StyledCenteredFlexDiv>
          <Pressable onPress={() => navigate(WebRoutes.browseRedirect)}>
            <Text variant="titleLarge" style={style.brand}>
              {brand.name}
            </Text>
          </Pressable>

          {navItems}
        </StyledCenteredFlexDiv>

        <StyledCenteredFlexDiv>
          <StyledPropertySwitcherDiv>
            <DesktopPropertySwitcher />
          </StyledPropertySwitcherDiv>
          <span style={style.verticalLine} />

          <StyledCenteredFlexDiv>
            <DesktopUserDropdownMenu />
          </StyledCenteredFlexDiv>
        </StyledCenteredFlexDiv>
      </div>
    </header>
  );
}
