import tw from 'tailwind-styled-components';

export const StyledServiceWrapper = tw.div`
  flex-col flex flex-1
  max-md:mt-4
`;

export const StyledServiceContainer = tw.div`
  mt-8 flex flex-col
  max-md:mt-0 max-md:flex-1
`;
