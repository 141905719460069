import tw from 'tailwind-styled-components';

interface StyledContainedSectionProps {
  shouldHideFooter?: boolean;
}

export const StyledContainedSection = tw.section<StyledContainedSectionProps>`
  flex flex-col mx-auto responsive-container
  max-md:mb-1
  md:my-6 grow md:grow-0
  ${(props) => !props.shouldHideFooter && 'max-md:pb-[var(--footer-size)]'}
`;
