import { useParams } from 'react-router-dom';
import { usePauseController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { properties_getPropertiesAndSchedules } from '@lawnstarter/customer-modules/stores/modules';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';
import { ActionAlternatives } from '@lawnstarter/ls-react-common/templates';

import { DetailsHeader } from '@src/components';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';

import { StyledContainer } from './styles';

export function PauseScreen() {
  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();
  const { propertyId, scheduleId } = useParams() as { propertyId: string; scheduleId: string };

  const optionalAction = useModal(ModalTemplate.OPTIONAL_ACTION);
  const actionConfirmation = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const schedule = useSelector((state) => state.schedules.schedulesById[scheduleId]);

  const { showPauseModal, supportPath, changeFrequencyPath } = usePauseController({
    schedule,
    onSuccess: () => {
      navigate(WebRoutes.serviceDetail, {
        options: { replace: true },
        params: { propertyId, scheduleId },
      });
      dispatch(properties_getPropertiesAndSchedules({ forceRefresh: true }));
    },
    modals: { optionalAction, actionConfirmation },
  });

  return (
    <>
      <DetailsHeader showBackButton={true} />

      <StyledContainer>
        <ActionAlternatives
          buttons={{
            accept: { label: t('continue'), onPress: showPauseModal },
            reject: {
              label: t('cancel'),
              onPress: () =>
                navigate(WebRoutes.serviceDetail, {
                  params: { propertyId, scheduleId },
                }),
            },
          }}
          options={[
            {
              showDivider: true,
              title: t('pauseServices.changeFrequency.heading'),
              description: t('pauseServices.changeFrequency.content'),
              target: {
                name: t('supportActions.changeFrequency.title'),
                onPress: () =>
                  navigate(changeFrequencyPath as WebRoutes, {
                    params: { propertyId, scheduleId },
                  }),
              },
            },
            {
              showDivider: true,
              title: t('pauseServices.skipMow.heading'),
              description: t('pauseServices.skipMow.content'),
              target: {
                name: t('skipMow'),
                onPress: () =>
                  navigate(WebRoutes.skipService, { params: { propertyId, scheduleId } }),
              },
            },
            {
              title: t('pauseServices.contactSupport.heading'),
              description: t('pauseServices.contactSupport.content'),
              target: {
                name: t('contactSupport.title'),
                onPress: () =>
                  navigate(supportPath as WebRoutes, { params: { propertyId, scheduleId } }),
              },
            },
          ]}
        />
      </StyledContainer>
    </>
  );
}
