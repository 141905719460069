import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    primaryBold: {
      ...setFontWeight('700'),
      color: theme.colors.primary,
    },
  });

export const StyledOrderWrapper = tw.div`
  p-4 gap-6
  md:flex md:p-0
`;

export const StyledOrderDetails = tw.div`
  flex-1
  max-md:pt-6
  md:pl-8
`;

export const StyledNoticeContainer = tw.div`
max-md:mb-14
`;

export const StyledMobileSubmitButtonContainer = tw.div`
fixed bottom-24 left-1/2 transform -translate-x-1/2 w-11/12 shadow-lg rounded-3xl
md:hidden
`;

export const StyledDesktopSubmitButtonContainer = tw.div`
max-md:hidden
`;
