import { generateLtrsStartDateOptions } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { RadioOptionVariant } from '@lawnstarter/ls-react-common/enums';
import { RadioOptionsForm } from '@lawnstarter/ls-react-common/molecules';

import type { AerationFormProps } from './types';

export function AerationForm({ control }: AerationFormProps) {
  return (
    <>
      <RadioOptionsForm
        name="starts_at"
        label={t('aeration.form.startsAt.label')}
        options={generateLtrsStartDateOptions()}
        control={control}
        variant={RadioOptionVariant.Flat}
        noPadding={true}
        rules={{
          validate: (value: boolean | string) => Boolean(value),
        }}
      />
    </>
  );
}
