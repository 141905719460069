import { Divider } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FlatList, LayoutChangeEvent, View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import {
  billingHistory_getItems,
  billingHistory_updateItems,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Accordion, Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { currency, date } from '@lawnstarter/ls-react-common/filters';
import { ActionUnavailable } from '@lawnstarter/ls-react-common/molecules';

import { DetailsHeader } from '@src/components';
import { useDispatch, useSelector } from '@src/hooks';

import { style } from './styles';

import type { BillingHistory, BillingItemResponse } from '@lawnstarter/ls-react-common/types';

export function BillingHistoryScreen() {
  const theme = useAppTheme();
  const styles = useMemo(() => style(theme), [theme]);
  const dispatch = useDispatch();

  const [flatListHeight, setFlatListHeight] = useState(0);

  const billingHistoryItems = useSelector(({ billingHistory }) => {
    return billingHistory.items.map((item) => {
      const newItem = { ...item, items: [...item.items] };
      if (!Number.isNaN(newItem.tax) && newItem.tax !== 0) {
        newItem.items.push({
          id: 'tax',
          note: t('tax'),
          amount: newItem.tax,
        });
      }

      return newItem;
    });
  });
  const pagination = useSelector(({ billingHistory }) => billingHistory.itemsPagination);
  const nextPageLinkExists = useSelector(({ billingHistory }) =>
    Boolean(billingHistory?.itemsPagination?.links?.next),
  );
  const { isLoading, isUpdating } = useSelector(
    ({ billingHistory }) => billingHistory.loadingStatus,
  );

  useEffect(() => {
    dispatch(billingHistory_getItems());
  }, [dispatch]);

  const handleLoadMore = useCallback(() => {
    if (!isUpdating && !isLoading && nextPageLinkExists) {
      dispatch(billingHistory_updateItems({ page: pagination.currentPage + 1 }));
    }
  }, [isUpdating, isLoading, nextPageLinkExists, pagination.currentPage, dispatch]);

  const renderAccordionTitle = useCallback(({ timestamp }: BillingHistory) => {
    return (
      <View style={{ flex: 1 }}>
        <Text>{date({ date: timestamp, format: 'compact' })}</Text>
      </View>
    );
  }, []);

  const renderAccordionSubtitle = useCallback(
    ({ note, total }: BillingHistory) => {
      return (
        <View style={styles.subtitleWrapper}>
          <Text style={styles.subtitleNote}>{note}</Text>
          <Text
            style={{
              ...styles.subtitleTotal,
              color: total > 0 ? theme.colors.primary : theme.colors.gray.tertiary,
            }}
          >
            {currency({ amount: total })}
          </Text>
        </View>
      );
    },
    [theme, styles],
  );

  const renderItem = useCallback(
    ({ id, note, amount }: BillingItemResponse) => {
      return (
        <li key={id} style={styles.itemWrapper}>
          <View style={styles.itemContent}>
            <Text style={styles.itemNote}>{note}</Text>

            <Text>{currency({ amount })}</Text>
          </View>

          <Divider />
        </li>
      );
    },
    [styles],
  );

  const renderAccordion = useCallback(
    ({ item: { timestamp, note, total, items } }: { item: BillingHistory }) => {
      return (
        <>
          <Accordion
            key={`accordion-${timestamp}`}
            style={{
              innerWrapper: {
                padding: theme.spacing.s4,
              },
            }}
            testID="accordion"
            trackID="billing-history"
            title={renderAccordionTitle({ timestamp } as BillingHistory)}
            subtitle={renderAccordionSubtitle({ note, total } as BillingHistory)}
          >
            <View style={styles.contentWrapper}>
              <ul style={styles.list}>
                {items.map((item) => {
                  return renderItem(item);
                })}
                <li key="accordion-total" style={styles.listTotal}>
                  <Text>
                    {t('total')}: {currency({ amount: total })}
                  </Text>
                </li>
              </ul>
            </View>
          </Accordion>

          <Divider key={`accordion-divider-${timestamp}`} style={styles.accordionDivider} />
        </>
      );
    },
    [theme, renderAccordionSubtitle, renderAccordionTitle, renderItem, styles],
  );

  const renderListFooter = useCallback(() => {
    if (!billingHistoryItems?.length) {
      return null;
    }

    return nextPageLinkExists ? (
      <View style={styles.footerContainer}>
        <Button trackID="billing_history-load_more" onPress={handleLoadMore} loading={isUpdating}>
          {t('loadMore')}
        </Button>
      </View>
    ) : null;
  }, [billingHistoryItems, isUpdating, nextPageLinkExists, handleLoadMore, styles]);

  return (
    <View style={{ height: '100%' }}>
      <DetailsHeader key="header" />

      <View
        style={styles.contentContainer}
        onLayout={({
          nativeEvent: {
            layout: { height },
          },
        }: LayoutChangeEvent) => {
          setFlatListHeight(height);
        }}
      >
        <FlatList
          testID="billing-history"
          data={billingHistoryItems}
          extraData={isUpdating}
          onEndReachedThreshold={0.8}
          onEndReached={handleLoadMore}
          keyExtractor={(item) => item.timestamp}
          renderItem={renderAccordion}
          ListFooterComponent={renderListFooter}
          ListEmptyComponent={
            !isUpdating && !isLoading ? (
              <View style={{ justifyContent: 'center' }}>
                <ActionUnavailable
                  title={t('billingHistory.emptyState.title')}
                  description={t('billingHistory.emptyState.description')}
                />
              </View>
            ) : null
          }
          contentContainerStyle={{ height: flatListHeight }}
        />
      </View>
    </View>
  );
}
