import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { Pressable } from 'react-native';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { DEVICE_WIDTH_BREAKPOINTS } from '@src/constants';
import { useRouteNavigation, useWindowSize } from '@src/hooks';

import type { HTMLAttributes } from 'react';

export function OrderServiceHeader({
  section,
  serviceName,
  withSlashes = false,
  className = '',
  showMQSteps = false,
}: {
  section?: string;
  serviceName: string;
  withSlashes?: boolean;
  className?: HTMLAttributes<any>['className'];
  showMQSteps?: boolean;
}) {
  const theme = useAppTheme();
  const { navigate } = useRouteNavigation();
  const { width } = useWindowSize();

  const MQStepsModal = useModal(ModalTemplate.STEPS);

  const fontVariant = useMemo(() => {
    if (width <= DEVICE_WIDTH_BREAKPOINTS.mobile.max) {
      return 'titleLarge';
    }

    if (
      width > DEVICE_WIDTH_BREAKPOINTS.mobile.max &&
      width <= DEVICE_WIDTH_BREAKPOINTS.tablet.max
    ) {
      return 'headlineSmall';
    }

    return 'displaySmall';
  }, [width]);

  const commonHeaderProps = useCallback(
    ({ bold }: { bold?: boolean } = {}): any => ({
      accessibilityRole: 'header',
      dataSet: { textVariant: fontVariant },
      variant: fontVariant,
      maxLines: 1,
      style: {
        color: theme.colors.primary,
        ...setFontWeight(bold ? '700' : '400'),
      },
    }),
    [fontVariant, theme],
  );

  const renderMQSteps = useCallback(() => {
    return (
      <Pressable
        onPress={() => {
          MQStepsModal.show({
            title: t('quote.manualQuote.steps.howQuotingWorks'),
            steps: [
              t('quote.manualQuote.steps.step1'),
              t('quote.manualQuote.steps.step2'),
              t('quote.manualQuote.steps.step3'),
              t('quote.manualQuote.steps.step4'),
            ],
          });
        }}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Icon name="help-circle" color={theme.colors.blue.primary} />
        <Text
          variant="bodyLarge"
          style={{ color: theme.colors.blue.primary, ...setFontWeight('700') }}
        >
          {t('quote.manualQuote.steps.seeHowQuotingWorks')}
        </Text>
      </Pressable>
    );
  }, [MQStepsModal, theme]);

  return (
    <section className={`pb-4 ${className}`}>
      <div className={classNames('flex', 'items-center', { 'pb-2': showMQSteps })}>
        <Pressable onPress={() => navigate(WebRoutes.browseRedirect)}>
          <Text {...commonHeaderProps()}>{withSlashes ? `${section} /` : section}</Text>
        </Pressable>
        <div className="ml-2">
          <Text {...commonHeaderProps({ bold: true })}>{serviceName}</Text>
        </div>
      </div>

      {showMQSteps && renderMQSteps()}
    </section>
  );
}
