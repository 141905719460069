import { PropertyGateLock } from '@lawnstarter/ls-react-common/enums';

import type { PropertyDetailsFormData } from '@lawnstarter/ls-react-common/organisms';
import type { PropertyDetails } from './types';

export const mapFormDataToAction = (
  currentPropertyInformation: PropertyDetails,
  { property, mowing }: PropertyDetailsFormData,
): PropertyDetails => ({
  ...currentPropertyInformation,
  access_instructions: property.instructions.content ?? '',
  cornerlot: property.isCornerlot,
  gate: {
    has_gate: property.gate.exists,
    locked: property.gate.lock !== PropertyGateLock.No,
    code: property.gate.lock === PropertyGateLock.Code ? property.gate.code ?? 'Locked w/ key' : '',
    width: property.gate.exists ? property.gate.width : undefined,
  },
  grasslength: mowing.grassHeight,
  pets: property.hasPets,
  multitenant: {
    is_multitenant: property.multitenant.isMultitenant,
    units: property.multitenant.isMultitenant ? (property.multitenant.unit ?? '').split(',') : [],
  },
  mowing_preferences: {
    ...currentPropertyInformation?.mowing_preferences,
    details: mowing.instructions.content,
    scope: mowing.scope,
  },
});
