import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { AppTheme, setFontWeight } from '@lawnstarter/ls-react-common';

const commonStyles = (theme: AppTheme) =>
  StyleSheet.create({
    navItem: {
      ...setFontWeight('700'),
      textAlign: 'center',
      paddingVertical: theme.spacing.s8,
      paddingHorizontal: theme.spacing.s4,
      lineHeight: theme.sizing.s4,
      color: theme.colors.gray.tertiary,
    },
    bottomBorder: {
      borderBottomColor: theme.colors.transparent,
      borderBottomWidth: 1,
    },
  });

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    brand: {
      ...commonStyles(theme).bottomBorder,
      ...setFontWeight('900'),
      paddingVertical: theme.spacing.s7,
      marginRight: theme.sizing.s8,
      lineHeight: theme.sizing.s6,
      color: theme.colors.primary,
    },
    navItem: {
      ...commonStyles(theme).navItem,
      ...commonStyles(theme).bottomBorder,
    },
    selectedNavItem: {
      ...commonStyles(theme).navItem,
      ...commonStyles(theme).bottomBorder,
      color: '#000',
      borderBottomColor: theme.colors.primary,
    },
    verticalLine: {
      borderWidth: 1,
      height: theme.sizing.s8,
      marginRight: theme.spacing.s4,
      borderColor: theme.colors.gray.secondary,
    },
  });

export const StyledCenteredFlexDiv = tw.div`
 flex items-center
`;

export const StyledPropertySwitcherDiv = tw.div`
 mr-4
`;
