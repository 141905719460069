import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useUnpauseController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { CalendarInput } from '@lawnstarter/ls-react-common/molecules';

import { DetailsHeader, Loader } from '@src/components';
import { useRouteNavigation } from '@src/hooks';

export function UnpauseScreen() {
  const { navigate } = useRouteNavigation();
  const { propertyId, scheduleId } = useParams() as { propertyId: string; scheduleId: string };

  const { minDate, isUpdating, isLoading, submit, control, disabledDays, nextStartsAt } =
    useUnpauseController({
      propertyId,
      scheduleId,
      onSuccess: () =>
        navigate(WebRoutes.serviceDetail, {
          options: { replace: true },
          params: { propertyId, scheduleId },
        }),
    });

  return (
    <div>
      <DetailsHeader showBackButton />

      {isLoading ? (
        <Loader testID="loader" />
      ) : (
        <form className="p-4 flex flex-col gap-2">
          <CalendarInput
            control={control}
            name="schedule_at"
            disabled={isUpdating}
            rules={{ required: true }}
            disabledDays={disabledDays}
            minDate={minDate ?? undefined}
            maxDate={dayjs(nextStartsAt).toDate()}
            label={t('resumePausedSchedule.earlierDate')}
          />

          <Button
            mode="contained"
            onPress={submit}
            loading={isUpdating}
            testID="reactivate_earlier_button"
            trackID="resume_paused_schedule_screen-reactivate_earlier"
          >
            {t('supportActions.reactivateEarlier.title')}
          </Button>
        </form>
      )}
    </div>
  );
}
