import { StyleSheet } from 'react-native';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    sectionTitle: {
      fontWeight: '600',
      marginBottom: theme.spacing.s3,
    },
    divider: {
      marginVertical: theme.spacing.s3,
    },
    contentContainer: {
      flex: 1,
    },
    screenContainer: {
      flex: 1,
      margin: theme.spacing.s3,
      backgroundColor: theme.colors.white.primary,
    },
    dueSection: {
      display: 'flex',
      flexDirection: 'column',
    },
    dueDay: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dueDayTitle: {
      fontFamily: theme.fontVariant.primary.w700,
      fontWeight: '700',
      marginBottom: theme.spacing.s3,
    },
    dueDetails: {
      marginBottom: theme.spacing.s3,
    },
    serviceSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    servicePrice: {
      marginBottom: theme.spacing.s3,
    },
  });
};
