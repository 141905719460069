import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { auth_syncLogin } from '@lawnstarter/customer-modules/stores/modules';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text, TextInput } from '@lawnstarter/ls-react-common/atoms';
import { SignInForm, SignInFormData } from '@lawnstarter/ls-react-common/organisms';

import { useDispatch } from '@src/hooks';

import { StyledDetails, StyledRecovery, StyledWelcome } from './styles';

export function SignInScreen() {
  const theme = useAppTheme();
  const dispatch = useDispatch();

  const { control, setFocus, handleSubmit } = useForm<SignInFormData>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(
      z.object({
        email: TextInput.Email.validationRule({
          invalid: t('validations.invalidEmail'),
          required: t('validations.emailRequired'),
        }),
        password: TextInput.Password.validationRule({
          required: t('validations.passwordRequired'),
        }),
      }),
    ),
  });

  const signIn = () =>
    handleSubmit(({ email, password }: SignInFormData) => {
      dispatch(auth_syncLogin({ email, password }));
    });

  return (
    <form className="flex flex-col">
      <StyledWelcome>
        <Text variant="titleLarge" style={{ color: theme.colors.primary }} accessibilityLabel="h1">
          {t('forms.signIn.welcomeBack')}
        </Text>
      </StyledWelcome>

      <StyledDetails>
        <Text style={{ color: theme.colors.primary }} accessibilityLabel="h3">
          {t('forms.signIn.enterDetails')}
        </Text>
      </StyledDetails>

      <SignInForm
        origin="sign_in_screen"
        control={control}
        isUpdating={false}
        submit={signIn}
        setFocus={setFocus}
        fieldTexts={{
          email: { label: t('email') },
          password: { label: t('password') },
        }}
      />

      <StyledRecovery>
        <Link to={WebRoutes.passwordRecovery} className="with-hover max-md:mb-5 max-md:self-center">
          <Text style={{ ...setFontWeight('700'), color: theme.colors.primary }}>
            {t('forms.signIn.forgotPassword')}
          </Text>
        </Link>

        <Button
          trackID="sign_in_screen"
          testID="sign_in_button"
          mode="contained"
          onPress={signIn()}
          contentStyle={{ paddingHorizontal: theme.spacing.s5 }}
        >
          {t('signIn')}
        </Button>
      </StyledRecovery>
    </form>
  );
}
