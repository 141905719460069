import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    backButton: {
      borderRadius: 100,
      height: theme.sizing.s7,
      width: theme.sizing.s7,
      backgroundColor: theme.colors.white.primary,
      textAlign: 'center',
    },
    title: {
      ...setFontWeight('600'),
      color: theme.colors.white.primary,
    },
    subTitle: { color: theme.colors.white.primary },
  });

export const StyledDetailsHeader = tw.div<{ $withSubtitle: boolean }>`
  h-[80px] bg-primary pl-6 flex flex-row items-center mb-4 sticky top-0 z-10
  ${({ $withSubtitle }) => ($withSubtitle ? 'h-[90px]' : 'h-[80px]')}
  max-md:hidden
`;
