import { useMemo } from 'react';
import { share_platformsSelector } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';

import { useSelector } from './useSelector';

export function useServiceReviewConfirmation() {
  const theme = useAppTheme();

  const sharePlatforms = useSelector(share_platformsSelector(theme));

  const { nextdoorLocallyShared: sharedOnNextDoorLocally } = useSelector(({ share }) => share);

  const sharedOnNextDoor = useMemo(
    () => Boolean(sharedOnNextDoorLocally || sharePlatforms?.nextdoor?.is_share_complete),
    [sharedOnNextDoorLocally, sharePlatforms?.nextdoor?.is_share_complete],
  );

  return { sharedOnNextDoor };
}
