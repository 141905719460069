import { SessionStorageKeys } from '@src/enums';

export function setServiceReviewShown(id: number) {
  const pastTriggered = JSON.parse(
    sessionStorage.getItem(SessionStorageKeys.ServiceReviewModalTriggered) ?? '[]',
  ) as string[];
  pastTriggered.push(id.toString());
  sessionStorage.setItem(
    SessionStorageKeys.ServiceReviewModalTriggered,
    JSON.stringify(pastTriggered),
  );
}
