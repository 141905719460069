import { LEGACY_CUST_WEB_BASE_URL } from '@env';
import { User } from '@sentry/react';

export const isLeadOrOnboardingIncomplete = ({
  user,
  authToken,
}: {
  user?: User;
  authToken?: string | null;
}): void => {
  if (!user || !authToken) {
    return;
  }

  if (user?.customer?.is_customer_lead) {
    window.location.href = `${LEGACY_CUST_WEB_BASE_URL}/?auth=${authToken}`;
  }

  if (
    user?.customer?.processing_order &&
    (user.customer.processing_order?.policies_accepted_at === null ||
      user.customer.processing_order?.property_info?.completed_at === null)
  ) {
    window.location.href = `${LEGACY_CUST_WEB_BASE_URL}/?auth=${authToken}`;
  }
};
