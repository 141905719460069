import { Children } from 'react';
import { View } from 'react-native';

import { useStyles } from './styles';

import type { Props } from './types';

export function ModalFooter({ children, itemStyle, stacked = false, style, ...props }: Props) {
  const { styles, ids } = useStyles(stacked);

  return (
    <View dataSet={{ media: ids.container }} style={[styles.container, style]} {...props}>
      {Children.map(
        children,
        (child) =>
          child && (
            <View dataSet={{ media: ids.item }} style={[styles.item, itemStyle]}>
              {child}
            </View>
          ),
      )}
    </View>
  );
}
