import { useMemo } from 'react';
import { Image, View } from 'react-native';
import { useBrand } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import { getLongGrassPricingConcepts } from '@lawnstarter/ls-constants';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { setFontWeight } from '@lawnstarter/ls-react-common/helpers';

import { DetailsHeader } from '@src/components';

import { StyleLongGrassWrapper } from '../LongGrassQuoteReportScreen/styles';
import { style } from './styles';

import type { LongGrassPricingConcept } from './types';

export function LearnAboutLongGrassPricing() {
  const brand = useBrand();
  const theme = useAppTheme();
  const styles = useMemo(() => style({ theme }), [theme]);

  const renderFactors = () =>
    getLongGrassPricingConcepts().map((concept: LongGrassPricingConcept, idx: number) => (
      <View key={concept.title} style={idx === 0 ? {} : { marginTop: theme.spacing.s5 }}>
        <Text variant="titleSmall" style={{ ...setFontWeight('700') }}>
          {concept.title}
        </Text>

        <Text>{concept.description}</Text>

        <div className="flex flex-row mt-2 justify-start">
          <Image
            testID={`${concept.title}-bad`}
            source={{ uri: `${brand.assets}/${concept.images.bad}` }}
            style={{ ...styles.image, marginRight: theme.spacing.s2 }}
          />
          <Image
            style={styles.image}
            testID={`${concept.title}-good`}
            source={{ uri: `${brand.assets}/${concept.images.good}` }}
          />
        </div>
      </View>
    ));

  return (
    <div>
      <DetailsHeader title={t('long_grass.pricing')} showBackButton />

      <StyleLongGrassWrapper>
        <Text variant="titleMedium" style={styles.title}>
          {t('long_grass.labels.pricingFactors')}
        </Text>

        {renderFactors()}
      </StyleLongGrassWrapper>
    </div>
  );
}
