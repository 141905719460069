import { StyleSheet } from 'react-native';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const style = ({ theme }: { theme: AppTheme }) =>
  StyleSheet.create({
    title: {
      display: 'flex',
      ...setFontWeight('700'),
      color: theme.colors.primary,
      marginBottom: theme.spacing.s5,
    },
    image: {
      width: 137,
      height: 137,
    },
  });
